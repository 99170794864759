import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import LoginPage from "../../../compnents/pages/Login";
import { verifyOrganisation } from "../../../store/api/organisation";
import { setDynamicFavicon } from "../../../helpers";

const Login = ({ superadmin }) => {
  const { companyName } = useParams();
  const [primaryColor, setPrimaryColor] = useState("#96f7dc");
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: verifyOrganisation,
    onSuccess: (data) => {
      localStorage.setItem("organisationId", data.id);
      localStorage.setItem("companyName", companyName.trim());
      localStorage.setItem("logoCompany", data.logoLink);
      setDynamicFavicon(data.logoLink);
      setPrimaryColor(data.primaryColor || "#96f7dc");
    },
    onError: (error) => {
      if (error.response) {
        navigate(`/`);
      }
    },
  });

  useEffect(() => {
    if (companyName) {
      mutate({ organisationName: companyName || "" });
      localStorage.setItem("companyName", companyName);
    }
  }, [companyName]);

  useEffect(() => {
    document.title = `Login | ${!superadmin ? `${companyName}` : "superAdmin"}`;
    window.scrollTo(0, 0);
  }, [companyName, superadmin]);

  return (
    <main id="main" tabIndex={0}>
      <LoginPage superadmin={superadmin} primaryColor={primaryColor} />
    </main>
  );
};

export default Login;
