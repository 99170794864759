import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getTemplateCreationUser, getUserById } from "../../../store/api/users";
import OrganisatioFilter from "../Statisctics/OrganisationFilter";
import { roles } from "../../../constants/routes";
import Button from "../../common/Button";

interface UserModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (user: User) => void;
  isEdit: boolean;
  IdUserToEdit?: string;
  role?: string;
}

interface User {
  id?: string;
  fullName: string;
  email: string;
  locations: string;
  dateCreated: string;
  userTypes: string;
  organisationId?: string;
}

interface Location {
  id: string;
  title: string;
  isSelected: boolean;
}

interface UserType {
  id: string;
  title: string;
  isSelected: boolean;
}
interface organisationType {
  id: string;
  title: string;
  isSelected: boolean;
}

const UserModal: React.FC<UserModalProps> = ({
  open,
  onClose,
  onSubmit,
  isEdit,
  IdUserToEdit,
  role,
}) => {
  const [user, setUser] = useState<User>({
    id: "",
    fullName: "",
    email: "",
    locations: "",
    dateCreated: new Date().toISOString().split("T")[0], // Today's date
    userTypes: "",
    organisationId: "",
  });

  const [locations, setLocations] = useState<Location[]>([]);
  const [userTypeList, setUserTypeList] = useState<UserType[]>([]);
  const [organisation, setOrganisation] = useState<organisationType>();

  const { data, isLoading } = useQuery({
    queryKey: ["UserLocationAndType"],
    queryFn: () => getTemplateCreationUser(),
    enabled: open,
  });

  const { data: userData } = useQuery({
    queryKey: ["userToEdit", IdUserToEdit],
    queryFn: () => getUserById({ id: IdUserToEdit }),
    enabled: isEdit && IdUserToEdit ? true : false,
  });

  useEffect(() => {
    if (!isLoading) {
      setLocations(data?.locations ?? []);
      setUserTypeList(data?.userTypes ?? []);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (isEdit && userData) {
      setUser({
        fullName: userData.fullName || "",
        email: userData.email || "",
        locations: userData.locations.find((el) => el.isSelected)?.id || "",
        dateCreated:
          userData.dateCreated || new Date().toISOString().split("T")[0],
        userTypes: userData.userTypes.find((el) => el.isSelected)?.id || "",
      });
    }
  }, [isEdit, userData]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value as string });
  };

  const handleSubmit = () => {
    onSubmit({ ...user, organisationId: organisation?.id });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: { xs: "90%", md: "500px" }, // responsive width
          maxHeight: "90vh", // ensures the modal doesn't grow beyond the screen height
          bgcolor: "background.paper",
          p: 4,
          m: "auto",
          mt: "2%",
          borderRadius: 2,
          boxShadow: 24,
          overflowY: "auto", // adds scroll functionality
        }}
      >
        <Typography>
          {isEdit ? "Edit User" : "Add a new single user"}
        </Typography>
        <TextField
          fullWidth
          required
          label="Full Name"
          name="fullName"
          value={user.fullName}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={user.email}
          type="email"
          required
          onChange={handleInputChange}
          margin="normal"
          disabled={isEdit}
        />

        {/* Location Select based on locations array */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Location</InputLabel>
          <Select
            name="locations"
            value={user.locations}
            onChange={handleInputChange}
            label="Location"
          >
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Date Added"
          name="dateCreated"
          value={user.dateCreated}
          onChange={handleInputChange}
          margin="normal"
          disabled
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>User Type</InputLabel>
          <Select
            name="userTypes"
            value={user.userTypes}
            onChange={handleInputChange}
            label="User Type"
          >
            {userTypeList.map((userType) => (
              <MenuItem key={userType.id} value={userType.id}>
                {userType.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {roles.SuperAdmin === role && (
          <OrganisatioFilter setOrganisation={setOrganisation} />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            onClick={handleSubmit}
            text={isEdit ? "Edit User" : "Add User"}
          />
          <Button onClick={onClose} text="Close" backgroundColor="white" />
        </Box>
      </Box>
    </Modal>
  );
};

export default UserModal;
