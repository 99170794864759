import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Container, Grid } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import { IMAGE_URL_AZURE } from "../../../../constants/env";

const MainArticle = ({
  article,
  primaryColor,
  primaryColorLight,
  secondaryColor,
  companyName,
  navigate,
}) => (
  <Box
    onClick={() => navigate(`/${companyName}/article/details/${article.id}`)}
    sx={{
      width: {
        xs: "100%",
        sm: "300px",
        md: "350px",
      },
      height: 400,
      bgcolor: "white",
      cursor: "pointer",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Box
      sx={{
        width: "100%",
        height: 200,
        position: "relative",
        overflow: "hidden",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          maxWidth: "100%",
          maxHeight: "100%",
          width: "auto",
          height: "auto",
          objectFit: "contain",
          transition: "transform 0.3s ease-in-out",
        },
        "&:hover img": {
          transform: "scale(1.1)",
        },
      }}
    >
      <img
        src={article?.imageLink + "?" + IMAGE_URL_AZURE}
        alt={article.title}
      />
    </Box>
    <Box sx={{ flex: 1, p: 2, display: "flex", flexDirection: "column" }}>
      <Typography
        variant="overline"
        sx={{
          color: secondaryColor ? secondaryColor : primaryColorLight,
          fontWeight: "bold",
          fontSize: "12px",
          mb: 1,
        }}
      >
        {article?.topics?.split(",").slice(0, 3).join(", ")}
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "24px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
        }}
      >
        {article.title}
      </Typography>

      <Typography
        variant="caption"
        sx={{
          color: primaryColor,
          mt: "auto",
        }}
      >
        {article?.scheduledDate &&
          formatDistanceToNow(new Date(article?.scheduledDate), {
            addSuffix: true,
          })}
      </Typography>
    </Box>
  </Box>
);

const SecondaryArticle = ({
  article,
  primaryColor,
  primaryColorLight,
  secondaryColor,
  companyName,
  navigate,
}) => (
  <Box
    onClick={() => navigate(`/${companyName}/article/details/${article.id}`)}
    sx={{
      width: {
        xs: "100%",
        sm: "270px",
        md: "290px",
      },
      height: 170,
      bgcolor: "white",
      cursor: "pointer",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Box sx={{ p: 2, flex: 1, display: "flex", flexDirection: "column" }}>
      <Typography
        sx={{
          color: secondaryColor ? secondaryColor : primaryColorLight,
          fontWeight: "bold",
          fontSize: "12px",
          mb: 1,
        }}
      >
        {article?.topics?.split(",").slice(0, 3).join(", ")}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          fontSize: "16px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
        }}
      >
        {article.title}
      </Typography>

      <Typography
        variant="caption"
        sx={{
          color: primaryColor,
          mt: "auto",
        }}
      >
        {article?.scheduledDate &&
          formatDistanceToNow(new Date(article?.scheduledDate), {
            addSuffix: true,
          })}
      </Typography>
    </Box>
  </Box>
);

const TopSection = ({ articles }) => {
  const navigate = useNavigate();
  const { primaryColor, primaryColorLight, secondaryColor } = useOrganisation();
  const companyName = localStorage.getItem("companyName");

  return (
    <Container maxWidth="lg" sx={{ py: 4 }} style={{ paddingTop: 0 }}>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {articles?.slice(0, 3).map((article, index) => (
          <Grid
            item
            key={article.id || index}
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            <MainArticle
              article={article}
              primaryColor={primaryColor}
              primaryColorLight={primaryColorLight}
              secondaryColor={secondaryColor}
              companyName={companyName}
              navigate={navigate}
            />
          </Grid>
        ))}
      </Grid>
      <hr />
      <Grid container spacing={2}>
        {articles?.slice(3, 7).map((article, index) => (
          <Grid
            item
            key={article.id || index}
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            <SecondaryArticle
              article={article}
              primaryColor={primaryColor}
              primaryColorLight={primaryColorLight}
              secondaryColor={secondaryColor}
              companyName={companyName}
              navigate={navigate}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TopSection;
