import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import Table from "../../../common/Table";
import AdFormModal from "./AddEdit";
import { columns } from "./columns";
import { getAllAds, deleteAds } from "../../../../store/api/ads";
import Button from "../../../common/Button";

const Ads = ({ tabIndex, index }) => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("DateCreated");
  const [isDescending, setIsDescending] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);

  const { data } = useQuery({
    queryKey: ["adsList", searchTerm, currentPage, sortBy, isDescending],
    queryFn: () => getAllAds({ searchTerm, currentPage, sortBy, isDescending }),
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: (id) => deleteAds({ id }),
    onSuccess: (data) => {
      queryClient.refetchQueries("adsList");
      handleCloseConfirmation();
    },
    onError: (error) => {
      console.error("Delete failed:", error);
    },
  });

  // Reset edit state when modal is closed
  useEffect(() => {
    if (!open) {
      setIsEdit(false);
      setEditData(null);
    }
  }, [open]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleConfirmDelete = () => {
    deleteMutate(adToDelete);
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setEditData(data);
    handleOpen();
  };

  const handleDelete = (ads) => {
    handleOpenConfirmation();
    setAdToDelete(ads.id);
  };

  return (
    <Box>
      <Grid container xs={12} mb={2} md={12}>
        <AdFormModal
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          isEdit={isEdit}
          editData={editData}
          setIsEdit={setIsEdit}
        />
      </Grid>
      <Table data={data} columns={columns({ handleEdit, handleDelete })} />
      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>Are you sure to delete this Ad</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmation}
            text="Disagree"
            backgroundColor="white"
          />
          <Button onClick={handleConfirmDelete} text="Agree" />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Ads;
