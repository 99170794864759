import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Container } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import { getRole } from "../../../helpers/auth";
import { roles } from "../../../constants/routes";
import { useOrganisation } from "../../../hooks/useOrganisation";
import "./style.css";
import { setDynamicFavicon } from "../../../helpers";
import { IMAGE_URL_AZURE } from "../../../constants/env";

const NavBar = () => {
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");
  const queryClient = useQueryClient();

  const { primaryColor, logoLink } = useOrganisation();
  const { pathname } = useLocation();
  const role = getRole();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("companyName");
    localStorage.removeItem("organisationId");
    localStorage.removeItem("logoCompany");
    localStorage.clear();
    setDynamicFavicon(null);

    navigate("/");
    queryClient.refetchQueries({
      queryKey: ["onboardedOrganisation"],
    });
  };

  const handleMenuRoute = (route) => {
    navigate(route);
    handleMenuClose();
  };

  // Custom styles to remove hover effects
  const iconButtonStyle = {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  const menuItemStyle = {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  return pathname.includes("onboarding") ? (
    <Container maxWidth="lg" className="sectionCenter">
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex" }}>
            <MenuItem onClick={handleLogout}>
              <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
              Log out
            </MenuItem>
          </Box>
        </Toolbar>
      </AppBar>
    </Container>
  ) : (
    <div style={{ backgroundColor: "transparent" }}>
      <Container
        maxWidth="lg"
        className="sectionCenter"
        style={{ paddingLeft: 0 }}
      >
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          style={{ paddingLeft: 0 }}
        >
          <Toolbar sx={{ justifyContent: "space-between", paddingLeft: 0 }}>
            {logoLink ? (
              <Box
                onClick={() =>
                  role === roles.SuperAdmin
                    ? navigate(`/${companyName}/admin-dashboard`)
                    : navigate(`/${companyName}/dashboard`)
                }
                component="img"
                src={`${logoLink}?${IMAGE_URL_AZURE}`}
                alt="logo"
                sx={{
                  maxHeight: "100px",
                  maxWidth: "200px",
                  width: "auto",
                  height: "auto",
                  objectFit: "contain",
                  display: { xs: "none", sm: "block" },
                  cursor: "pointer",
                }}
              />
            ) : (
              <Box
                onClick={() =>
                  role === roles.SuperAdmin
                    ? navigate(`/${companyName}/admin-dashboard`)
                    : navigate(`/${companyName}/dashboard`)
                }
                component="img"
                src="/favicon/Komz_Logo_GreenBlock.png"
                alt="Komz_logo"
                sx={{
                  maxHeight: "100px",
                  maxWidth: "200px",
                  width: "auto",
                  height: "auto",
                  objectFit: "contain",
                  display: { xs: "none", sm: "block" },
                  cursor: "pointer",
                }}
              />
            )}

            <Box sx={{ display: "flex" }}>
              <Box className="header-left">
                <IconButton
                  onClick={() =>
                    role === roles.SuperAdmin
                      ? navigate(`/${companyName}/admin-dashboard`)
                      : navigate(`/${companyName}/dashboard`)
                  }
                  sx={iconButtonStyle}
                >
                  <HomeIcon sx={{ color: primaryColor }} />
                </IconButton>
              </Box>

              {role !== roles.SuperAdmin && (
                <Box className="header-left" style={{ color: primaryColor }}>
                  <IconButton
                    color="inherit"
                    onClick={handleMenuOpen}
                    sx={iconButtonStyle}
                  >
                    <Typography
                      variant="body2"
                      sx={{ ml: 1 }}
                      color={primaryColor}
                      style={{ paddingRight: "10px", fontWeight: "bold" }}
                    >
                      Your Settings
                    </Typography>
                    <AccountCircleRoundedIcon sx={{ color: primaryColor }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                      elevation: 4,
                      sx: { width: 200 },
                    }}
                  >
                    <MenuItem
                      onClick={() =>
                        handleMenuRoute(`/${companyName}/settings/account`)
                      }
                      sx={menuItemStyle}
                    >
                      Account
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuRoute(`/${companyName}/settings/preferences`)
                      }
                      sx={menuItemStyle}
                    >
                      Preference Center
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout} sx={menuItemStyle}>
                      <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                      Log out
                    </MenuItem>
                  </Menu>
                </Box>
              )}

              {role !== roles.Reader && (
                <IconButton
                  onClick={() => navigate(`/${companyName}/admin-dashboard`)}
                  className="header-left"
                  sx={{
                    ...iconButtonStyle,
                    borderRadius: 4,
                    color: primaryColor,
                  }}
                >
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{
                      ml: 1,
                      letterSpacing: ".2rem",
                      color: primaryColor,
                      textDecoration: "none",
                    }}
                    className="header-left"
                    style={{ fontWeight: "bold" }}
                  >
                    Admin
                  </Typography>
                </IconButton>
              )}

              {role === roles.SuperAdmin && (
                <MenuItem onClick={handleLogout} sx={menuItemStyle}>
                  <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                  Log out
                </MenuItem>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  );
};

export default NavBar;
