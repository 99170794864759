import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// -----------------------------------------------------
// Define types for the add resource function parameters and response
interface addResourceParams {
  fileName?: string;
  contentType?: string;
  fileData?: string;
}

interface addResourceResponse {
  msg: string;
  user: any;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<addResourceResponse>} returns a Promise either an error or the response object msg & user
 */
export const addResource = async (
  file: addResourceParams
): Promise<addResourceResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<addResourceResponse> = await axios.post(
      `${URL.RESOURCE.BASE}${URL.RESOURCE.V1_ADMIN_ADD_RESOURCES}`,
      {
        ...file,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      // getConfig()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for get resources function parameters and response
// Define types for the add resource function parameters and response
interface addResourceParams {
  IsOnlyImage?: boolean;
}

interface ResourcesResponse {}
/**
 * @method GET
 * @route
 * @returns {Promise<ResourcesResponse>} returns a Promise either an error or the response object
 */
export const getAllResources = async ({
  IsOnlyImage,
}: addResourceParams): Promise<ResourcesResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<ResourcesResponse> = await axios.get(
      `${URL.RESOURCE.BASE}${URL.RESOURCE.V1_ADMIN_GET_RESOURCES_LIST}?IsOnlyImage=${IsOnlyImage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      // getConfig()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for delete Resource

interface deleteResourceParams {
  resourceId: string;
}
interface ResourceResponse {
  latestApproved: [];
}
/**
 * @method PUT
 * @route
 * @returns {Promise<ResourceResponse>} returns a Promise either an error or the response object
 */
export const deleteResource = async (
  resourceId: deleteResourceParams
): Promise<ResourceResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const response: AxiosResponse<ResourceResponse> = await axios.delete(
    `${URL.RESOURCE.BASE}${URL.RESOURCE.V1_ADMIN_DELETE_RESOURCES}/${resourceId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
