import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// -----------------------------------------------------
// Define types for  share content

interface UserShareContentResponse {}
interface UserShareContentParams {
  contentId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<UserShareContentResponse>}
 */
export const shareContentAnalytics = async ({
  contentId,
}: UserShareContentParams): Promise<UserShareContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const response: AxiosResponse<UserShareContentResponse> = await axios.get(
    `${URL.ANALYTICS.BASE}${URL.ANALYTICS.V1_ANALYTICS_USER_CONTENT_SHARE}/${contentId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for  Read content

interface UserReadContentResponse {}
interface UserReadContentParams {
  contentId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<UserReadContentResponse>} returns a Promise either an error or the response object
 */
export const readContentAnalytics = async ({
  contentId,
}: UserReadContentParams): Promise<UserReadContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const response: AxiosResponse<UserReadContentResponse> = await axios.get(
    `${URL.ANALYTICS.BASE}${URL.ANALYTICS.V1_ANALYTICS_USER_CONTENT_READ}/${contentId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for  share content

interface UserShareContentResponse {}
interface UserShareContentParams {
  contentId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<UserShareContentResponse>} returns a Promise either an error or the response object
 */
export const emailClickContentAnalytics = async ({
  contentId,
}: UserShareContentParams): Promise<UserShareContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const response: AxiosResponse<UserShareContentResponse> = await axios.get(
    `${URL.ANALYTICS.BASE}${URL.ANALYTICS.V1_ANALYTICS_USER_EMAIL_CONTENT_READ}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for  share content

interface UserShareContentResponse {}
interface UserShareContentParams {
  contentId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<UserShareContentResponse>} returns a Promise either an error or the response object
 */
export const pushClickContentAnalytics = async ({
  contentId,
}: UserShareContentParams): Promise<UserShareContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const response: AxiosResponse<UserShareContentResponse> = await axios.get(
    `${URL.ANALYTICS.BASE}${URL.ANALYTICS.V1_ANALYTICS_USER_NOTIFICATION_CONTENT_READ}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for  share content

interface UserShareContentResponse {
  canView: boolean;
}
interface UserShareContentParams {
  contentId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<UserShareContentResponse>} returns a Promise either an error or the response object
 */
export const canViewNotification = async ({
  contentId,
}: UserShareContentParams): Promise<UserShareContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const response: AxiosResponse<UserShareContentResponse> = await axios.get(
    `${URL.ANALYTICS.BASE}${URL.ANALYTICS.V1_ANALYTICS_USER_NOTIFICATION_CAN_VIEW}/${contentId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for  share content

interface UserShareContentResponse {}
interface pushNotificationTrackParams {
  contentId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<UserShareContentResponse>} returns a Promise either an error or the response object
 */
export const pushNotificationTrackAnalytics = async ({
  contentId,
}: pushNotificationTrackParams): Promise<UserShareContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const response: AxiosResponse<UserShareContentResponse> = await axios.get(
    `${URL.ANALYTICS.BASE}${URL.ANALYTICS.V1_ADD_TRACK_PUSH_NOTIFICATION}/${contentId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
