import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import Details from "./Details";
import PrimaryColor from "./PrimaryColor";
import FontStep from "./Font";
import UploadLogo from "./UploadLogo";
import { useNavigate } from "react-router-dom";
import Topics from "./Topics";
import {
  getToUpdateOnboardedOrganisation,
  onboardOrganisation,
} from "../../../store/api/organisation";
import Button from "../../common/Button";
import { useOrganisation } from "../../../hooks/useOrganisation";

const OnboardingStep = ({ update }) => {
  const steps = update
    ? ["Details", "Your Logo", "Your Fonts", "Your Colour"]
    : ["Details", "Your Logo", "Your Fonts", "Your Colour", "Your Topics"];
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const companyName = localStorage.getItem("companyName");
  const queryClient = useQueryClient();
  const { secondaryColor } = useOrganisation();

  const [activeStep, setActiveStep] = useState(0);
  const [logoImage, setLogoImage] = useState(null);
  const [primaryFont, setPrimaryFont] = useState("DM Sans");
  const [primaryColorData, setPrimaryColor] = useState("#96f7dc");
  const [topics, setTopics] = useState([]);
  const [email, setEmail] = useState("");
  const [timeZone, setTimeZone] = useState(null);
  const [location, setLocation] = useState("");

  const { data: updatedData } = useQuery({
    queryKey: ["updateOnboardedOrganisation"],
    queryFn: () => getToUpdateOnboardedOrganisation({}),
    enabled: update && !!token,
  });

  useEffect(() => {
    if (updatedData && update) {
      setPrimaryColor(updatedData.primaryColor ? updatedData.primaryColor : "");
      setPrimaryFont(updatedData.primaryFont ? updatedData.primaryFont : "");
      setEmail(updatedData.email ? updatedData.email : "");
      setLogoImage(updatedData.logoImageLink ? updatedData.logoImageLink : "");
      setLocation(updatedData.location ? updatedData.location : "");
      setTimeZone(updatedData.timeZoneId ? updatedData.timeZoneId : "");
    }
  }, [update, updatedData]);

  const { mutate } = useMutation({
    mutationFn: onboardOrganisation,
    onSuccess: (data) => {
      if (update) {
        queryClient.refetchQueries({
          queryKey: ["onboardedOrganisation"],
        });
        navigate(`/${companyName}/admin-dashboard`);
      } else {
        setActiveStep(5);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (primaryFont) {
      const fontLink = document.createElement("link");
      fontLink.rel = "stylesheet";
      fontLink.href = `https://fonts.googleapis.com/css2?family=${primaryFont.replace(
        / /g,
        "+"
      )}&display=swap`;
      document.head.appendChild(fontLink);

      return () => {
        document.head.removeChild(fontLink);
      };
    }
  }, []);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleColorChange = (color) => {
    setPrimaryColor(color.hex);
  };

  const handleSave = () => {
    if (update) {
      mutate({
        primaryColor: primaryColorData,
        primaryFont,
        location,
        email,
        timeZoneId: timeZone,
        logoImage: logoImage?.contentType ? logoImage : null,
        topics,
        secondaryColor: secondaryColor,
      });
    } else {
      setActiveStep(5);
      mutate({
        primaryColor: primaryColorData,
        primaryFont,
        location,
        email,
        timeZoneId: timeZone,
        logoImage,
        topics,
      });
    }
  };
  const handleSkip = () => {
    handleSave();
  };
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Details
            email={email}
            setEmail={setEmail}
            location={location}
            setLocation={setLocation}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );

      case 1:
        return (
          <UploadLogo
            logo={logoImage}
            handleLogoUpload={setLogoImage}
            handleBack={handleBack}
            handleNext={handleNext}
            update={update}
          />
        );
      case 2:
        return (
          <FontStep
            font={primaryFont}
            setFont={setPrimaryFont}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <PrimaryColor
            font={primaryFont}
            color={primaryColorData}
            handleColorChange={handleColorChange}
            handleBack={handleBack}
            handleNext={handleNext}
            update={update}
            handleSave={handleSave}
          />
        );
      case 4:
        return (
          <Topics
            setTopics={setTopics}
            topics={topics}
            handleBack={handleBack}
            handleSave={handleSave}
          />
        );
      case 5:
        return (
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography variant="h5" mb={2}>
              Congratulations, you are all set
            </Typography>
            <Typography mb={2} variant="body">
              Lets explore your dashboard
            </Typography>
            <Box mb={2}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 48 48"
              >
                <path
                  fill={primaryColorData}
                  d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                ></path>
                <path
                  fill="#fff"
                  d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
                ></path>
              </svg>
            </Box>
            <Button
              sx={{
                p: 2,
              }}
              backgroundColor={primaryColorData}
              onClick={() => navigate(`/${companyName}/dashboard`)}
              text=" Visit Dashboard"
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", marginTop: 8 }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          "& .MuiStepLabel-root .Mui-completed": {
            color: "#96f7dc",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "#96f7dc",
          },
          "& .MuiStepLabel-label.Mui-active": {
            color: "black",
            fontWeight: "bold",
          },
          "& .MuiStepLabel-label.Mui-completed": {
            color: "black",
          },
        }}
      >
        {steps.map((label, i) => (
          <Step
            key={label}
            onClick={() => i < activeStep && activeStep < 5 && setActiveStep(i)}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Main Content */}
      <Box sx={{ marginTop: 2 }}>
        {renderStepContent(activeStep)}
        {activeStep !== 5 && !update && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button onClick={handleSkip} style={{ marginTop: 6 }} text="Skip" />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default OnboardingStep;
