import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Grid,
  Divider,
  Chip,
  Container,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getArticleByid } from "../../../store/api/content";
import Loading from "../../common/Loading";
import Button from "../../common/Button";
import { format } from "date-fns";
import ArticleResources from "../Dashboard/ArticleDetails/ArticleResource";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { roles } from "../../../constants/routes";
import { getRole } from "../../../helpers/auth";
import { IMAGE_URL_AZURE } from "../../../constants/env";

const PreviewContent = ({ open, handleClose, id }) => {
  const navigate = useNavigate();
  const { primaryColor } = useOrganisation();
  const role = getRole();
  const companyName = localStorage.getItem("companyName");

  const { data, isLoading } = useQuery({
    queryKey: ["article", id],
    queryFn: () =>
      getArticleByid({
        id,
      }),
    enabled: !!id,
  });

  const shouldShowEditButton =
    (roles.ContentCreator === role && data?.status === "Pending") ||
    roles.ContentCreator !== role;

  const handleFileClick = (file) => {
    if (
      file.contentType.startsWith("image/") ||
      file.contentType.startsWith("video/")
    ) {
    } else {
      window.open(file.url + `?${IMAGE_URL_AZURE}`, "_blank");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Container maxWidth="lg" sx={{ padding: 4 }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 900,
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2" gutterBottom>
                  {data?.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  gutterBottom
                >
                  {data?.author || ""}{" "}
                  {data?.scheduledDate
                    ? format(new Date(data.scheduledDate), "dd MMM yyyy")
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid container item xs={12} display="flex" alignItems="start">
                <Grid item xs={12} md={6}>
                  <img
                    src={`${data?.mainImage}?${IMAGE_URL_AZURE}`}
                    alt="Preview"
                    style={{ width: "300px", height: "auto" }}
                  />
                  <Box sx={{ marginTop: 2 }}>
                    {data?.topic?.split(",").map((el, i) => (
                      <Chip
                        key={i}
                        label={el}
                        style={{
                          marginRight: 4,
                          marginBottom: 4,
                          color: "black",
                          background: "white",
                          border: `1px solid ${primaryColor}`,
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="start"
                  alignItems={"start"}
                  padding={2}
                >
                  <Typography variant="body1" sx={{ marginBottom: 3 }}>
                    <div dangerouslySetInnerHTML={{ __html: data?.text }} />
                  </Typography>
                  {data?.url && (
                    <Button
                      variant="contained"
                      text="View Content’"
                      to={data?.url}
                      linkExternal={true}
                    />
                  )}
                  {data?.files && data.files.length > 0 && (
                    <Box className="mt-4">
                      <Typography
                        variant="subtitle2"
                        className="text-gray-600 mb-2"
                      >
                        Related Files:
                      </Typography>
                      <ArticleResources
                        files={data.files}
                        onFileClick={handleFileClick}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
                  <Button
                    text="Close"
                    onClick={handleClose}
                    backgroundColor="white"
                    style={{ border: "1px solid black" }}
                  />
                  {shouldShowEditButton && (
                    <Button
                      text="Edit content"
                      onClick={() =>
                        navigate(`/${companyName}/edit-content/${id}`)
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Modal>
  );
};

export default PreviewContent;
