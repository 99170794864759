import { useCallback, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import Loading from "../../../common/Loading";
import ArticleCard from "../../../common/ArticleCard";
import { getAllArticles } from "../../../../store/api/content";

const AdArticleCard = ({ ad, isMobile, isTablet }) => {
  if (!ad) return null;

  // If ad has content property, use that data
  const adContent = ad.content || { ...ad, imageLink: ad.imageUrl };
  return (
    <ArticleCard
      article={{
        ...adContent,
      }}
      isMobile={isMobile}
      isTablet={isTablet}
      isAd={true}
      external={!!adContent?.link}
      link={adContent?.link}
    />
  );
};

const PaginatedContent = ({ ads }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["articlesPagination"],
    queryFn: ({ pageParam = "" }) =>
      getAllArticles({ continuationToken: pageParam }),
    getNextPageParam: (lastPage) => lastPage.continuationToken || null,
  });

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.scrollHeight - 20
    ) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (isLoading) return <Loading />;
  if (error)
    return <Typography variant="body1">Error loading articles</Typography>;

  const renderContent = () => {
    // Find the List type ad group
    const listTypeAdGroup = ads?.ads
      ?.filter((adGroup) => adGroup.type === "List")
      .reduce((allAds, currentItem) => {
        return allAds.concat(currentItem.ads);
      }, []);
    // Get the ads from that group
    const listAds = listTypeAdGroup || [];
    let adIndex = 0;

    const allArticles =
      data?.pages.flatMap((page) => page.paginatedContent) || [];
    const itemsPerRow = isMobile ? 1 : isTablet ? 2 : 4;
    let index = 0;
    let rowNumber = 0;
    const finalContent = [];

    while (index < allArticles.length) {
      if (rowNumber % 2 === 0) {
        // Ad row
        const currentAdRowNumber = rowNumber;
        const alignment =
          Math.floor(currentAdRowNumber / 2) % 2 === 0 ? "left" : "right";
        const articlesInRow = allArticles.slice(
          index,
          index + (itemsPerRow - 1)
        );
        const ad =
          listAds.length > 0 ? listAds[adIndex % listAds.length] : null;

        const rowElements = [];
        const adElement = (
          <AdArticleCard
            key={`ad-${currentAdRowNumber}`}
            ad={ad}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        );
        if (alignment === "left") rowElements.push(adElement);

        articlesInRow.forEach((article) => {
          rowElements.push(
            <ArticleCard
              key={article.id}
              article={article}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          );
        });

        if (ad && alignment === "right") rowElements.push(adElement);
        if (ad) adIndex++;

        finalContent.push(...rowElements);
        index += articlesInRow.length;
      } else {
        // Regular row
        const articlesInRow = allArticles.slice(index, index + itemsPerRow);
        finalContent.push(
          ...articlesInRow.map((article) => (
            <ArticleCard
              key={article.id}
              article={article}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          ))
        );
        index += articlesInRow.length;
      }
      rowNumber++;
    }

    return (
      <Grid container>
        {finalContent}
        {isFetchingNextPage && <Loading />}
      </Grid>
    );
  };

  return (
    <section style={{ background: "#F0F0F0" }}>
      <section className="sectionCenter">
        <Box sx={{ padding: 2 }}>{renderContent()}</Box>
      </section>
    </section>
  );
};

export default PaginatedContent;
