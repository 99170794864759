import React, { useState, useEffect } from "react";
import { useMediaQuery, useTheme, Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams, useNavigate } from "react-router-dom";

import ScrollToTopButton from "../../common/GoTop";
import Loading from "../../common/Loading";
import TopContent from "./TopContent";
import PaginatedContent from "./PaginatedContent";
import FilterBar from "./FilterBar";
import FeaturedContent from "./FeaturedContent";
import SearchedContent from "./SearchPage";
import ArticleAds from "./ArticleAds";

import {
  getAllArticles,
  getSearchContentByUserTemplate,
  getUserSearchedContent,
} from "../../../store/api/content";
import { getOnboardedOrganisation } from "../../../store/api/organisation";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getRole } from "../../../helpers/auth";
import { roles } from "../../../constants/routes";
import Button from "../../common/Button";
import "./style.css";

interface Topic {
  id: string;
  title: string;
}

interface SearchTemplate {
  topics: Topic[];
  types: any[];
  regions: any[];
}
type EmptyStateType = "search" | "featured" | "latest" | "all";

interface EmptyStateProps {
  canAddContent: boolean;
  type: EmptyStateType;
}
const EmptyState: React.FC<EmptyStateProps> = ({ canAddContent, type }) => {
  const { companyName } = useOrganisation();
  const navigate = useNavigate();

  const messages = {
    search: {
      title: "No results found",
      description: "Try adjusting your search filters for better results.",
      actionText: canAddContent ? "Add New Content" : null,
    },
    featured: {
      title: "No Featured Content",
      description: canAddContent
        ? "Add featured content to highlight important information."
        : "Featured content coming soon!",
      actionText: canAddContent ? "Add Featured Content" : null,
    },
    latest: {
      title: "No Latest Content",
      description: canAddContent
        ? "Add some content to get started."
        : "New content coming soon!",
      actionText: canAddContent ? "Add Content" : null,
    },
    all: {
      title: "No Content Available",
      description: canAddContent
        ? "Start building your content library."
        : "Content is being prepared. Check back soon!",
      actionText: canAddContent ? "Create First Content" : null,
    },
  };

  const content = messages[type];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        py: 6,
        px: 2,
        my: 3,
        borderRadius: 2,
        backgroundColor: "rgba(0,0,0,0.02)",
        minHeight: "200px",
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        {content.title}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mb: 3, maxWidth: "600px" }}
      >
        {content.description}
      </Typography>
      {content.actionText && canAddContent && (
        <Button
          onClick={() => navigate(`/${companyName}/create-content`)}
          text={content.actionText}
        />
      )}
    </Box>
  );
};

const DashboardPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { primaryColor } = useOrganisation();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const role = getRole();
  const canAddContent = role !== roles.Reader;

  const [region, setRegion] = useState<string[]>([]);
  const [topic, setTopic] = useState<string[]>([]);
  const [type, setType] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [isSearch, setIsSearch] = useState<boolean>(false);

  // Function to check if any filter is active
  const hasActiveFilters = () => {
    return (
      region.length > 0 ||
      topic.length > 0 ||
      type.length > 0 ||
      search.length > 2
    );
  };

  const { data: searchedContent, isLoading: isSearchLoading } = useQuery({
    queryKey: ["searchedContent", search, region, topic, type],
    queryFn: () =>
      getUserSearchedContent({
        searchTerm: search,
        usersIds: null,
        topicIds: topic.length ? topic : null,
        regionIds: region.length ? region : null,
        typeIds: type.length ? type : null,
      }),
    enabled: hasActiveFilters(),
  });

  const { data: filters } = useQuery<SearchTemplate>({
    queryKey: ["searchTemplate"],
    queryFn: () => getSearchContentByUserTemplate({}),
  });

  useEffect(() => {
    const topicParam = searchParams.get("topic");
    if (topicParam && filters?.topics) {
      const matchingTopic = filters.topics.find(
        (el) => el?.title === topicParam
      );
      setTopic(matchingTopic?.id ? [matchingTopic.id] : []);
      setIsSearch(true);
    }
  }, [searchParams, filters?.topics]);

  const { data, error, isLoading } = useQuery({
    queryKey: ["contentUser"],
    queryFn: () => getAllArticles({ continuationToken: "" }),
    enabled: !hasActiveFilters(),
  });

  const {} = useQuery({
    queryKey: ["onboardedOrganisationDashboard"],
    queryFn: () => getOnboardedOrganisation(),
  });

  const handleTopicChange = (newTopics: string[]) => {
    setTopic(newTopics);
    navigate("");
  };

  const hasLatestContent = Boolean(
    data?.latestApproved && data.latestApproved.length > 0
  );
  const hasFeaturedContent = Boolean(
    data?.latestFeatured && data.latestFeatured.length > 0
  );
  const hasPaginatedContent = Boolean(
    data?.paginatedContent && data.paginatedContent.length > 0
  );
  const hasAdsContent = Boolean(data?.ads && data.ads.length > 0);
  const hasSearchResults = Boolean(searchedContent);

  const isCompletelyEmpty =
    !hasLatestContent &&
    !hasFeaturedContent &&
    !hasPaginatedContent &&
    !hasAdsContent;

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <ScrollToTopButton />
      <div>
        <FilterBar
          region={region}
          setRegion={setRegion}
          topicList={filters?.topics}
          topic={topic}
          type={type}
          typeList={filters?.types}
          regionList={filters?.regions}
          setTopic={handleTopicChange}
          setType={setType}
          search={search}
          setIsSearch={setIsSearch}
          setSearch={setSearch}
        />
      </div>

      {hasActiveFilters() ? (
        isSearchLoading ? (
          <Loading />
        ) : hasSearchResults ? (
          <SearchedContent
            searchedContent={searchedContent}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        ) : (
          <EmptyState canAddContent={canAddContent} type="search" />
        )
      ) : (
        <>
          {isCompletelyEmpty ? (
            <EmptyState canAddContent={canAddContent} type="all" />
          ) : (
            <>
              <div>
                {hasLatestContent ? (
                  <TopContent articles={data?.latestApproved} />
                ) : null}

                {hasFeaturedContent ? (
                  <div
                    style={{
                      backgroundColor: primaryColor || "black",
                    }}
                  >
                    <FeaturedContent articles={data?.latestFeatured} />
                  </div>
                ) : null}
              </div>

              {hasAdsContent && <ArticleAds data={data?.ads} />}

              {hasPaginatedContent ? <PaginatedContent ads={data} /> : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default DashboardPage;
