import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { IconButton, Box, Tooltip, Snackbar, Typography } from "@mui/material";
import { LinkedIn, ContentCopy, Email, X } from "@mui/icons-material";
import { shareContentAnalytics } from "../../../../store/api/analytics";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import { IMAGE_URL_AZURE } from "../../../../constants/env";

const ShareHeader = ({ article }) => {
  const { companyName, logoLink } = useOrganisation();
  const [copySuccess, setCopySuccess] = useState(false);
  const [shareMetadata, setShareMetadata] = useState({
    title: "",
    summary: "",
    source: "",
    logoUrl: "",
  });
  const { id } = useParams();

  const { mutate: shareMutate } = useMutation({
    mutationFn: () => shareContentAnalytics({ contentId: id }),
  });

  useEffect(() => {
    if (companyName) {
      const fullLogoUrl = logoLink
        ? new URL(logoLink, window.location.origin).toString()
        : "";

      setShareMetadata({
        title: `${companyName} - ${article?.title}`,
        summary: "Interesting article to share",
        source: window.location.hostname,
        logoUrl: fullLogoUrl,
      });
    }
  }, [companyName, logoLink, article]);

  // const handleLinkedInShare = () => {
  //   const articleUrl = window.location.href;

  //   const params = {
  //     mini: "true",
  //     url: articleUrl,
  //     title: shareMetadata.title,
  //     summary: shareMetadata.summary,
  //     source: shareMetadata.source,
  //   };
  //   if (shareMetadata.logoUrl) {
  //     const fullLogoUrl = shareMetadata.logoUrl.includes("http")
  //       ? shareMetadata.logoUrl
  //       : `${IMAGE_URL_AZURE}${shareMetadata.logoUrl}`;
  //     params.image = encodeURIComponent(fullLogoUrl);
  //   }

  //   const queryString = Object.entries(params)
  //     .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
  //     .join("&");

  //   const linkedInShareUrl = `https://www.linkedin.com/shareArticle?${queryString}`;

  //   window.open(linkedInShareUrl, "_blank", "noopener,noreferrer");
  //   shareMutate(id);
  // };

  const handleLinkedInShare = () => {
    const articleUrl = window.location.href;

    const params = {
      mini: "true",
      url: articleUrl,
      title: shareMetadata.title,
      summary: shareMetadata.summary,
      source: shareMetadata.source,
    };

    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?${queryString}`;

    window.open(linkedInShareUrl, "_blank", "noopener,noreferrer");
    shareMutate(id);
  };

  const handleEmailShare = () => {
    const subject = encodeURIComponent(shareMetadata.title);
    const body = encodeURIComponent(
      `I found this interesting article from ${shareMetadata.source}: ${window.location.href}`
    );
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
    shareMutate(id);
  };

  const handleTwitterShare = () => {
    const tweetText = encodeURIComponent(shareMetadata.title);
    const tweetUrl = encodeURIComponent(window.location.href);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;
    window.open(twitterUrl, "_blank");
    shareMutate(id);
  };

  const handleCopyLink = () => {
    const articleUrl = window.location.href;
    navigator.clipboard.writeText(articleUrl).then(() => {
      setCopySuccess(true);
    });
    shareMutate(id);
  };

  const handleCloseSnackbar = () => {
    setCopySuccess(false);
  };

  return (
    <Box display="flex" alignItems="center" padding={2}>
      <Tooltip title="Share on LinkedIn">
        <IconButton
          onClick={handleLinkedInShare}
          aria-label="Share on LinkedIn"
        >
          <LinkedIn style={{ color: "#2677b5" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on Twitter">
        <IconButton
          onClick={handleTwitterShare}
          aria-label="Share on Twitter (X)"
        >
          <X />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share via Email">
        <IconButton onClick={handleEmailShare} aria-label="Share via Email">
          <Email />
        </IconButton>
      </Tooltip>
      <Tooltip title="Copy link">
        <IconButton onClick={handleCopyLink}>
          <ContentCopy />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="Link copied!"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </Box>
  );
};

export default ShareHeader;
