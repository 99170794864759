import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  useMediaQuery,
  Chip,
  Container,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { format, formatDistanceToNow } from "date-fns";
import { X, RefreshCw } from "lucide-react";

import ArticleResources from "./ArticleResource";
import ShareArticle from "./ShareArticle";
import Loading from "../../../common/Loading";
import Button from "../../../common/Button";

import { useOrganisation } from "../../../../hooks/useOrganisation";
import { getArticleByid } from "../../../../store/api/content";
import {
  emailClickContentAnalytics,
  pushClickContentAnalytics,
  readContentAnalytics,
} from "../../../../store/api/analytics";
import { getTextColor } from "../../../../helpers";
import { IMAGE_URL_AZURE } from "../../../../constants/env";

// Create a styled component for the zoom effect
const ZoomableCardMedia = ({ image, alt, height }) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        height: height || "auto",
        position: "relative",
      }}
    >
      <CardMedia
        component="img"
        height={height}
        image={image}
        alt={alt}
        sx={{
          transition: "transform 0.5s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      />
    </Box>
  );
};

const ErrorDisplay = ({ message, onRetry, onGoHome }) => {
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");
  const { primaryColor } = useOrganisation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50vh",
        textAlign: "center",
        padding: 4,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Oops! We couldn't find that article
      </Typography>
      <Typography variant="body1" paragraph>
        {message ||
          "The article you're looking for might have been removed, had its name changed, or is temporarily unavailable."}
      </Typography>
      <Box sx={{ display: "flex", gap: 2, marginTop: 3 }}>
        <Button
          startIcon={<RefreshCw size={16} />}
          onClick={onRetry}
          backgroundColor="white"
          text="Try Again"
        />

        <Button
          onClick={() => navigate(`/${companyName}/dashboard`)}
          text="Browse Articles"
        />
      </Box>
    </Box>
  );
};

const ArticlePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const ComingFrom = searchParams.get("ComingFrom");

  const companyName = localStorage.getItem("companyName");
  const { primaryColor, primaryColorLight } = useOrganisation();
  const [selectedFile, setSelectedFile] = useState(null);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["article", id],
    queryFn: () => getArticleByid({ id }),
    enabled: !!id,
    retry: 1,
    onError: (error) => {
      console.error("Error fetching article:", error);
    },
  });

  const { data: analytics } = useQuery({
    queryKey: ["contentAnalytics", id],
    queryFn: () => readContentAnalytics({ contentId: id }),
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: false,
    enabled: !isError && !!data,
  });

  const {} = useQuery({
    queryKey: ["emailcontentAnalytics", id],
    queryFn: () => emailClickContentAnalytics({ contentId: id }),
    enabled: ComingFrom === "email" && !isError && !!data,
  });

  const {} = useQuery({
    queryKey: ["notificationcontentAnalytics", id],
    queryFn: () => pushClickContentAnalytics({ contentId: id }),
    enabled: ComingFrom === "push" && !isError && !!data,
  });
  useEffect(() => {
    if (ComingFrom === "email" && data?.url) {
      const userAgent = navigator.userAgent.toLowerCase();
      const isOutlookApp =
        userAgent.includes("outlook") ||
        userAgent.includes("microsoft") ||
        window.location.search.includes("source=outlook");
      // First attempt: try window.open directly
      const newWindow = window.open(data.url, "_blank");

      // If window.open fails or we're in Outlook, try alternative methods
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined" ||
        isOutlookApp
      ) {
        // Method 2: Create and click a link
        const link = document.createElement("a");
        link.href = data.url;
        link.target = "_blank";
        link.rel = "noopener noreferrer";

        // Make it more visible to encourage popup allowance
        link.style.position = "fixed";
        link.style.top = "50%";
        link.style.left = "50%";
        link.style.transform = "translate(-50%, -50%)";
        link.style.zIndex = "9999";
        link.style.background = primaryColor || "#007bff";
        link.style.color = "white";
        link.style.padding = "15px 20px";
        link.style.borderRadius = "5px";
        link.style.textDecoration = "none";
        link.style.fontWeight = "bold";
        link.textContent = "Click to open content";

        document.body.appendChild(link);

        // Set a timeout to simulate a slightly delayed click
        // This can help with some browsers/email clients
        setTimeout(() => {
          link.click();

          // Remove the link after a short delay
          setTimeout(() => {
            if (document.body.contains(link)) {
              document.body.removeChild(link);
            }
          }, 100);
        }, 100);
      }
    }
  }, [ComingFrom, data, primaryColor]);

  useEffect(() => {
    // If user is not logged in and came from email, store the article URL
    const token = localStorage.getItem("token");
    if (!token && ComingFrom === "email" && id) {
      localStorage.setItem("pendingArticleId", id);
      localStorage.setItem("pendingArticleSource", ComingFrom);
    }
  }, [id, ComingFrom]);

  const handleFileClick = (file) => {
    if (
      file.contentType.startsWith("image/") ||
      file.contentType.startsWith("video/")
    ) {
      setSelectedFile(file);
    } else {
      window.open(file.url + `?${IMAGE_URL_AZURE}`, "_blank");
    }
  };

  const getImageWithToken = (url) => {
    if (!url) return "";
    return `${url}?${IMAGE_URL_AZURE}`;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !data) {
    return (
      <Container maxWidth="lg" className="sectionCenter">
        <ErrorDisplay
          message={
            error?.response?.status === 403
              ? "You don't have permission to access this article."
              : "This article could not be found or is temporarily unavailable."
          }
          onRetry={() => refetch()}
          onGoHome={() => navigate(`/${companyName}/dashboard`)}
        />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" className="sectionCenter">
      <Box sx={{ padding: isSmallScreen ? 2 : "24px" }}>
        <Grid container spacing={isSmallScreen ? 2 : 0}>
          <Grid container item xs={12} md={9} alignItems={"start"}>
            <Grid item xs={12} md={10} paddingLeft={0}>
              <Typography variant="h4" paddingLeft={0}>
                {data?.title}
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={{ marginBottom: 2 }}
              >
                {data?.author || ""}{" "}
                {data?.scheduledDate
                  ? format(new Date(data.scheduledDate), "dd MMM yyyy")
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid container xs={12} md={12} display="flex" alignItems="start">
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  image={getImageWithToken(data?.mainImage)}
                  alt="Article Image"
                />
                <Box sx={{ marginTop: 2 }}>
                  {data?.topic &&
                    data.topic
                      .split(",")
                      .slice(0, 3)
                      .map((el, i) => (
                        <Chip
                          key={i}
                          label={el}
                          style={{
                            marginRight: 4,
                            marginBottom: 4,
                            color: "black",
                            background: "white",
                            border: `1px solid ${primaryColor}`,
                          }}
                          onClick={() =>
                            navigate(`/${companyName}/dashboard?topic=${el}`)
                          }
                        />
                      ))}
                </Box>
                {data?.isShearable && (
                  <Grid item md={6} xs={6}>
                    <ShareArticle article={data} />
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems={"start"}
                padding={2}
                paddingTop={0}
              >
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 3, marginTop: "0!important" }}
                >
                  <Box
                    sx={{
                      display: "-webkit-box",
                      marginTop: 0,
                      "& p": {
                        margin: 0,
                      },
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: data?.text }} />
                  </Box>
                </Typography>
                {data?.url && (
                  <Button
                    variant="contained"
                    text="View Content"
                    to={data?.url}
                    linkExternal={true}
                  />
                )}
                {data?.files && data.files.length > 0 && (
                  <Box className="mt-4">
                    <Typography
                      variant="subtitle2"
                      className="text-gray-600 mb-2"
                    >
                      Related Files:
                    </Typography>
                    <ArticleResources
                      files={data.files}
                      onFileClick={handleFileClick}
                      primaryColor={primaryColor}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: 2 }}
              style={{ alignSelf: "start" }}
            >
              Featured
            </Typography>
            <Box>
              {data.featuredContents &&
                data.featuredContents.map((item, index) => (
                  <Card
                    key={index}
                    sx={{ marginBottom: 2 }}
                    onClick={() =>
                      navigate(`/${companyName}/article/details/${item.id}`)
                    }
                    style={{
                      borderRadius: 0,
                      boxShadow: "none",
                      cursor: "pointer",
                    }}
                  >
                    <ZoomableCardMedia
                      image={getImageWithToken(item?.mainImage)}
                      alt={`Featured ${index}`}
                      height="120"
                    />
                    <CardContent style={{ background: primaryColorLight }}>
                      <Typography
                        variant="subtitle1"
                        color={getTextColor(primaryColor) || "black"}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        color={getTextColor(primaryColor) || "black"}
                      >
                        {item.scheduledDate &&
                          formatDistanceToNow(new Date(item.scheduledDate), {
                            addSuffix: true,
                          })}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
            </Box>
          </Grid>
        </Grid>
        {data.relatedContents && data.relatedContents.length > 0 && (
          <Box sx={{ marginTop: 5 }}>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              You may be interested in?
            </Typography>
            <Grid container spacing={isSmallScreen ? 2 : 5}>
              {data.relatedContents.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    style={{
                      cursor: "pointer",
                      boxShadow: "none",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 0,
                      height: "300px", // Fixed height for consistency
                    }}
                    onClick={() =>
                      navigate(`/${companyName}/article/details/${item?.id}`)
                    }
                  >
                    <ZoomableCardMedia
                      image={getImageWithToken(item?.mainImage)}
                      alt="Article Image"
                      height="120px"
                    />
                    <CardContent
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "130px",
                      }}
                    >
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        style={{
                          color: primaryColor,
                          fontWeight: "bold",
                          fontSize: 10,
                        }}
                      >
                        {item?.topics &&
                          item.topics.split(",").slice(0, 3).join(", ")}
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{
                          marginBottom: "auto",
                          fontWeight: "bold",
                          fontSize: 16,
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          {item?.scheduledDate &&
                            formatDistanceToNow(new Date(item?.scheduledDate), {
                              addSuffix: true,
                            })}
                        </Typography>
                        <hr
                          style={{
                            border: 0,
                            borderTop: "1px solid #ccc",
                            margin: "8px 0",
                          }}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        <Dialog
          open={!!selectedFile}
          onClose={() => setSelectedFile(null)}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent className="relative p-0">
            <button
              onClick={() => setSelectedFile(null)}
              className="absolute right-2 top-2 p-2 rounded-full bg-white shadow-md hover:bg-gray-100 transition-colors duration-200"
            >
              <X className="w-6 h-6" />
            </button>
            {selectedFile?.contentType.startsWith("image/") ? (
              <img
                src={selectedFile.url + `?${IMAGE_URL_AZURE}`}
                alt={selectedFile.title}
                className=""
              />
            ) : selectedFile?.contentType.startsWith("video/") ? (
              <video
                src={selectedFile.url + `?${IMAGE_URL_AZURE}`}
                controls
                className=""
              />
            ) : null}
          </DialogContent>
        </Dialog>
      </Box>
    </Container>
  );
};

export default ArticlePage;
