import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";

const ListTable = ({
  data = [],
  columns,
  isLoading,
  totalPage,
  setCurrentPage,
  currentPage,
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");

  const cellStyles = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px",
    padding: "8px 16px",
  };

  const actionCellStyles = {
    ...cellStyles,
    minWidth: "140px",
    maxWidth: "none",
    overflow: "visible",
    position: "relative",
    zIndex: 1,
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "black", color: "white" }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    sx={{
                      color: "white",
                      borderColor: "black",
                      ...(header.id === "actions"
                        ? actionCellStyles
                        : cellStyles),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {table?.getRowModel().rows.map((row, i) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: i % 2 === 0 ? "#f5f5f5" : "white",
                  }}
                >
                  {row?.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      sx={
                        cell.column.id === "actions"
                          ? actionCellStyles
                          : cellStyles
                      }
                    >
                      {flexRender(
                        cell?.column?.columnDef?.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        p={2}
      >
        <Grid item xs={12} md={6}>
          <Pagination
            count={totalPage}
            color="primary"
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: { xs: "left", md: "right" } }}
        >
          <Button
            variant="raised"
            onClick={() => navigate(`/${companyName}/admin-dashboard`)}
          >
            Back to dashboard
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ListTable;
