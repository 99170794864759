import React, { useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";

const EmailHandler = () => {
  const { companyName, id } = useParams(); // Extract companyName and article ID from the URL
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const ComingFrom = searchParams.get("comingFrom");
    console.log(searchParams.get("comingFrom"));

    if (!token && id) {
      localStorage.setItem("pendingArticleId", id);
      localStorage.setItem("pendingArticleCompany", companyName); // Save companyName for redirect
      localStorage.setItem("pendingArticleSource", "email");
    }

    // Redirect to the login page
    navigate("/");
  }, [id, companyName, searchParams, navigate]);

  return null; // No need to render anything
};

export default EmailHandler;
