import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import DownloadIcon from "@mui/icons-material/Download";
import { createExcelTemplate } from "./template";
import Button from "../../../common/Button";

const AddCSV = ({ handleNextStep, handleBackStep, csvData, handleDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });
  const [download, setDownload] = useState(false);

  return (
    <div>
      <div>
        <Button
          onClick={() => {
            createExcelTemplate();
            setDownload(true);
          }}
          text={"Download Template"}
        />
        <Box
          sx={{
            pointerEvents: download ? "auto" : "none",
            opacity: download ? 1 : 0.5,
            mt: 3,
          }}
        >
          <div
            {...getRootProps()}
            style={{
              border: "1px dashed gray",
              padding: "20px",
              marginTop: "20px",
              color: download ? "black" : "gray",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: download ? "pointer" : "not-allowed",
            }}
          >
            <DownloadIcon />
            <input disabled={!download} {...getInputProps()} />
            <Typography variant="body1">
              Drag and drop or click to upload a CSV file
            </Typography>
          </div>
        </Box>
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <Button
          variant="contained"
          onClick={() => {
            handleNextStep();
          }}
          disabled={!csvData.length}
          text="Upload CSV file"
        />
        <Button
          variant="outlined"
          onClick={handleBackStep}
          text=" Back"
          backgroundColor="white"
        />
      </div>
    </div>
  );
};

export default AddCSV;
