import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Box, Grid, Typography } from "@mui/material";
import Input from "../../common/Input";
import { resetPassword } from "../../../store/api/users";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import Toast from "../../common/Toast";
import { Link } from "react-router-dom";

const Reset = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    password: false,
    confirmPassword: false,
    samePassword: false,
  });
  const [toast, setToast] = useState({ message: null, status: null });
  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [linkExpired, setLinkExpired] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    const email = JSON.parse(localStorage.getItem("email"));
    // eslint-disable-next-line no-use-before-define
    const resetToken = JSON.parse(localStorage.getItem("resetToken"));
    setEmail(email);
    setResetToken(resetToken);
  }, []);

  const validateForm = () => {
    let hasError = false;
    const newErrors = {
      password: false,
      confirmPassword: false,
      samePassword: false,
    };

    if (password === "") {
      newErrors.password = true;
      hasError = true;
    }
    if (confirmPassword === "") {
      newErrors.confirmPassword = true;
      hasError = true;
    }
    if (confirmPassword !== password) {
      newErrors.samePassword = true;
      hasError = true;
    }
    setErrors(newErrors);
    return !hasError;
  };

  const SubmitForm = () => {
    if (validateForm()) {
      // Force the toast to reset before submitting
      setToast({ message: null, status: null });

      ForgetPasswordMutate.mutate({
        newPassword: password,
        email,
        token: resetToken,
      });
    }
  };

  const ForgetPasswordMutate = useMutation({
    mutationFn: resetPassword,
    onSuccess: (data) => {
      // Handle success
      setToast({ message: "Password updated successfully!", status: 200 });
      navigate("/");
    },
    onError: (error) => {
      let errorMessage = error.response?.data?.title || "Something went wrong.";
      let status = error.response?.status || 400;

      // Check if the error is related to an expired or invalid token
      if (
        // status === 400 ||
        status === 401 ||
        errorMessage.toLowerCase().includes("invalid") ||
        errorMessage.toLowerCase().includes("expire")
      ) {
        setLinkExpired(true);
        errorMessage = "The link has expired.";
      }

      // Set the toast with the error message
      setToast({ message: errorMessage, status });
    },
  });

  // This useEffect ensures the toast is properly reset and shown again
  useEffect(() => {
    if (toast.message) {
      const timer = setTimeout(() => {
        setToast({ message: null, status: null });
      }, 5000); // Toast will disappear after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [toast.message]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "white",
        padding: 4,
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          width: "100%",
          textAlign: "center",
          border: "1px solid #ccc",
          borderRadius: 2,
          padding: 4,
          bgcolor: "background.paper",
          boxShadow: 3,
        }}
      >
        <Typography variant="h4">Reset Password</Typography>

        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {errors.samePassword && (
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="span" style={{ color: "red" }}>
                  You should enter the same password
                </Typography>
              </Grid>
            )}
            {linkExpired && (
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={2}
              >
                <Typography variant="body1" style={{ color: "red" }}>
                  The link has expired. Click{" "}
                  <Link to="/forgetPassword" style={{ color: "blue" }}>
                    here
                  </Link>{" "}
                  to resend.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Input
                required={true}
                onChange={setPassword}
                value={password}
                label="Password"
                type="password"
                error={errors.password}
                helperText={errors.password ? "Password is required" : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required={true}
                onChange={setConfirmPassword}
                value={confirmPassword}
                label="Confirm Password"
                type="password"
                error={errors.confirmPassword}
                helperText={
                  errors.confirmPassword ? "Password is required" : ""
                }
              />
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              alignItems="center"
              item
              xs={12}
            >
              <Button onClick={SubmitForm} text={"Continue"} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {toast.message && <Toast message={toast.message} status={toast.status} />}
    </Box>
  );
};

export default Reset;
