import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Box, Grid, Typography } from "@mui/material";
import Input from "../../common/Input";
import { resetPassword } from "../../../store/api/users";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import Toast from "../../common/Toast";

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    password: false,
    confirmPassword: false,
    samePassword: false,
  });
  const [toast, setToast] = useState({ message: null, status: null });

  const validateForm = () => {
    let hasError = false;
    const newErrors = {
      password: false,
      confirmPassword: false,
      samePassword: false,
    };

    if (password === "") {
      newErrors.password = true;
      hasError = true;
    }
    if (confirmPassword === "") {
      newErrors.confirmPassword = true;
      hasError = true;
    }
    if (confirmPassword !== password) {
      newErrors.samePassword = true;
      hasError = true;
    }
    setErrors(newErrors);
    return !hasError;
  };

  const SubmitForm = () => {
    if (validateForm()) {
      ForgetPasswordMutate.mutate({ password });
    }
  };

  const ForgetPasswordMutate = useMutation({
    mutationFn: resetPassword,
    onSuccess: (data) => {
      // Handle success
      setToast({ message: "Password reset email sent!", status: 200 });
      navigate("/");
    },
    onError: (error) => {
      let errorMessage = error.response?.data?.title || "Something went wrong.";
      let status = error.response.status;

      setToast({ message: errorMessage, status });
    },
  });
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "white",
        padding: 4,
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          width: "100%",
          textAlign: "center",
          border: "1px solid #ccc",
          borderRadius: 2,
          padding: 4,
          bgcolor: "background.paper",
          boxShadow: 3,
        }}
      >
        <Typography variant="h4">Reset Password</Typography>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {errors.samePassword && (
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="span" style={{ color: "red" }}>
                  You should enter the same password
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Input
                required={true}
                onChange={setPassword}
                value={password}
                label="Password"
                type="password"
                error={errors.password}
                helperText={errors.password ? "Password is required" : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required={true}
                onChange={setConfirmPassword}
                value={confirmPassword}
                label="Confirm Password"
                type="password"
                error={errors.confirmPassword}
                helperText={
                  errors.confirmPassword ? "Password is required" : ""
                }
              />
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              alignItems="center"
              item
              xs={12}
            >
              <Button onClick={SubmitForm} text={"Continue"} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {toast.message && <Toast message={toast.message} status={toast.status} />}
    </Box>
  );
};

export default ResetPasswordPage;
