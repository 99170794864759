import React, { useState } from "react";
import { Paper, Typography, Box } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";

const CustomPieChart = ({ data, title, onArticleClick }) => {
  const pieColors = ["#10B981", "#4B9EFF", "#8B5CF6"];
  const [selectedSliceIndex, setSelectedSliceIndex] = useState(null);

  const processedData = React.useMemo(() => {
    if (!data) return [];
    return data
      .sort((a, b) => a.value - b.value)
      .map((article) => ({
        id: article.name,
        value: article.count,
        label: article.name,
        originalData: article,
      }));
  }, [data]);

  const total = processedData.reduce((sum, entry) => sum + entry.value, 0);

  const handleClick = (item, index) => {
    setSelectedSliceIndex(index);

    if (onArticleClick && item?.id) {
      onArticleClick(item);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        height: "100%",
        minHeight: "300px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "0.875rem",
          fontWeight: 500,
          color: "#374151",
          marginBottom: 2,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <PieChart
          series={[
            {
              data: processedData,
              innerRadius: 60,
              outerRadius: 100,
              paddingAngle: 2,
              cornerRadius: 4,
              startAngle: -180,
              endAngle: 180,
              colors: pieColors,
              tooltip: {
                valueFormatter: (value) => {
                  const percentage = ((value / total) * 100).toFixed(0);
                  return `${value} (${percentage}%)`;
                },
              },
              // arcLabel: (item) => `${item.label}`,
              arcLabelMinAngle: 45,
              // onClick: (event, elementIndex, data) => {
              //   console.log("Pie Click Event:", { event, elementIndex, data });
              //   const clickedItem = processedData[elementIndex];
              //   handleClick(clickedItem.originalData);
              // },
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 58, additionalRadius: -30, color: "gray" },
            },
          ]}
          height={250}
          margin={{
            top: 10,
            bottom: 10,
            left: 20,
            right: 20,
          }}
          slotProps={{
            legend: { hidden: true },
          }}
          onItemClick={(event, elementIndex, data) => {
            console.log("Pie Click Event:", { event, elementIndex, data });
            handleClick(data.originalData, elementIndex);
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: 2,
            width: "100%",
            maxWidth: "280px",
          }}
        >
          {processedData.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "0.75rem",
                color: "#4B5563",
                cursor: "pointer",
                "&:hover": {
                  opacity: 0.8,
                },
                transition: "opacity 0.2s",
                padding: "4px",
                borderRadius: "4px",
                "&:active": {
                  backgroundColor:
                    selectedSliceIndex === index
                      ? "rgba(0, 0, 0, 0.05)"
                      : "transparent",
                },
              }}
              onClick={() => {
                console.log("Legend Click Event:", item);
                handleClick(item.originalData);
              }}
            >
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "100%",
                  backgroundColor: pieColors[index],
                  border:
                    selectedSliceIndex === index ? "2px solid #000" : "none",
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  fontWeight: selectedSliceIndex === index ? 600 : 400,
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {item.label} ({((item.value / total) * 100).toFixed(0)}%)
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default CustomPieChart;
