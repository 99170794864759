import { Box } from "@mui/material";
import React from "react";

const Footer = () => {
  // const { secondaryColor } = useOrganisation();
  // const textColorWithSecondary = getTextColor(secondaryColor);

  return (
    <Box
      sx={{
        width: "100%",
        // color: textColorWithSecondary,
        backgroundColor: "transparent",
        bottom: 0,
        left: 0,
      }}
    >
      <footer
        style={{
          padding: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* {textColorWithSecondary === "white" ? (
            <img src="/favicon/Komz_Logo_White.png" alt="" width={"20%"} />
          ) : ( */}
          <img src="/favicon/Komz_Logo_Black.png" alt="" width={"20%"} />
          {/* )} */}
        </Box>
      </footer>
    </Box>
  );
};
export default Footer;
