import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ReactQuill from "react-quill";
import {
  TextField,
  Grid,
  Typography,
  Box,
  Autocomplete,
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "react-quill/dist/quill.snow.css";
import { getTopicsForArticle } from "../../../../store/api/content";
import CreateImage from "./CreateContent";
import Button from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { roles } from "../../../../constants/routes";
import { getRole } from "../../../../helpers/auth";
import { addTopic } from "../../../../store/api/topic";
import { addType } from "../../../../store/api/type";
import { addRegion } from "../../../../store/api/region";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const CreateContent = ({
  setStep,
  step,
  isContentFromUrl,
  setContent,
  content,
  image,
  setImage,
  imageList,
  setTitle,
  title,
  setTopics,
  setTypes,
  setRegions,
  topics,
  types,
  regions,
  imageError,
  titleError,
  summaryError,
  isEdit,
  author,
  setAuthor,
}) => {
  const navigate = useNavigate();
  const role = getRole();
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();
  const companyName = localStorage.getItem("companyName");
  const [topicsDATA, setTopicsDATA] = useState([]);
  const [regionsDATA, setRegionsDATA] = useState([]);
  const [typesDATA, setTypesDATA] = useState([]);
  const [errors, setErrors] = useState({
    title: false,
    topics: false,
    content: false,
    types: false,
    regions: false,
    image: false,
  });

  // New item dialog state
  const [newItemDialog, setNewItemDialog] = useState({
    open: false,
    type: "", // "topic", "type", or "region"
    value: "",
  });

  // Refs for error fields
  const titleRef = useRef(null);
  const topicsRef = useRef(null);
  const typesRef = useRef(null);
  const regionsRef = useRef(null);
  const imageRef = useRef(null);
  const contentRef = useRef(null);

  const { data, isLoading } = useQuery({
    queryKey: ["TopicTypeForArticle"],
    queryFn: () => getTopicsForArticle(),
  });

  useEffect(() => {
    if (!isLoading && data) {
      setTopicsDATA(data.topics);
      setTypesDATA(data.types);
      setRegionsDATA(data.regions);
      !isEdit && setAuthor(data?.author);
    }
  }, [isLoading, data]);

  const addFunctionMap = {
    topics: addTopic,
    types: addType,
    regions: addRegion,
  };
  const { mutate: addMutate } = useMutation({
    mutationFn: ({ value, type }) => addFunctionMap[type](value),
    onSuccess: (data) => {
      queryClient.refetchQueries("TopicTypeForArticle");
      handleCloseDialog();
    },
    onError: (error) => {},
  });

  const handleSetForm = () => {
    let hasError = false;
    const newErrors = {
      title: false,
      topics: false,
      types: false,
      regions: false,
      content: false,
      image: !image,
    };

    if (title.trim() === "") {
      newErrors.title = true;
      hasError = true;
    }

    if (content.trim() === "") {
      newErrors.content = true;
      hasError = true;
    }

    if (topics.length === 0) {
      newErrors.topics = true;
      hasError = true;
    }
    if (regions.length === 0) {
      newErrors.regions = true;
      hasError = true;
    }
    if (types.length === 0) {
      newErrors.types = true;
      hasError = true;
    }
    if (!image) {
      newErrors.image = true;
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      if (newErrors.title) {
        titleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (newErrors.topics) {
        topicsRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (newErrors.types) {
        typesRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (newErrors.regions) {
        regionsRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (newErrors.image) {
        imageRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (newErrors.content) {
        contentRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      setStep(step + 1);
    }
  };

  // Function to handle adding a new item
  const handleAddNewItem = (type) => {
    setNewItemDialog({
      open: true,
      type,
      value: "",
    });
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setNewItemDialog({
      ...newItemDialog,
      open: false,
    });
  };

  // Function to save the new item
  const handleSaveNewItem = () => {
    const { type, value } = newItemDialog;
    if (!value.trim()) {
      return;
    }

    addMutate({ type, value });
  };

  const autocompleteStyles = {
    width: "100%",
    maxWidth: "800px", // Set a max-width for consistency
    "& .MuiAutocomplete-tag": {
      maxWidth: "150px",
      margin: "3px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      height: "32px",
      "& .MuiChip-label": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      flexWrap: "wrap",
      maxHeight: "200px",
      overflowY: "auto",
      padding: "8px",
      gap: "4px",
      "& .MuiAutocomplete-input": {
        width: "100px !important",
      },
    },
    "& .MuiOutlinedInput-root": {
      padding: "4px",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      width: "100%",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  };

  const containerStyles = {
    maxWidth: "800px", // Match with autocomplete max-width
    margin: "0 auto",
    padding: "0 16px",
    width: "100%",
  };

  return (
    <section className="sectionCenter" style={{ minHeight: "100vh" }}>
      <Box sx={containerStyles}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs={12} ref={titleRef}>
            <TextField
              label="Title*"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={errors.title}
              sx={{ width: "100%" }}
            />
            {titleError && (
              <Typography variant="caption" color="error">
                We Can't pull the title from the url, please add it
              </Typography>
            )}
          </Grid>

          {/* Author */}
          <Grid item xs={12}>
            <TextField
              label="Author"
              variant="outlined"
              fullWidth
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              placeholder="Defaults to your name if left blank"
              disabled={roles.ContentCreator === role}
              sx={{ width: "100%" }}
            />
          </Grid>

          {/* Topics */}
          <Grid item xs={12} ref={topicsRef}>
            <Box sx={{ position: "relative" }}>
              <Autocomplete
                multiple
                sx={autocompleteStyles}
                options={["Select All", "Unselect All", ...topicsDATA]}
                getOptionLabel={(option) =>
                  option === "Select All" || option === "Unselect All"
                    ? ""
                    : option.title
                }
                value={topics}
                onChange={(event, values) => {
                  if (values.includes("Select All")) {
                    setTopics(topicsDATA);
                  } else if (values.includes("Unselect All")) {
                    setTopics([]);
                  } else {
                    setTopics(
                      values.filter(
                        (val) => val !== "Select All" && val !== "Unselect All"
                      )
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Topics *"
                    error={errors.topics}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
                renderOption={(props, option) =>
                  option === "Select All" || option === "Unselect All" ? (
                    <Box
                      {...props}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 1,
                        px: 2,
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#f0f0f0" },
                      }}
                    >
                      <MUIButton
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        {option}
                      </MUIButton>
                    </Box>
                  ) : (
                    <li {...props}>{option.title}</li>
                  )
                }
              />
              {roles.Admin === role && (
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    right: "60px",
                    top: "10px",
                    zIndex: 1,
                    backgroundColor: primaryColor,
                    color: "white",
                    "&:hover": {
                      backgroundColor: primaryColor,
                    },
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={() => handleAddNewItem("topics")}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Grid>

          {/* Types */}
          <Grid item xs={12} ref={typesRef}>
            <Box sx={{ position: "relative" }}>
              <Autocomplete
                multiple
                sx={autocompleteStyles}
                options={["Select All", "Unselect All", ...typesDATA]}
                getOptionLabel={(option) =>
                  option === "Select All" || option === "Unselect All"
                    ? ""
                    : option.title
                }
                value={types}
                onChange={(event, values) => {
                  if (values.includes("Select All")) {
                    setTypes(typesDATA);
                  } else if (values.includes("Unselect All")) {
                    setTypes([]);
                  } else {
                    setTypes(
                      values.filter(
                        (val) => val !== "Select All" && val !== "Unselect All"
                      )
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Types *"
                    error={errors.types}
                  />
                )}
                renderOption={(props, option) =>
                  option === "Select All" || option === "Unselect All" ? (
                    <Box
                      {...props}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 1,
                        px: 2,
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#f0f0f0" },
                      }}
                    >
                      <MUIButton
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        {option}
                      </MUIButton>
                    </Box>
                  ) : (
                    <li {...props}>{option.title}</li>
                  )
                }
              />
              {roles.Admin === role && (
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    right: "60px",
                    top: "10px",
                    zIndex: 1,
                    backgroundColor: primaryColor,
                    color: "white",
                    "&:hover": {
                      backgroundColor: primaryColor,
                    },
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={() => handleAddNewItem("types")}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Grid>

          {/* Regions */}
          <Grid item xs={12} ref={regionsRef}>
            <Box sx={{ position: "relative" }}>
              <Autocomplete
                multiple
                sx={autocompleteStyles}
                options={["Select All", "Unselect All", ...regionsDATA]}
                getOptionLabel={(option) =>
                  option === "Select All" || option === "Unselect All"
                    ? ""
                    : option.title
                }
                value={regions}
                onChange={(event, values) => {
                  if (values.includes("Select All")) {
                    setRegions(regionsDATA);
                  } else if (values.includes("Unselect All")) {
                    setRegions([]);
                  } else {
                    setRegions(
                      values.filter(
                        (val) => val !== "Select All" && val !== "Unselect All"
                      )
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Regions *"
                    error={errors.regions}
                  />
                )}
                renderOption={(props, option) =>
                  option === "Select All" || option === "Unselect All" ? (
                    <Box
                      {...props}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 1,
                        px: 2,
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#f0f0f0" },
                      }}
                    >
                      <MUIButton
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        {option}
                      </MUIButton>
                    </Box>
                  ) : (
                    <li {...props}>{option.title}</li>
                  )
                }
              />
              {roles.Admin === role && (
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    right: "60px",
                    top: "10px",
                    zIndex: 1,
                    backgroundColor: primaryColor,
                    color: "white",
                    "&:hover": {
                      backgroundColor: primaryColor,
                    },
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={() => handleAddNewItem("regions")}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Grid>

          {/* Image Upload */}
          <Grid item xs={12} ref={imageRef}>
            <CreateImage
              image={image}
              setImage={setImage}
              imageList={imageList}
              isContent={true}
            />
            {imageError && (
              <Typography variant="caption" color="error">
                The image could not be processed. Please click on the image
                below to upload it manually or upload a new one.
              </Typography>
            )}
            {errors.image && (
              <Typography variant="caption" color="error">
                Image is required.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} ref={contentRef}>
            <Typography>Content *</Typography>
            <Box
              sx={{
                width: "100%",
                maxWidth: { xs: "300px", sm: "300px", md: "800px" },
                minWidth: { xs: "300px", sm: "300px", md: "800px" },
                mb: 4,
                "& .quill": {
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                },
                "& .ql-container": {
                  minHeight: "200px",
                },
                "& .ql-editor": {
                  minHeight: "200px",
                },
              }}
            >
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                style={{ width: "100%", height: "auto" }}
              />
              {summaryError && (
                <Typography variant="caption" color="error">
                  We couldn't extract the summary. Please add content summary.
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Buttons */}
          <Grid item container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                text="Go back"
                onClick={() =>
                  !isEdit
                    ? isContentFromUrl
                      ? setStep(step - 1)
                      : setStep(0)
                    : navigate(`/${companyName}/all-content`)
                }
                backgroundColor="white"
              />
            </Grid>
            <Grid item>
              <Button text="Next" onClick={handleSetForm} />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Dialog for adding new items */}
      <Dialog open={newItemDialog.open} onClose={handleCloseDialog}>
        <DialogTitle>
          {newItemDialog.type === "topics"
            ? "Add New Topic"
            : newItemDialog.type === "types"
            ? "Add New Type"
            : "Add New Region"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={`Enter ${
              newItemDialog.type === "topics"
                ? "Topic"
                : newItemDialog.type === "types"
                ? "Type"
                : "Region"
            } Name`}
            type="text"
            fullWidth
            variant="outlined"
            value={newItemDialog.value}
            onChange={(e) =>
              setNewItemDialog({ ...newItemDialog, value: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            text="Cancel"
            backgroundColor="white"
          />
          <Button
            onClick={handleSaveNewItem}
            disabled={!newItemDialog.value.trim()}
            text="Add"
          />
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default CreateContent;
