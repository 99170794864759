import React, { useState, useCallback, useRef } from "react";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  FormControl,
  Grid,
  Container,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useOrganisation } from "../../../hooks/useOrganisation";

const FilterBar = ({
  region,
  setRegion,
  topic,
  setTopic,
  search,
  setSearch,
  setIsSearch,
  topicList,
  regionList,
  typeList,
  type,
  setType,
}) => {
  const { primaryColor } = useOrganisation();
  const [openSelect, setOpenSelect] = useState(null);
  const menuRefs = useRef({});

  const handleSelect = useCallback(
    (e, value, setValue, items, selectedValue) => {
      e.preventDefault();
      e.stopPropagation();

      // Handle selection without closing the menu
      if (selectedValue === "select-all") {
        setValue(items.map((item) => item.id));
      } else if (selectedValue === "unselect-all") {
        setValue([]);
      } else {
        const newValue = value.includes(selectedValue)
          ? value.filter((id) => id !== selectedValue)
          : [...value, selectedValue];
        setValue(newValue);
      }
      setIsSearch(true);
    },
    [setIsSearch]
  );

  const customSelectStyles = {
    position: "relative",
    "& .MuiSelect-select": {
      padding: "8px 32px 8px 15px",
      border: "none",
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      color: primaryColor,
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-icon": {
      right: 0,
      color: primaryColor,
    },
    minWidth: "120px",
    marginRight: "50px",
  };

  const CustomSelect = ({ label, value, onChange, options, name }) => {
    return (
      <FormControl sx={{ position: "relative" }}>
        <Select
          multiple
          value={value}
          open={openSelect === name}
          onOpen={() => setOpenSelect(name)}
          onClose={() => setOpenSelect(null)}
          onChange={() => {}}
          IconComponent={KeyboardArrowDownIcon}
          sx={customSelectStyles}
          displayEmpty
          renderValue={() => (
            <Box
              component="span"
              sx={{ fontWeight: "bolder", color: primaryColor }}
            >
              {label}
            </Box>
          )}
          MenuProps={{
            disablePortal: false,
            variant: "menu",
            transitionDuration: 0,
            disableAutoFocusItem: true,
            disableScrollLock: true,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              ref: (ref) => {
                menuRefs.current[name] = ref;
              },
              sx: {
                maxHeight: 300,
                position: "absolute",
                marginTop: "4px",
                zIndex: 1000,
                overflowY: "auto",
              },
              onClick: (e) => {
                // Prevent menu from closing on click
                e.stopPropagation();
              },
            },
            MenuListProps: {
              onClick: (e) => {
                // Prevent menu from closing on click
                e.stopPropagation();
              },
            },
          }}
        >
          <MenuItem
            onClick={(e) =>
              handleSelect(e, value, onChange, options, "select-all")
            }
            sx={{
              "&:hover": {
                backgroundColor: `${primaryColor}20`,
              },
            }}
          >
            Select All
          </MenuItem>
          <MenuItem
            onClick={(e) =>
              handleSelect(e, value, onChange, options, "unselect-all")
            }
            sx={{
              "&:hover": {
                backgroundColor: `${primaryColor}20`,
              },
            }}
          >
            Unselect All
          </MenuItem>
          {options?.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              onClick={(e) =>
                handleSelect(e, value, onChange, options, item.id)
              }
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                "&.Mui-selected": {
                  backgroundColor: `${primaryColor}10`,
                },
                "&:hover": {
                  backgroundColor: `${primaryColor}20`,
                },
              }}
            >
              <Typography variant="span">{item.title}</Typography>
              {value.includes(item.id) && (
                <CheckCircleIcon sx={{ color: primaryColor }} />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Container
      className="sectionCenter"
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "white",
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      <Grid
        container
        className="sectionCenter"
        spacing={2}
        alignItems="center"
        sx={{ position: "relative" }}
      >
        <Grid item xs={12} md={9} sx={{ paddingLeft: "0 !important" }}>
          <Box display="flex" alignItems="center" sx={{ position: "relative" }}>
            <CustomSelect
              label="Region"
              value={region}
              onChange={setRegion}
              options={regionList}
              name="region"
            />
            <CustomSelect
              label="Topic"
              value={topic}
              onChange={setTopic}
              options={topicList}
              name="topic"
            />
            <CustomSelect
              label="Type"
              value={type}
              onChange={setType}
              options={typeList}
              name="type"
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setIsSearch(true);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: primaryColor }} />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "8px",
                input: {
                  fontWeight: "bold",
                  color: primaryColor,
                },
                "& input::placeholder": {
                  fontWeight: "bold",
                  color: primaryColor,
                  opacity: 1,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: primaryColor,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: primaryColor,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: primaryColor,
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default FilterBar;
