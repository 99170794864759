import { Box, Typography } from "@mui/material";
import {
  ArrowDownward as ArrowDownIcon,
  ArrowUpward as ArrowUpIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";

export const notificationColumns = ({
  onRowClick,
  onSort,
  currentSortBy,
  currentSortOrder,
}) => [
  {
    accessorKey: "name",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => onSort && onSort("name")}
      >
        <Typography variant="body2">Name</Typography>
        {currentSortBy === "name" ? (
          currentSortOrder === "asc" ? (
            <ArrowUpIcon fontSize="small" />
          ) : (
            <ArrowDownIcon fontSize="small" />
          )
        ) : (
          <SwapVertIcon fontSize="small" sx={{ opacity: 0.5 }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.name}
      </Box>
    ),
  },
  {
    accessorKey: "company",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => onSort && onSort("company")}
      >
        <Typography variant="body2">Company</Typography>
        {currentSortBy === "company" ? (
          currentSortOrder === "asc" ? (
            <ArrowUpIcon fontSize="small" />
          ) : (
            <ArrowDownIcon fontSize="small" />
          )
        ) : (
          <SwapVertIcon fontSize="small" sx={{ opacity: 0.5 }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.company}
      </Box>
    ),
  },
  {
    accessorKey: "email",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => onSort && onSort("email")}
      >
        <Typography variant="body2">Email</Typography>
        {currentSortBy === "email" ? (
          currentSortOrder === "asc" ? (
            <ArrowUpIcon fontSize="small" />
          ) : (
            <ArrowDownIcon fontSize="small" />
          )
        ) : (
          <SwapVertIcon fontSize="small" sx={{ opacity: 0.5 }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.email}
      </Box>
    ),
  },
  {
    accessorKey: "articleTitle",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => onSort && onSort("articleTitle")}
      >
        <Typography variant="body2">Article Title</Typography>
        {currentSortBy === "articletitle" ? (
          currentSortOrder === "asc" ? (
            <ArrowUpIcon fontSize="small" />
          ) : (
            <ArrowDownIcon fontSize="small" />
          )
        ) : (
          <SwapVertIcon fontSize="small" sx={{ opacity: 0.5 }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box
        component="span"
        sx={{
          marginLeft: 1,
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() => onRowClick && onRowClick(row.original)}
      >
        {row.original.articleTitle}
      </Box>
    ),
  },
  {
    accessorKey: "event",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => onSort && onSort("event")}
      >
        <Typography variant="body2">Events</Typography>
        {currentSortBy === "event" ? (
          currentSortOrder === "asc" ? (
            <ArrowUpIcon fontSize="small" />
          ) : (
            <ArrowDownIcon fontSize="small" />
          )
        ) : (
          <SwapVertIcon fontSize="small" sx={{ opacity: 0.5 }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.event}
      </Box>
    ),
  },
  {
    accessorKey: "actionedDate",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => onSort && onSort("actionedDate")}
      >
        <Typography variant="body2">Last Actioned</Typography>
        {currentSortBy === "actioneddate" ? (
          currentSortOrder === "asc" ? (
            <ArrowUpIcon fontSize="small" />
          ) : (
            <ArrowDownIcon fontSize="small" />
          )
        ) : (
          <SwapVertIcon fontSize="small" sx={{ opacity: 0.5 }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {new Date(row.original.actionedDate).toLocaleDateString()}
      </Box>
    ),
  },
];
