import React from "react";
import { Box } from "@mui/material";
import Footer from "../../compnents/common/Footer";

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Box sx={{ flex: "1 0 auto" }}>{children}</Box>
      <Box sx={{ flexShrink: 0 }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
