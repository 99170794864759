import { Box, Typography } from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";

export const columns = ({ sortBy, sortOrder, handleSort }) => [
  {
    accessorKey: "name",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSort("name")}
      >
        <Typography variant="body2">Name</Typography>
        {sortBy === "name" ? (
          sortOrder === "asc" ? (
            <ArrowUpward style={{ fontSize: "1rem" }} />
          ) : (
            <ArrowDownward style={{ fontSize: "1rem" }} />
          )
        ) : (
          <SwapVertIcon style={{ fontSize: "1rem" }} />
        )}
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.name}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "date",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSort("date")}
      >
        <Typography variant="body2">Date</Typography>
        {sortBy === "date" ? (
          sortOrder === "asc" ? (
            <ArrowUpward style={{ fontSize: "1rem" }} />
          ) : (
            <ArrowDownward style={{ fontSize: "1rem" }} />
          )
        ) : (
          <SwapVertIcon style={{ fontSize: "1rem" }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.date
            ? new Date(row.original.date).toLocaleDateString()
            : "-"}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "email",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSort("email")}
      >
        <Typography variant="body2">Email</Typography>
        {sortBy === "email" ? (
          sortOrder === "asc" ? (
            <ArrowUpward style={{ fontSize: "1rem" }} />
          ) : (
            <ArrowDownward style={{ fontSize: "1rem" }} />
          )
        ) : (
          <SwapVertIcon style={{ fontSize: "1rem" }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.email}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "tokenUsage",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSort("tokenusage")}
      >
        {console.log(table)}
        <Typography variant="body2">Logins</Typography>
        {sortBy === "tokenusage" ? (
          sortOrder === "asc" ? (
            <ArrowUpward style={{ fontSize: "1rem" }} />
          ) : (
            <ArrowDownward style={{ fontSize: "1rem" }} />
          )
        ) : (
          <SwapVertIcon style={{ fontSize: "1rem" }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.tokenUsage ? row.original.tokenUsage : "-"}
        </Box>
      </>
    ),
  },
];

// Redesigned articleColumns to use the same pattern as columns
export const articleColumns = ({ sortBy, sortOrder, handleSort }) => [
  {
    accessorKey: "articleTitle",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSort("articleTitle")}
      >
        <Typography variant="body2">Article Title</Typography>
        {sortBy === "articleTitle" ? (
          sortOrder === "asc" ? (
            <ArrowUpward style={{ fontSize: "1rem" }} />
          ) : (
            <ArrowDownward style={{ fontSize: "1rem" }} />
          )
        ) : (
          <SwapVertIcon style={{ fontSize: "1rem" }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.articleTitle}
      </Box>
    ),
  },
  {
    accessorKey: "views",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSort("views")}
      >
        <Typography variant="body2">Views</Typography>
        {sortBy === "views" ? (
          sortOrder === "asc" ? (
            <ArrowUpward style={{ fontSize: "1rem" }} />
          ) : (
            <ArrowDownward style={{ fontSize: "1rem" }} />
          )
        ) : (
          <SwapVertIcon style={{ fontSize: "1rem" }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.views}
      </Box>
    ),
  },
  {
    accessorKey: "readDate",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSort("shares")}
      >
        <Typography variant="body2">read Date</Typography>
        {sortBy === "readDate" ? (
          sortOrder === "asc" ? (
            <ArrowUpward style={{ fontSize: "1rem" }} />
          ) : (
            <ArrowDownward style={{ fontSize: "1rem" }} />
          )
        ) : (
          <SwapVertIcon style={{ fontSize: "1rem" }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.readDate
          ? new Date(row.original.readDate).toLocaleDateString()
          : "-"}
      </Box>
    ),
  },
  {
    accessorKey: "readTime",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSort("readtime")}
      >
        <Typography variant="body2">Read Time</Typography>
        {sortBy === "readtime" ? (
          sortOrder === "asc" ? (
            <ArrowUpward style={{ fontSize: "1rem" }} />
          ) : (
            <ArrowDownward style={{ fontSize: "1rem" }} />
          )
        ) : (
          <SwapVertIcon style={{ fontSize: "1rem" }} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {Number(row.original.readTime).toFixed(2)} min
      </Box>
    ),
  },
];
