import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Box, Typography, TextField } from "@mui/material";
import Button from "../../../compnents/common/Button";
import { verifyOrganisation } from "../../../store/api/organisation";

const LaunchPage = () => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState(null);

  const { mutate, isPending } = useMutation({
    mutationFn: verifyOrganisation,
    onSuccess: (data) => {
      localStorage.setItem("organisationId", data.id);
      localStorage.setItem("companyName", companyName.trim());
      localStorage.setItem("logoCompany", data.logoLink);
      setTimeout(() => {
        navigate(`/${companyName.trim()}/login`);
      }, 0);

      setError(null);
    },
    onError: (error) => {
      if (error.response) {
        setError("Check the company name again");
      }
    },
  });

  useEffect(() => {
    document.title = `Company Login `;
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = `Company Login `;
    window.scrollTo(0, 0);
  }, []);
  const handleLogin = (e) => {
    e.preventDefault(); // Prevents default form submission
    if (companyName) {
      mutate({ organisationName: companyName.trim() });
    } else {
      setError("Company name is required");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        px: 2, // Padding for small screens
        backgroundColor: "#fff",
      }}
    >
      {/* Title */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          mb: 1,
          textAlign: "center",
        }}
      >
        Let’s get started...
      </Typography>

      {/* Subtitle */}
      <Typography
        variant="body1"
        sx={{
          mb: 4,
          color: "gray",
          textAlign: "center",
        }}
      >
        Enter the vendor name and click launch
      </Typography>

      {/* Form */}
      <form onSubmit={handleLogin}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            gap: 1, // Adds spacing between input and suffix
            mb: 3,
          }}
        >
          {" "}
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "gray",
            }}
          >
            komzhub.com
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Company Name"
            sx={{
              width: { xs: "100%", sm: "300px" }, // Full width on small screens
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Box>
        {error && (
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {error}
            </Typography>
          </Box>
        )}
        <Box display={"flex"} justifyContent={"center"}>
          <Button text={"Launch"} isLoading={isPending} type="submit" />
        </Box>
      </form>
    </Box>
  );
};

export default LaunchPage;
