import React from "react";
import {
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import Button from "../../../common/Button";

const UserList = ({
  csvData,
  handleSaveRow,
  editingRow,
  setEditingRow,
  handleEditRow,
  handleBackStep,
  handleSubmit,
  selectedTypeTitle,
}) => {
  return (
    <div>
      <Typography variant="h5" fontWeight={"bold"} mb={2}>
        Review New Users
      </Typography>
      <Typography variant="body2">
        Add to{" "}
        <Typography variant="span" style={{ textDecorationLine: "underline" }}>
          {selectedTypeTitle}
        </Typography>
        list
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Full Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {csvData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                {editingRow === index ? (
                  <TextField
                    value={row.fullName}
                    onChange={(e) =>
                      handleSaveRow(index, "fullName", e.target.value)
                    }
                  />
                ) : (
                  row.fullName
                )}
              </TableCell>
              <TableCell>
                {editingRow === index ? (
                  <TextField
                    value={row.email}
                    onChange={(e) =>
                      handleSaveRow(index, "email", e.target.value)
                    }
                  />
                ) : (
                  row.email
                )}
              </TableCell>
              <TableCell>
                {editingRow === index ? (
                  <TextField
                    value={row.location}
                    onChange={(e) =>
                      handleSaveRow(index, "location", e.target.value)
                    }
                  />
                ) : (
                  row.location
                )}
              </TableCell>
              <TableCell>
                {editingRow === index ? (
                  <IconButton onClick={() => setEditingRow(null)}>
                    <CheckIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleEditRow(index)}>
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <Button onClick={handleSubmit} text="Submit" />

        <Button onClick={handleBackStep} text="Back" backgroundColor="white" />
      </div>
    </div>
  );
};

export default UserList;
