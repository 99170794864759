import { useQuery } from "@tanstack/react-query";
import { getOnboardedOrganisation } from "../store/api/organisation";
import { getRole } from "../helpers/auth";
import { roles } from "../constants/routes";

const COLORS = {
  primary: {
    light: "#e3fdf6",
    dark: "#70dbc4",
    normal: "#96f7dc",
  },
};

// Updated color adjustment function optimized for blue shades
const adjustColorShade = (color, percent) => {
  let colorHex = color?.replace("#", "");
  if (colorHex.length === 3) {
    colorHex = colorHex
      .split("")
      .map((hex) => hex + hex)
      .join("");
  }

  const r = parseInt(colorHex.substring(0, 2), 16);
  const g = parseInt(colorHex.substring(2, 4), 16);
  const b = parseInt(colorHex.substring(4, 6), 16);

  const adjust = (value, percent) => {
    if (percent >= 0) {
      // Reduced intensity for lightening
      const factor = percent / 200; // Divided by 200 instead of 100 to make it more subtle
      return Math.round(Math.min(255, value + (255 - value) * factor));
    } else {
      const factor = 1 + Math.abs(percent / 100);
      return Math.round(Math.max(0, value / factor));
    }
  };

  const newR = adjust(r, percent).toString(16).padStart(2, "0");
  const newG = adjust(g, percent).toString(16).padStart(2, "0");
  const newB = adjust(b, percent).toString(16).padStart(2, "0");

  return `#${newR}${newG}${newB}`;
};

export const useOrganisation = () => {
  const token = localStorage.getItem("token");
  const companyName = localStorage.getItem("companyName");
  const organisationId = localStorage.getItem("organisationId");
  const role = getRole();

  const { data, isFetched } = useQuery({
    queryKey: ["onboardedOrganisation", "updateOnboardedOrganisation", token],
    queryFn: () => getOnboardedOrganisation({}),
    enabled: !!token && role !== roles.SuperAdmin,
    staleTime: Infinity,
  });

  if (data) {
    const { isOnBoarded, primaryColor, primaryFont, logoLink } = data;
    const primaryColorLight = adjustColorShade(primaryColor || "#96f7dc", 60);
    const primaryColorDark = adjustColorShade(primaryColor || "#96f7dc", -20);
    return {
      isOnBoarded,
      isFetched,
      primaryColor,
      primaryFont,
      logoLink,
      primaryColorLight,
      primaryColorDark,
      organisationId,
      companyName,
      secondaryColor: companyName.toLowerCase().includes("sumo")
        ? "#0366ff"
        : null,
    };
  }
  return {
    primaryColor: COLORS.primary.normal,
    primaryColorLight: COLORS.primary.light,
    primaryColorDark: COLORS.primary.dark,
    primaryFont: "DM Sans",
    secondaryColor: null,
  };
};
