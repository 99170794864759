import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Modal,
  TextField,
  Typography,
  Alert,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import {
  addFeed,
  editFeed,
  getFeedTemplateToCreate,
  validateFeed,
} from "../../../store/api/feed";
import Button from "../../common/Button";

const AddFeedModal = ({ open, handleClose, feedToEdit, isEdit, idFeed }) => {
  const queryClient = useQueryClient();

  const [feedName, setFeedName] = useState("");
  const [feedUrl, setFeedUrl] = useState("");
  const [error, setError] = useState("");
  const [isTesting, setIsTesting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showTopicSelect, setShowTopicSelect] = useState(false);
  const [showFinalStep, setShowFinalStep] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [autoPublish, setAutoPublish] = useState(false);

  const hasAllSelections =
    selectedTypes.length > 0 &&
    selectedTopics.length > 0 &&
    selectedRegions.length > 0;

  const { data: feedTemplate } = useQuery({
    queryKey: ["RssFeedTemplate"],
    queryFn: () => getFeedTemplateToCreate(),
  });

  const { mutate: validateFeedMutation } = useMutation({
    mutationFn: () => validateFeed({ url: feedUrl }),
    onSuccess: (data) => {
      setIsTesting(false);
      setIsValid(data);
      !data &&
        setError("It appears no content is being returned for this feed.");
    },
    onError: (error) => {
      setError("Error testing the URL. Please try again.");
      setIsTesting(false);
    },
  });

  const { mutate: addFeedMutation } = useMutation({
    mutationFn: () =>
      addFeed({
        title: feedName,
        link: feedUrl,
        autoPost: autoPublish,
        contentTypeIds: selectedTypes.map((type) => type.id),
        contentTopicIds: selectedTopics.map((topic) => topic.id),
        contentRegionIds: selectedRegions.map((region) => region.id),
      }),
    onSuccess: (data) => {
      queryClient.refetchQueries({
        queryKey: ["RssFeedList"],
      });
      handleClose();
    },
    onError: (error) => {},
  });

  const { mutate: editFeedMutation } = useMutation({
    mutationFn: () =>
      editFeed({
        id: idFeed,
        title: feedName,
        link: feedUrl,
        autoPost: autoPublish,
        contentTypeIds: selectedTypes.map((type) => type.id),
        contentTopicIds: selectedTopics.map((topic) => topic.id),
        contentRegionIds: selectedRegions.map((region) => region.id),
      }),
    onSuccess: (data) => {
      queryClient.refetchQueries({
        queryKey: ["RssFeedList"],
      });
      handleClose();
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (isEdit) {
      setFeedName(feedToEdit?.title);
      setFeedUrl(feedToEdit?.link);
      setAutoPublish(feedToEdit?.autoPost);
      setSelectedTypes(feedToEdit?.types.filter((el) => el.isSelected) || []);
      setSelectedTopics(feedToEdit?.topics.filter((el) => el.isSelected) || []);
      setSelectedRegions(
        feedToEdit?.regions.filter((el) => el.isSelected) || []
      );

      // You might need to map the edit data to match the new autocomplete format
    } else {
      setFeedName("");
      setFeedUrl("");
      setAutoPublish(false);
      setIsValid(false);
      setIsTesting(false);
      setError("");
      setSelectedTopics([]);
      setSelectedTypes([]);
      setSelectedRegions([]);
    }
  }, [isEdit, feedToEdit, handleClose]);

  const handleValidateUrl = () => {
    setIsTesting(true);
    setError("");
    validateFeedMutation();
  };

  const handleYesClick = () => {
    setShowTopicSelect(true);
    setShowFinalStep(true);
    setAutoPublish(false);
  };

  const handleNoClick = () => {
    setShowTopicSelect(false);
    setShowFinalStep(true);
    setAutoPublish(false);
    setSelectedTopics([]);
    setSelectedTypes([]);
    setSelectedRegions([]);
  };

  const handleSave = () => {
    if (isEdit) {
      editFeedMutation();
    } else {
      addFeedMutation();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-feed-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: 500,
          maxHeight: "90vh",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
          <Typography variant="h6">Add Feed</Typography>
        </Box>

        <Box
          sx={{
            p: 2,
            overflowY: "auto",
            flex: 1,
          }}
        >
          <TextField
            label="Feed Name"
            fullWidth
            value={feedName}
            onChange={(e) => setFeedName(e.target.value)}
            margin="normal"
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              mt: 2,
              mb: 2,
              gap: 1,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <TextField
              label="Feed URL"
              fullWidth
              value={feedUrl}
              onChange={(e) => setFeedUrl(e.target.value)}
              margin="normal"
              required
            />

            <Button
              onClick={handleValidateUrl}
              variant="contained"
              disabled={!feedUrl || isTesting}
              text={isTesting ? "Testing..." : "Next"}
              sx={{
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 1, sm: 0 },
              }}
            />
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          {isValid && (
            <Box sx={{ mt: 2 }}>
              <Alert severity="success" sx={{ mb: 2 }}>
                Super - that worked.
              </Alert>

              <Typography sx={{ mb: 2 }}>
                Is this RSS Feed based on a specific topic and matchable to one
                of your site's content types (i.e. Blog / News etc)?
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mb: 3,
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Button
                  onClick={handleYesClick}
                  variant="contained"
                  backgroundColor="green"
                  text="Yes"
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                />

                <Button
                  onClick={handleNoClick}
                  variant="contained"
                  backgroundColor="red"
                  text="No"
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                />
              </Box>

              {showTopicSelect && feedTemplate && (
                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    Please choose appropriate categories for this feed below.
                    You can also choose whether you want to auto post content
                    out to the site. This appears in a separate section (if
                    enabled) and does not generate notifications to users.
                  </Typography>

                  <Autocomplete
                    multiple
                    options={feedTemplate.topics}
                    getOptionLabel={(option) => option.title}
                    value={selectedTopics}
                    onChange={(event, newValue) => {
                      setSelectedTopics(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Topics" margin="normal" />
                    )}
                    sx={{ mb: 2 }}
                  />

                  <Autocomplete
                    multiple
                    options={feedTemplate.types}
                    getOptionLabel={(option) => option.title}
                    value={selectedTypes}
                    onChange={(event, newValue) => {
                      setSelectedTypes(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Types" margin="normal" />
                    )}
                    sx={{ mb: 2 }}
                  />

                  <Autocomplete
                    multiple
                    options={feedTemplate.regions}
                    getOptionLabel={(option) => option.title}
                    value={selectedRegions}
                    onChange={(event, newValue) => {
                      setSelectedRegions(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Regions" margin="normal" />
                    )}
                    sx={{ mb: 2 }}
                  />
                </Box>
              )}

              {showFinalStep && (
                <Box>
                  {showTopicSelect && hasAllSelections && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={autoPublish}
                          onChange={(e) => setAutoPublish(e.target.checked)}
                        />
                      }
                      label="Auto Post"
                    />
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 2,
                      pt: 2,
                      borderTop: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Button
                      onClick={handleSave}
                      variant="contained"
                      text="Save"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AddFeedModal;
