import React, { useState, useCallback, useEffect } from "react";
import { Box, Grid, Card, IconButton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";
import { ArrowBack } from "@mui/icons-material";

import Table from "../../../common/Table";
import Loading from "../../../common/Loading";
import Button from "../../../common/Button";
import DataCard from "../DataCard";

import { articleColumns, columns } from "./columns";
import { exportToCSV } from "../../../../helpers";
import {
  getAllActiveUserStatistics,
  getAllBouncesUserStatistics,
  getAllLoginUserStatistics,
  getAllSubscriptionUserStatistics,
  getAllUnActiveUserStatistics,
  getAllUnSubscriptionUserStatistics,
  getUserArticlesStatistics,
  getUserStatistics,
} from "../../../../store/api/statistics";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const TabPanel = ({ value, index, children }) => {
  return value === index && <Box>{children}</Box>;
};

const UserTab = ({ tabIndex, index, startDate, endDate, organisation }) => {
  const { primaryColor } = useOrganisation();
  const [selectedCard, setSelectedCard] = useState({
    key: "active",
    getDataFunction: getAllActiveUserStatistics,
  });
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showArticles, setShowArticles] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [articleSortBy, setArticleSortBy] = useState("views");
  const [articleSortOrder, setArticleSortOrder] = useState("desc");

  const cardsData = [
    {
      title: "Subscribers",
      key: "subscribers",
      countKey: "subscribers",
      getDataFunction: getAllSubscriptionUserStatistics,
    },
    {
      title: "Logins",
      key: "logins",
      countKey: "logins",
      getDataFunction: getAllLoginUserStatistics,
    },
    {
      title: "Active Users",
      key: "active",
      countKey: "active",
      getDataFunction: getAllActiveUserStatistics,
    },
    {
      title: "Inactive Users",
      key: "unactive",
      countKey: "inactive",
      getDataFunction: getAllUnActiveUserStatistics,
    },
    {
      title: "Unsubscribes",
      key: "unsubscribers",
      countKey: "unsubscribers",
      getDataFunction: getAllUnSubscriptionUserStatistics,
    },
    {
      title: "Bounces",
      key: "bounces",
      countKey: "bounces",
      getDataFunction: getAllBouncesUserStatistics,
    },
  ];
  const [selectedBarIndex, setSelectedBarIndex] = useState(null);

  // Query for overall user statistics
  const { data: statistics } = useQuery({
    queryKey: ["userStatistics", startDate, endDate, organisation],
    queryFn: () =>
      getUserStatistics({
        startDate,
        endDate,
        pageNumber: 1,
        organisationId: organisation,
      }),
  });

  const { data: userArticles, isLoading: loadingArticles } = useQuery({
    queryKey: [
      "userArticles",
      selectedUser?.userId,
      currentPage,
      articleSortBy,
      articleSortOrder,
      startDate,
      endDate,
      organisation,
    ],
    queryFn: () =>
      getUserArticlesStatistics({
        userId: selectedUser?.userId,
        pageNumber: currentPage,
        sortBy: articleSortBy,
        sortOrder: articleSortOrder,
        startDate,
        endDate,
        organisationId: organisation,
      }),
    enabled: !!selectedUser?.userId,
  });

  // Fetch data based on the selected card
  const { data: usersStatistics, isLoading: loadUsers } = useQuery({
    queryKey: [
      selectedCard?.key,
      startDate,
      endDate,
      currentPage,
      sortBy,
      sortOrder,
      organisation,
    ],
    queryFn: () =>
      selectedCard.getDataFunction({
        startDate,
        endDate,
        pageNumber: currentPage,
        sortBy: sortBy,
        sortOrder: sortOrder,
        organisationId: organisation,
      }),
    enabled: !!selectedCard,
  });

  useEffect(() => {
    if (!loadUsers) {
      setTotalPage(usersStatistics?.totalPages || 1);
      setCurrentPage(1);
    }
  }, [loadUsers, selectedCard, sortBy, sortOrder]);

  useEffect(() => {
    setSelectedBarIndex(null);
  }, [startDate, endDate]);

  const handleUserList = useCallback((cardData) => {
    setSelectedUser(null);
    setShowArticles(false);
    setSelectedBarIndex(null);

    setSelectedCard({
      key: cardData.key,
      getDataFunction: cardData.getDataFunction,
    });
  }, []);

  const handleSort = (key) => {
    if (sortBy === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };

  const handleArticleSort = (key) => {
    if (articleSortBy === key) {
      setArticleSortOrder(articleSortOrder === "asc" ? "desc" : "asc");
    } else {
      setArticleSortBy(key);
      setArticleSortOrder("asc");
    }
  };

  const handleBackClick = () => {
    setSelectedUser(null);
    setShowArticles(false);
    setSelectedBarIndex(null);
  };

  const data = {
    labels: statistics?.engagements?.map((el) => el?.name) || [],
    datasets: [
      {
        label: "Engagement",
        data: statistics?.engagements?.map((el) => el.count) || [],
        borderWidth: 1,
        backgroundColor: statistics?.engagements?.map((_, index) =>
          index === selectedBarIndex ? primaryColor : "grey"
        ),
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: { beginAtZero: true },
      x: {
        categoryPercentage: 0.6,
        barPercentage: 0.5,
      },
    },
    responsive: true,
    onClick: (_, elements) => {
      if (elements.length > 0) {
        const dataIndex = elements[0].index;
        const userData = statistics?.engagements[dataIndex];
        setSelectedBarIndex(dataIndex);

        setSelectedUser(null);
        setShowArticles(false);
        setTimeout(() => {
          setSelectedUser({
            userId: userData?.id,
            name: userData?.name,
            email: userData?.email,
          });
          setShowArticles(true);
          setCurrentPage(1);
        }, 0);
      }
    },
    onHover: (event, chartElements) => {
      event.native.target.style.cursor = chartElements.length
        ? "pointer"
        : "default";
    },
  };

  // Create custom columns with sorting functionality using the same pattern for both tables
  const userColumns = columns({
    sortBy,
    sortOrder,
    handleSort,
  });

  const customArticleColumns = articleColumns({
    data: userArticles?.paginatedContent,
    sortBy: articleSortBy,
    sortOrder: articleSortOrder,
    handleSort: handleArticleSort,
  });

  return (
    <TabPanel value={tabIndex} index={index}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <Bar data={data} options={chartOptions} />
          </Card>
        </Grid>

        {/* Data Cards */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {cardsData.map((cardData) => (
              <Grid item xs={6} md={4} key={cardData.key}>
                <DataCard
                  title={cardData.title}
                  count={statistics?.[cardData.countKey] || 0}
                  onClick={() => handleUserList(cardData)}
                  isSelected={selectedCard.key === cardData.key}
                  isActivated={!showArticles}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {showArticles ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
              <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">
                Articles for User: {selectedUser?.name}
              </Typography>
            </Box>
            <Button
              text="Export CSV"
              onClick={() => exportToCSV(userArticles?.paginatedContent)}
            />
          </Box>
          {loadingArticles ? (
            <Loading />
          ) : (
            <Table
              data={userArticles?.paginatedContent}
              columns={customArticleColumns}
              totalPage={userArticles?.totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </>
      ) : (
        <>
          <Grid
            style={{ marginTop: "1em", display: "flex", justifyContent: "end" }}
          >
            <Button
              text="Export CSV"
              onClick={() => exportToCSV(usersStatistics?.paginatedContent)}
            />
          </Grid>
          {loadUsers && selectedCard ? (
            <Loading />
          ) : (
            <Table
              data={usersStatistics?.paginatedContent}
              columns={userColumns}
              totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </>
      )}
    </TabPanel>
  );
};

export default UserTab;
