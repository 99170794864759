import { IMAGE_URL_AZURE } from "../constants/env";

// Function to export table data as CSV
export const exportToCSV = (data) => {
  if (!data || data.length === 0) {
    alert("No data available for export.");
    return;
  }

  const csvHeaders = Object.keys(data[0]);
  const csvRows = data.map((row) =>
    csvHeaders
      .map((header) => JSON.stringify(row[header], (key, value) => value ?? ""))
      .join(",")
  );

  const csvContent = [csvHeaders.join(","), ...csvRows].join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "user_statistics.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Determines if a color is dark based on its RGB luminance.
 * @param {string} color Hex color string (e.g., '#RRGGBB').
 * @returns {boolean} True if the color is dark, false otherwise.
 */
const isDarkColor = (color) => {
  const r = parseInt(color?.substring(1, 3), 16);
  const g = parseInt(color?.substring(3, 5), 16);
  const b = parseInt(color?.substring(5, 7), 16);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luminance < 128;
};

/**
 * Sets the appropriate text color based on the background color.
 * @param {string} backgroundColor Hex color string (e.g., '#RRGGBB').
 * @returns {string} 'white' or 'black' depending on the luminance.
 */
export const getTextColor = (backgroundColor) => {
  return isDarkColor(backgroundColor || "#96f7dc") ? "#F7F7F7" : "#424242";
};

export const darkenColor = (color, percent) => {
  // Simple implementation - for a more robust solution, consider a color library
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = Math.max(0, (num >> 16) - amt);
  const G = Math.max(0, ((num >> 8) & 0x00ff) - amt);
  const B = Math.max(0, (num & 0x0000ff) - amt);
  return `#${(0x1000000 + (R << 16) + (G << 8) + B).toString(16).slice(1)}`;
};
export const setDynamicFavicon = (vendorLogoUrl) => {
  // First, remove any existing favicon links to ensure clean update
  const existingLinks = document.querySelectorAll("link[rel*='icon']");
  existingLinks.forEach((link) => link.parentNode?.removeChild(link));
  console.log("update");
  // Create a new link element
  const link = document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = vendorLogoUrl + "?" + IMAGE_URL_AZURE;

  // Append it to the head
  document.getElementsByTagName("head")[0].appendChild(link);
};

export const ENV = {
  development: {
    baseURL: process.env.REACT_APP_API_URL || "https://komz.azurewebsites.net",
    notifURL: "https://komz.azurewebsites.net",
    prefix: "/api",
  },
  production: {
    baseURL: process.env.REACT_APP_API_URL || "https://komz.azurewebsites.net",
    notifURL: process.env.REACT_APP_API_URL || "https://komz.azurewebsites.net",
    prefix: "/api",
  },
};
export const APP_ENV_URL = {
  development: {
    baseURL: process.env.REACT_APP_APP_URL || "https://komz.tech",
  },
  production: {
    baseURL: process.env.REACT_APP_APP_URL || "https://komzhub.com",
  },
};

export const getEnvironment = () => {
  const envFromVar = process.env.REACT_APP_ENV;

  console.log("REACT_APP_ENV:", envFromVar);
  console.log(process.env.REACT_APP_ENV);
  if (envFromVar === "development" || envFromVar === "production") {
    console.log(`Using environment from REACT_APP_ENV: ${envFromVar}`);
    return envFromVar;
  }

  const hostname = window.location.hostname;
  console.log("Hostname:", hostname);

  if (hostname.includes("dev") || hostname.includes("localhost")) {
    console.log("Detected development environment from hostname");
    return "development";
  }

  console.log("Defaulting to production environment");
  return "production";
};
const currentEnv = getEnvironment();
export const BaseURL = ENV[currentEnv].baseURL;
export const AppURL = ENV[currentEnv].baseURL;
export const PREFIX = ENV[currentEnv].prefix;
export const NotifURL = ENV[currentEnv].notifURL;
