import React, { useState } from "react";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import Component from "./Component";
import {
  getAllTypes,
  addType,
  editType,
  deleteType,
  restoreType,
} from "../../../store/api/type";
import {
  getAllTopic,
  addTopic,
  editTopic,
  deleteTopic,
  restoreTopic,
} from "../../../store/api/topic";
import {
  getAllRegions,
  addRegion,
  editRegion,
  deleteRegion,
  restoreRegion,
} from "../../../store/api/region";
import ResourceList from "./Resources";
import UpdateOnboarding from "./UpdateOnboarding";
import { useOrganisation } from "../../../hooks/useOrganisation";
import NotificationSetting from "./Notificationsettings";
// import OrganisationConfig from "./Orgnanisation";

const Config = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check for small screens
  const { primaryColor } = useOrganisation();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderSettingTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <Component
            tabIndex={0}
            index={0}
            type={"type"}
            getData={getAllTypes}
            addFunction={addType}
            editFunction={editType}
            deleteFunction={deleteType}
            restoreFunction={restoreType}
            keytype={"allTypes"}
          />
        );
      case 1:
        return (
          <Component
            tabIndex={1}
            index={1}
            type={"topic"}
            getData={getAllTopic}
            addFunction={addTopic}
            editFunction={editTopic}
            deleteFunction={deleteTopic}
            restoreFunction={restoreTopic}
            keytype={"allTopics"}
          />
        );
      case 2:
        return (
          <Component
            tabIndex={2}
            index={2}
            type={"region"}
            getData={getAllRegions}
            addFunction={addRegion}
            editFunction={editRegion}
            deleteFunction={deleteRegion}
            restoreFunction={restoreRegion}
            keytype={"allRegions"}
          />
        );
      case 3:
        return <Component tabIndex={3} index={3} />;
      case 4:
        return <ResourceList tabIndex={4} index={4} />;
      case 5:
        return <UpdateOnboarding tabIndex={5} index={5} />;
      case 6:
        return <NotificationSetting tabIndex={6} index={6} />;
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant={isSmallScreen ? "scrollable" : "standard"}
        scrollButtons="auto"
        allowScrollButtonsMobile
        style={{ marginBottom: 8 }}
        TabIndicatorProps={{
          style: {
            backgroundColor: primaryColor || theme.palette.primary.main,
          },
        }}
        sx={{
          "& .MuiTab-root": {
            color: "text.secondary",
            "&.Mui-selected": {
              color: primaryColor || theme.palette.primary.main,
            },
            "&:hover": {
              opacity: 0.8,
            },
          },
        }}
      >
        <Tab label="Type" />
        <Tab label="Topic" />
        <Tab label="Region" />
        <Tab label="Ads" />
        <Tab label="Resources" />
        <Tab label="Configuration" />
        <Tab label="Notifications" />
      </Tabs>

      {renderSettingTab()}
    </Box>
  );
};

export default Config;
