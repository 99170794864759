import { jwtDecode } from "jwt-decode";

export const checkTokenExpiration = (token) => {
  try {
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    if (expirationTime > currentTime) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    return null;
  }
};

export const getRole = () => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  if (!token || token.split(".").length !== 3) {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    return null;
  }

  try {
    const decoded = jwtDecode(token);

    return decoded[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    window.location.href = "/";
    return null;
  }
};
