import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../common/Button";
const MAX_TOTAL_SIZE = 100 * 1024 * 1024; // 100MB in bytes

const ResourceSelectionModal = ({
  open,
  onClose,
  onResourceSelect,
  resources,
  currentTotalSize = 0,
}) => {
  const [selectedResources, setSelectedResources] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("dateAdded");
  const [order, setOrder] = useState("desc");
  const [selectedSize, setSelectedSize] = useState(0);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleResourceSelection = (resource) => {
    setSelectedResources((prev) => {
      const isSelected = prev.some((item) => item.id === resource.id);
      const newSelection = isSelected
        ? prev.filter((item) => item.id !== resource.id)
        : [...prev, { ...resource }];

      // Update selected size
      const newSelectedSize = newSelection.reduce(
        (total, item) => total + (item.fileSize || 0) * 1024 * 1024,
        0
      );
      setSelectedSize(newSelectedSize);

      return newSelection;
    });
  };

  const remainingSize = MAX_TOTAL_SIZE - currentTotalSize;
  const wouldExceedLimit = selectedSize > remainingSize;

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedResources(filteredResources);
    } else {
      setSelectedResources([]);
    }
  };

  const filteredResources = (resources || []).filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedResources = [...filteredResources].sort((a, b) => {
    if (orderBy === "dateAdded") {
      return order === "asc"
        ? new Date(a.dateAdded) - new Date(b.dateAdded)
        : new Date(b.dateAdded) - new Date(a.dateAdded);
    }
    if (orderBy === "fileSize") {
      return order === "asc"
        ? a.fileSize - b.fileSize
        : b.fileSize - a.fileSize;
    }
    return order === "asc"
      ? a[orderBy].localeCompare(b[orderBy])
      : b[orderBy].localeCompare(a[orderBy]);
  });

  const handleContinue = () => {
    if (selectedResources.length > 0) {
      onResourceSelect(selectedResources);
      onClose();
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const formatFileSize = (size) => {
    return size.toFixed(3);
  };
  const handleCheckboxClick = (event, resource) => {
    event.stopPropagation(); // Stop event from bubbling up to the row
    handleResourceSelection(resource);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Resources</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box mb={2}>
          <TextField
            fullWidth
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            size="small"
            sx={{ mb: 2 }}
          />
          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Current total size:{" "}
              {(currentTotalSize / (1024 * 1024)).toFixed(2)}MB
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Selected size: {(selectedSize / (1024 * 1024)).toFixed(2)}MB
            </Typography>
            <Typography
              variant="body2"
              color={wouldExceedLimit ? "error.main" : "success.main"}
            >
              Remaining available: {(remainingSize / (1024 * 1024)).toFixed(2)}
              MB
            </Typography>
          </Box>

          {wouldExceedLimit && (
            <Typography variant="body2" color="error.main" sx={{ mt: 1 }}>
              ⚠️ Selected files would exceed the 100MB limit
            </Typography>
          )}
        </Box>

        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selectedResources.length === filteredResources.length &&
                      filteredResources.length > 0
                    }
                    indeterminate={
                      selectedResources.length > 0 &&
                      selectedResources.length < filteredResources.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "title"}
                    direction={orderBy === "title" ? order : "asc"}
                    onClick={() => handleSort("title")}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "fileSize"}
                    direction={orderBy === "fileSize" ? order : "asc"}
                    onClick={() => handleSort("fileSize")}
                  >
                    File Size (MB)
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "dateAdded"}
                    direction={orderBy === "dateAdded" ? order : "asc"}
                    onClick={() => handleSort("dateAdded")}
                  >
                    Date Added
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedResources.map((resource) => (
                <TableRow
                  key={resource.id}
                  hover
                  onClick={() => handleResourceSelection(resource)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Checkbox
                      checked={selectedResources.some(
                        (item) => item.id === resource.id
                      )}
                      onClick={(e) => handleCheckboxClick(e, resource)}
                    />
                  </TableCell>
                  <TableCell>{resource.title}</TableCell>
                  <TableCell>{formatFileSize(resource.fileSize)}</TableCell>
                  <TableCell>{formatDate(resource.dateAdded)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="contained"
          onClick={handleContinue}
          backgroundColor="white"
          text="Close"
        />
        <Button
          variant="contained"
          onClick={handleContinue}
          disabled={selectedResources.length === 0}
          sx={{
            backgroundColor: "rgb(209, 250, 229)",
            color: "#000",
            "&:hover": {
              backgroundColor: "rgb(167, 243, 208)",
            },
          }}
          text="Continue"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ResourceSelectionModal;
