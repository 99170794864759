import React from "react";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const CustomArrow = ({ onClick, isLeft, bgColor, color }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 2,
      [isLeft ? "left" : "right"]: 16,
      bgcolor: bgColor,
      color: color,
      "&:hover": {
        bgcolor: bgColor,
        color: color,
        transform: "translateY(-50%) scale(1.1)",
      },
      transition: "all 0.2s",
      boxShadow: 2,
    }}
  >
    {isLeft ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
  </IconButton>
);

export default CustomArrow;
