import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// Define Feed for the add Type
interface addFeedParams {
  title: string;
  link: string;
  autoPost: Boolean;
  contentTypeIds: [string];
  contentTopicIds: [string];
  contentRegionIds: [string];
}

interface FeedResponse {
  msg: string;
}

/**
 * @method POST
 * @route
 * @body {Object} addFeedParams
 * @returns {Promise<FeedResponse>}
 */
export const addFeed = async ({
  title,
  link,
  autoPost,
  contentTypeIds,
  contentTopicIds,
  contentRegionIds,
}: addFeedParams): Promise<FeedResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<FeedResponse> = await axios.post(
      `${URL.FEED.BASE}${URL.FEED.V1_ADD_RSS_FEED}`,
      {
        title,
        link,
        autoPost,
        contentTypeIds,
        contentTopicIds,
        contentRegionIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define Feed for the validate feed
interface validateFeedParams {
  url: string;
}

interface validateFeedResponse {
  test: boolean;
}

/**
 * @method POST
 * @route
 * @body {Object} validateFeedParams
 * @returns {Promise<validateFeedResponse>}
 */
export const validateFeed = async ({
  url,
}: validateFeedParams): Promise<validateFeedResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<validateFeedResponse> = await axios.post(
      `${URL.FEED.BASE}${URL.FEED.V1_VALIDATE_RSS_FEED}?url=${url}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for GET all types for admin
interface getFeedParams {
  searchTerm: string;
  pageNumber: string;
}

interface GetFeedResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

/**
 * @method GET
 * @route
 * @returns {Promise<GetFeedResponse>}
 */
export const getAllFeed = async ({
  searchTerm,
  pageNumber,
}: getFeedParams): Promise<GetFeedResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetFeedResponse> = await axios.get(
    `${URL.FEED.BASE}${URL.FEED.V1_GET_ALL_RSS_FEED}?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=10`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for edit type by admin

interface UpdateFeedParams {
  title: string;
  link: string;
  autoPost: Boolean;
  contentTypeIds: [string];
  contentTopicIds: [string];
  contentRegionIds: [string];
  id: string;
}

interface FeedUpdateResponse {
  msg?: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<FeedUpdateResponse>} returns a Promise either an error or the response object
 */
export const editFeed = async ({
  id,
  title,
  link,
  autoPost,
  contentTypeIds,
  contentTopicIds,
  contentRegionIds,
}: UpdateFeedParams): Promise<FeedUpdateResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<FeedUpdateResponse> = await axios.put(
    `${URL.FEED.BASE}${URL.FEED.V1_EDIT_RSS_FEED}/${id}`,
    {
      title,
      link,
      autoPost,
      contentTypeIds,
      contentTopicIds,
      contentRegionIds,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for delete type

interface deleteTypeParams {
  typeIds: string;
}
interface TypeResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<TypeResponse>}
 */
export const deleteFeed = async (
  feedId: deleteTypeParams
): Promise<TypeResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<TypeResponse> = await axios.put(
    `${URL.FEED.BASE}${URL.FEED.V1_DELETE_RSS_FEED}/${feedId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for restore type

interface restoreTypeParams {
  typeIds: string;
}
interface TypeResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<TypeResponse>}
 */
export const restoreFeed = async (
  feedId: restoreTypeParams
): Promise<TypeResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<TypeResponse> = await axios.put(
    `${URL.FEED.BASE}${URL.FEED.V1_ACTIVATE_RSS_FEED}/${feedId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for restore type

interface restoreTypeParams {
  typeIds: string;
}
interface TypeResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<TypeResponse>}
 */
export const getFeedTemplateToUpdate = async (
  feedId: restoreTypeParams
): Promise<TypeResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<TypeResponse> = await axios.get(
    `${URL.FEED.BASE}${URL.FEED.V1_GET_UPDATE_TEMPLATE_RSS_FEED}/${feedId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for restore type

interface createTmplateFeedPramas {}
interface FeedResponse {
  regions: [];
  types: [];
  topics: [];
}
/**
 * @method PUT
 * @route
 * @returns {Promise<FeedResponse>}
 */
export const getFeedTemplateToCreate = async (): Promise<FeedResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<FeedResponse> = await axios.get(
    `${URL.FEED.BASE}${URL.FEED.V1_GET_CREATION_TEMPLATE_RSS_FEED}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
