import { IMAGE_URL_AZURE } from "../constants/env";
import { getImage } from "../store/api/content";

export const checkTokenValidation = (token) => {};
export const nameValidator = (value) => {
  if (value.length < 3) return "Name must be at least 3 characters long";
  if (value.length > 20) return "Name must be less than 20 characters long";
  if (!/^[a-zA-Z ]+$/.test(value))
    return "Name must contain only letters and spaces";
  return false;
};
export const emailValidator = (value) => {
  if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value))
    return true;
  return false;
};

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]); // Get the base64 part
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const fetchAndConvertToBase64 = async (url, isAzure) => {
  try {
    let response;
    if (isAzure) {
      response = await fetch(url + "?" + IMAGE_URL_AZURE);
    } else {
      response = await fetch(url);
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    throw error;
  }
};
export const getMimeType = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  const mimeTypes = {
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
  };
  return mimeTypes[extension] || "application/octet-stream";
};
// First, add this utility function to convert URL to base64
export const urlToBase64 = async (url) => {
  try {
    const response = await fetch(url, {
      mode: "no-cors",
      cache: "no-cache",
      crossOrigin: "anonymous",
    });
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Get base64 data without the data:image/xxx;base64, prefix
        const base64data = reader.result.split(",")[1];
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting URL to base64:", error);
    return null;
  }
};

export const convertImageUrlToBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({
          fileName: imageUrl.split("/").pop(),
          contentType: blob.type,
          fileData: reader.result.split(",")[1],
        });
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image to Base64:", error);
    // If direct conversion fails, try getting the data from backend
    try {
      const fileData = await getImage({ url: imageUrl });
      return {
        fileName: imageUrl.split("/").pop(),
        contentType: fileData.contentType,
        fileData: fileData.fileData,
      };
    } catch (backendError) {
      console.error("Backend fallback also failed:", backendError);
      throw error;
    }
  }
};

export const downloadImage = (imageUrl) => {
  const link = document.createElement("a");
  link.download = imageUrl.split("/").pop(); // Set the filename for the download
  link.href = imageUrl;
  link.target = "_blank"; // Open in a new tab (optional)
  document.body.appendChild(link);
  link.click(); // Trigger the download
  document.body.removeChild(link); // Clean up
};
