import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getTextColor } from "../../../helpers";
import { IMAGE_URL_AZURE } from "../../../constants/env";

const ArticleCard = ({
  article,
  isMobile,
  isTablet,
  width,
  featured,
  isAd,
  external = false,
  link = "",
}) => {
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");
  const { primaryColor, secondaryColor } = useOrganisation();

  const {
    title = "",
    imageLink = "",
    scheduledDate = "",
    topics = "",
    id = "",
  } = article;

  return (
    <Grid
      item
      xs={12}
      sm={isMobile ? 12 : isTablet ? 6 : 3}
      md={3}
      lg={3}
      key={article.id}
    >
      <Card
        sx={{
          maxWidth: isMobile ? "100%" : isTablet ? 280 : 345,
          margin: 1,
          width: width,
          borderRadius: 0,
          background: "transparent",
          cursor: "pointer",
          "& .MuiCardMedia-root": {
            transition: "transform 0.3s ease-in-out",
            height: "100%",
            width: "100%",
            objectFit: "contain",
            objectPosition: "center",
            backgroundColor: "white",
          },
          "&:hover .MuiCardMedia-root": {
            transform: "scale(1.05)",
          },
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          boxShadow: 1,
          position: "relative",
        }}
        style={
          featured
            ? {
                color: getTextColor(primaryColor),
                width: "260px",
                flex: "0 0 auto",
                boxShadow: "none",
              }
            : {
                boxShadow: "none",
              }
        }
        onClick={() => {
          !external
            ? navigate(`/${companyName}/article/details/${id}`)
            : window.open(link, "_blank", "noreferrer");
        }}
      >
        {isAd && (
          <Chip
            label="Ad"
            sx={{
              position: "absolute",
              top: 2,
              left: { xs: 10, sm: 10 },
              zIndex: 1,
              bgcolor: primaryColor,
              color: "white",
              fontWeight: "medium",
              border: "2px solid black",
            }}
          />
        )}
        <div
          style={{
            overflow: "hidden",
            position: "relative",
            paddingTop: "56.25%",
          }}
        >
          <CardMedia
            component="img"
            image={`${imageLink}?${IMAGE_URL_AZURE}`}
            alt={title}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "200px",
          }}
        >
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{
              color: featured
                ? getTextColor(primaryColor)
                : secondaryColor
                ? secondaryColor
                : primaryColor,
              marginBottom: "8px",
            }}
          >
            {topics.split(",").slice(0, 3).join(", ")}
          </Typography>
          <Typography
            variant="h6"
            style={{
              wordWrap: "break-word",
              fontSize: 14,
              textOverflow: "ellipsis",
              marginBottom: "auto",
              fontWeight: "bolder",
              color: featured ? getTextColor(primaryColor) : "Black",
            }}
          >
            {title}
          </Typography>

          {!isAd && (
            <Typography
              variant="body2"
              color="textSecondary"
              style={
                featured
                  ? {
                      color: getTextColor(primaryColor),
                    }
                  : { color: primaryColor }
              }
            >
              {formatDistanceToNow(scheduledDate, {
                addSuffix: true,
              })}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ArticleCard;
