import { useEffect, useState } from "react";
import { Box, Modal, TextField, Typography } from "@mui/material";
import Button from "../../../common/Button";

const AddEditModal = ({
  open,
  handleClose,
  type,
  initialData,
  isEdit,
  addMutate,
  editsMutate,
}) => {
  const [formData, setFormData] = useState({ title: "" });

  useEffect(() => {
    if (isEdit) {
      setFormData(initialData);
    }
  }, [isEdit, initialData]);

  const handleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  const handleSubmit = () => {
    if (formData.title) {
      if (isEdit) {
        editsMutate({ title: formData.title, id: formData.id });
      } else {
        addMutate(formData.title);
      }
      setFormData({ title: "" });
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 400 },
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 12,
          p: { xs: 2, sm: 4 },
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h5">
          {initialData ? "Edit" : "Add"} Item
        </Typography>
        <TextField
          fullWidth
          label="Name"
          value={formData?.title}
          onChange={handleChange}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
          <Button
            onClick={handleClose}
            sx={{ marginRight: 1 }}
            backgroundColor="white"
            text="Cancel"
          />

          <Button onClick={handleSubmit} text="Save" />
        </Box>
      </Box>
    </Modal>
  );
};

export default AddEditModal;
