import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Container,
  Box,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CustomArrow from "../../../common/Arrow";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import { getTextColor } from "../../../../helpers";
import { IMAGE_URL_AZURE } from "../../../../constants/env";

const AdComponents = {
  Carousel: ({ contents, primaryColor, navigate, companyName }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    const getImageHeight = () => {
      if (isMobile) return "250px";
      if (isTablet) return "400px";
      return "500px";
    };

    return (
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          paddingBottom: "12px",
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
          "& .carousel-slider": {
            background: "transparent !important",
          },
          "& .slide": {
            background: "transparent !important",
          },
        }}
      >
        <Box sx={{ padding: { xs: 2, md: 3 } }}>
          <Chip
            label="Ad"
            sx={{
              position: "absolute",
              top: 32,
              left: { xs: 32, sm: 48 },
              zIndex: 10,
              bgcolor: primaryColor,
              color: "white",
              fontWeight: "medium",
            }}
          />
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            renderArrowPrev={(onClick) => (
              <CustomArrow
                onClick={onClick}
                isLeft={true}
                bgColor={primaryColor}
                color={getTextColor(primaryColor) || "black"}
              />
            )}
            renderArrowNext={(onClick) => (
              <CustomArrow
                onClick={onClick}
                isLeft={false}
                bgColor={primaryColor}
                color={getTextColor(primaryColor) || "black"}
              />
            )}
            showIndicators={false}
            style={{ width: "100%" }}
          >
            {contents?.map((article) => (
              <Card
                key={article.id}
                sx={{
                  overflow: "hidden",
                  height: "auto",
                  borderRadius: 2,
                  boxShadow: 1,
                  width: "100%",
                  backgroundColor: "transparent",
                }}
              >
                <CardActionArea
                  onClick={() =>
                    article.content
                      ? navigate(
                          `/${companyName}/article/details/${article?.content?.id}`
                        )
                      : window.open(article?.link, "_blank", "noreferrer")
                  }
                >
                  <Box
                    sx={{
                      height: getImageHeight(),
                      width: "100%",
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0,0,0,0.1)",
                        opacity: 0,
                        transition: "opacity 0.3s ease-in-out",
                      },
                      "&:hover::after": {
                        opacity: 1,
                      },
                      // Gradient overlay for title visibility
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: "40%",
                        background:
                          "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
                        zIndex: 1,
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={`${
                        article?.content?.imageLink || article?.imageUrl
                      }?${IMAGE_URL_AZURE}`}
                      alt={article?.content?.title || article?.title}
                      sx={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        backgroundColor: "#000",
                      }}
                    />
                    {/* Title overlay */}
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        p: { xs: 2, md: 3 },
                        zIndex: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          textShadow: "1px 1px 3px rgba(0,0,0,0.8)",
                          mb: 0.5,
                          fontSize: { xs: "1rem", sm: "1.25rem" },
                        }}
                      >
                        {article?.content?.title || article?.title}
                      </Typography>
                      {article.description && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "rgba(255,255,255,0.9)",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textShadow: "1px 1px 2px rgba(0,0,0,0.8)",
                          }}
                        >
                          {article?.content?.description ||
                            article?.description}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Carousel>
        </Box>
      </Container>
    );
  },

  Banner: ({ imageLink, link, primaryColor, title, description }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          paddingBottom: "12px",
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        }}
      >
        <Box sx={{ margin: { xs: 2, md: 3 } }}>
          <Card
            sx={{
              borderRadius: 2,
              boxShadow: "none",
              overflow: "hidden",
              width: "100%",
              backgroundColor: "transparent",
            }}
            onClick={() => window.open(link, "_blank", "noreferrer")}
          >
            <CardActionArea>
              <Box
                sx={{
                  height: isMobile ? "200px" : "400px",
                  width: "100%",
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.1)",
                    opacity: 0,
                    transition: "opacity 0.3s ease-in-out",
                  },
                  "&:hover::after": {
                    opacity: 1,
                  },
                  // Gradient overlay for title visibility
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "40%",
                    background:
                      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
                    zIndex: 1,
                  },
                }}
              >
                <Chip
                  label="Ad"
                  sx={{
                    position: "absolute",
                    top: 32,
                    left: { xs: 32, sm: 48 },
                    zIndex: 10,
                    bgcolor: primaryColor,
                    color: "white",
                    fontWeight: "medium",
                  }}
                />
                <CardMedia
                  component="img"
                  image={`${imageLink}?${IMAGE_URL_AZURE}`}
                  alt={title || "Advertisement Banner"}
                  sx={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    backgroundColor: "#000",
                  }}
                />
                {/* Title overlay */}
                {title && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      p: { xs: 2, md: 3 },
                      zIndex: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        textShadow: "1px 1px 3px rgba(0,0,0,0.8)",
                        mb: 0.5,
                        fontSize: { xs: "1rem", sm: "1.25rem" },
                        textAlign: "center",
                      }}
                    >
                      {title}
                    </Typography>
                    {description && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "rgba(255,255,255,0.9)",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textShadow: "1px 1px 2px rgba(0,0,0,0.8)",
                        }}
                      >
                        {description}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </CardActionArea>
          </Card>
        </Box>
      </Container>
    );
  },
};

const ArticleAds = ({ data }) => {
  const { primaryColor, companyName } = useOrganisation();
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", background: "#F0F0F0" }}>
      {data?.map((ad, index) => {
        const AdComponent = AdComponents[ad?.type];
        return AdComponent ? (
          <React.Fragment key={index}>
            {ad?.type === "Banner" ? (
              <AdComponent
                imageLink={ad?.ads[0]?.imageUrl}
                link={ad?.ads[0]?.link}
                primaryColor={primaryColor}
                title={ad?.ads[0]?.title}
                description={ad?.ads[0]?.description}
              />
            ) : (
              <AdComponent
                contents={ad?.ads}
                primaryColor={primaryColor}
                navigate={navigate}
                companyName={companyName}
              />
            )}
          </React.Fragment>
        ) : null;
      })}
    </Box>
  );
};

export default ArticleAds;
