import { Box, Typography } from "@mui/material";
import { SwapVert as SwapVertIcon } from "@mui/icons-material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

export const columns = ({ onRowClick, onSort, sortBy, sortOrder }) => [
  {
    accessorKey: "readTime",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">Read Time</Typography>
          {sortBy === "readtime" ? (
            sortOrder === "asc" ? (
              <ArrowUpward
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => onSort("readTime")}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => onSort("readTime")}
              />
            )
          ) : (
            <SwapVertIcon
              fontSize="small"
              onClick={() => onSort("readTime")}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.readTime}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "title",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">Title</Typography>
          {sortBy === "title" ? (
            sortOrder === "asc" ? (
              <ArrowUpward
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => onSort("title")}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => onSort("title")}
              />
            )
          ) : (
            <SwapVertIcon
              fontSize="small"
              onClick={() => onSort("title")}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      </div>
    ),
    cell: ({ row }) => (
      <Box
        component="span"
        sx={{
          marginLeft: 1,
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() => onRowClick && onRowClick(row.original)}
      >
        {row.original.title}
      </Box>
    ),
  },

  {
    accessorKey: "views",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Views</Typography>
        {sortBy === "views" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("views")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("views")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("views")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box
          component="span"
          sx={{
            marginLeft: 1,
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() =>
            onRowClick && row.original.views && onRowClick(row.original)
          }
        >
          {row.original.views}
        </Box>
      </>
    ),
  },
];

export const readerColumns = ({ onSort, sortBy, sortOrder }) => [
  {
    accessorKey: "readerName",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Reader Name</Typography>
        {sortBy === "readername" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("readerName")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("readerName")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("readerName")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.readerName}
      </Box>
    ),
  },
  {
    accessorKey: "readerEmail",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Email</Typography>
        {sortBy === "readeremail" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("readerEmail")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("readerEmail")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("readerEmail")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.readerEmail}
      </Box>
    ),
  },
  {
    accessorKey: "averageReadTime",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Avg. Read Time (sec)</Typography>
        {sortBy === "averagereadtime" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("averageReadTime")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("averageReadTime")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("averageReadTime")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.averageReadTime?.toFixed(2)}
      </Box>
    ),
  },
  {
    accessorKey: "visitCount",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Visit Count</Typography>
        {sortBy === "visitcount" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("visitCount")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("visitCount")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("visitCount")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.visitCount}
      </Box>
    ),
  },
  {
    accessorKey: "mostRecentVisitDate",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Last Visit</Typography>
        {sortBy === "mostrecentvisitdate" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("mostRecentVisitDate")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("mostRecentVisitDate")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("mostRecentVisitDate")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {new Date(row.original.mostRecentVisitDate).toLocaleDateString()}
      </Box>
    ),
  },
];

export const filteredColumns = ({ onRowClick, onSort, sortBy, sortOrder }) => [
  {
    accessorKey: "articleTitle",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Article Title</Typography>
        {sortBy === "articletitle" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("articleTitle")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("articleTitle")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("articleTitle")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box
        component="span"
        sx={{
          marginLeft: 1,
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() => onRowClick(row.original)}
      >
        {row.original.articleTitle}
      </Box>
    ),
  },
  {
    accessorKey: "viewCount",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Views</Typography>
        {sortBy === "viewcount" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("viewCount")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("viewCount")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("viewCount")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box
        component="span"
        sx={{
          marginLeft: 1,
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() =>
          onRowClick && row.original.viewCount && onRowClick(row.original)
        }
      >
        {row.original.viewCount}
      </Box>
    ),
  },
  {
    accessorKey: "averageReadTime",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Avg. Read Time (sec)</Typography>
        {sortBy === "averagereadtime" ? (
          sortOrder === "asc" ? (
            <ArrowUpward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("averageReadTime")}
            />
          ) : (
            <ArrowDownward
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => onSort("averageReadTime")}
            />
          )
        ) : (
          <SwapVertIcon
            fontSize="small"
            onClick={() => onSort("averageReadTime")}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <Box component="span" sx={{ marginLeft: 1 }}>
        {row.original.averageReadTime?.toFixed(2)}
      </Box>
    ),
  },
];
