import React from "react";
import { Select, MenuItem, Typography } from "@mui/material";
import Button from "../../../common/Button";

const AddGroup = ({
  selectedGroup,
  handleGroupChange,
  handleNextStep,
  onClose,
}) => {
  return (
    <div>
      <Typography variant="body1">
        Are you adding to a new or existing group?
      </Typography>
      <Select
        value={selectedGroup}
        onChange={handleGroupChange}
        displayEmpty
        fullWidth
      >
        <MenuItem value="" disabled>
          Select group
        </MenuItem>
        <MenuItem value="Group 1">Group 1</MenuItem>
        <MenuItem value="Group 2">Group 2</MenuItem>
      </Select>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <Button
          onClick={handleNextStep}
          disabled={!selectedGroup}
          text={"Upload to this group"}
        />
        <Button
          variant="outlined"
          onClick={onClose}
          text={"Cancel"}
          backgroundColor="white"
        />
      </div>
    </div>
  );
};

export default AddGroup;
