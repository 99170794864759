import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// -----------------------------------------------------
// Define types for GET all user statistics for admin

interface GetUserStatiscticsResponse {
  subscribers: number;
  unsubscribers: number;
  logins: number;
  active: number;
  inactive: number;
  bounces: number;
  engagements: [];
}

interface getUserStatisticsParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  organisationId: string;
}
/**
 * @method POST
 * @route
 * @returns {Promise<GetUserStatiscticsResponse>}
 */
export const getUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  organisationId,
}: getUserStatisticsParams): Promise<GetUserStatiscticsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserStatiscticsResponse> = await axios.post(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_USER_STATISTICS
    }?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&pageSize=16`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for GET all user statistics for admin

interface GetUserStatiscticsResponse {
  subscribers: number;
  unsubscribers: number;
  logins: number;
  active: number;
  inactive: number;
  bounces: number;
  engagements: [];
}

interface getUserStatisticsParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  organisationId: string;
  userId: string;
}
/**
 * @method POST
 * @route
 * @returns {Promise<GetUserStatiscticsResponse>}
 */
export const getUserengagementStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  organisationId,
  userId,
}: getUserStatisticsParams): Promise<GetUserStatiscticsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserStatiscticsResponse> = await axios.post(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_USER_STATISTICS_ENGAGEMENT
    }/${userId}?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&pageSize=16`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for GET all user login statistics for admin

interface GetUserLoginResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserLoginParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
  organisationId: string;
}

/**
 * @method GET
 * @route
 * @returns {Promise<GetUserLoginResponse>}
 */
export const getAllLoginUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
  organisationId,
}: getUserLoginParams): Promise<GetUserLoginResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserLoginResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_USER_LOGINS
    }?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
  organisationId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllActiveUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
  organisationId,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_USER_STATISTICS_ACTIVE
    }?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
  organisationId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllUnActiveUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
  organisationId,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_USER_STATISTICS_UNACTIVE
    }?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllSubscriptionUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
  organisationId,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_USER_STATISTICS_SUBSCRIPTION
    }?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}

/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllUnSubscriptionUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
  organisationId,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_USER_STATISTICS_UNSUBSCRIPTION
    }?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */

export const getAllBouncesUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
  organisationId,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_USER_STATISTICS_BOUNCES
    }?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all read it content statistics for admin

interface GetViewedContentTopicsResponse {
  // {
  //   "readerName": "sarra11",
  //   "readerEmail": "sarra.guesmi+reader@dunetech.co.uk",
  //   "averageReadTime": 5,
  //   "visitCount": 2,
  //   "mostRecentVisitDate": "2025-01-29T08:09:59.3606869"
  // },
}

interface getUserArticleParams {
  userId: string;
  organisationId: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
  startDate: string;
  endDate: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetViewedContentTopicsResponse>}
 */

export const getUserArticlesStatistics = async ({
  userId,
  organisationId,
  pageNumber,
  sortBy,
  sortOrder,
  startDate,
  endDate,
}: getUserArticleParams): Promise<GetViewedContentTopicsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  console.log(userId);
  const response: AxiosResponse<GetViewedContentTopicsResponse> =
    await axios.get(
      `${URL.STATISTICS.BASE}${
        URL.STATISTICS.V1_ADMIN_USER_STATISTICS_ENGAGEMENT
      }/${userId}?startDate=${startDate}&endDate=${endDate}&organisationId=${
        organisationId ? organisationId : ""
      }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user statistics for admin

interface GetContentStatiscticsResponse {
  created: number;
  views: number;
  shares: number;
  avgReadTime: number;
  mvArticles: [];
  mvByTopic: [];
  mvByType: [];
}

interface getContentStatisticsParams {
  startDate: string;
  endDate: string;
  organisationId: string;
}
/**
 * @method POST
 * @route
 * @returns {Promise<GetContentStatiscticsResponse>}
 */
export const getContentStatistics = async ({
  startDate,
  endDate,
  organisationId,
}: getContentStatisticsParams): Promise<GetContentStatiscticsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetContentStatiscticsResponse> =
    await axios.post(
      `${URL.STATISTICS.BASE}${
        URL.STATISTICS.V1_ADMIN_CONTENT_STATISTICS
      }?organisationId=${organisationId ? organisationId : ""}`,
      { startDate, endDate },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all content created statistics for admin

interface GetCreatedContentResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getCreatedContentParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
  organisationId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetCreatedContentResponse>}
 */
export const getAllCreatedContentStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
  organisationId,
}: getCreatedContentParams): Promise<GetCreatedContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetCreatedContentResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${
      URL.STATISTICS.V1_ADMIN_CONTENT_STATISTICS_CREATED
    }?startDate=${startDate}&endDate=${endDate}&organisationId=${
      organisationId ? organisationId : ""
    }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all content shared statistics for admin

interface GetSharedContentResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getSharedContentParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetSharedContentResponse>}
 */
export const getAllSharedContentStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
}: getSharedContentParams): Promise<GetSharedContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetSharedContentResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_CONTENT_STATISTICS_SHARED}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for GET all viewed content statistics for admin

interface GetViewedContentResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getViewedContentParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetViewedContentResponse>}
 */
export const getAllViewedContentStatistics = async ({
  startDate,
  endDate,
  pageNumber,
  sortBy,
  sortOrder,
}: getViewedContentParams): Promise<GetViewedContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetViewedContentResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_CONTENT_STATISTICS_VIEWS}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all time Notification statistics for admin

interface GetNotificationStatisticsResponse {
  emails: number;
  pushs: number;
  sms: number;
  emailClickRate: number;
  smsClickRate: number;
  pushClickRate: number;
}

interface getNotificationStatisticsParams {
  startDate: string;
  endDate: string;
  organisationId: string;
}
/**
 * @method POST
 * @route
 * @returns {Promise<GetNotificationStatisticsResponse>}
 */
export const getAllNotificationStatistics = async ({
  startDate,
  endDate,
  organisationId,
}: getNotificationStatisticsParams): Promise<GetNotificationStatisticsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetNotificationStatisticsResponse> =
    await axios.post(
      `${URL.STATISTICS.BASE}${
        URL.STATISTICS.V1_ADMIN_NOTIFICATIONS_STATISTICS
      }?organisationId=${organisationId ? organisationId : ""}`,
      { startDate, endDate },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};
// -----------------------------------------------------
// Define types for GET all read it content statistics for admin

interface GetViewedContentResponse {
  // {
  //   "readerName": "sarra11",
  //   "readerEmail": "sarra.guesmi+reader@dunetech.co.uk",
  //   "averageReadTime": 5,
  //   "visitCount": 2,
  //   "mostRecentVisitDate": "2025-01-29T08:09:59.3606869"
  // },
}

interface getViewedContentParams {
  startDate: string;
  endDate: string;
  contentId: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetViewedContentResponse>}
 */
export const getAllReadContentStatistics = async ({
  startDate,
  endDate,
  contentId,
  pageNumber,
  sortBy,
  sortOrder,
}: getViewedContentParams): Promise<GetViewedContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetViewedContentResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_CONTENT_READ_STATISTICS}/${contentId}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all read it content statistics for admin

interface GetViewedContentTypesResponse {
  // {
  //   "readerName": "sarra11",
  //   "readerEmail": "sarra.guesmi+reader@dunetech.co.uk",
  //   "averageReadTime": 5,
  //   "visitCount": 2,
  //   "mostRecentVisitDate": "2025-01-29T08:09:59.3606869"
  // },
}

interface getViewedContentTypesParams {
  startDate: string;
  endDate: string;
  typeId: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetViewedContentTypesResponse>}
 */
export const getAllTypesContentStatistics = async ({
  startDate,
  endDate,
  typeId,
  pageNumber,
  sortBy,
  sortOrder,
}: getViewedContentTypesParams): Promise<GetViewedContentTypesResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetViewedContentTypesResponse> =
    await axios.get(
      `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_TYPES_CONTENT_STATISTICS}/${typeId}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all read it content statistics for admin

interface GetViewedContentTopicsResponse {
  // {
  //   "readerName": "sarra11",
  //   "readerEmail": "sarra.guesmi+reader@dunetech.co.uk",
  //   "averageReadTime": 5,
  //   "visitCount": 2,
  //   "mostRecentVisitDate": "2025-01-29T08:09:59.3606869"
  // },
}

interface getViewedContentTopicsParams {
  startDate: string;
  endDate: string;
  topicId: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetViewedContentTopicsResponse>}
 */
export const getAllTopicsContentStatistics = async ({
  startDate,
  endDate,
  topicId,
  pageNumber,
  sortBy,
  sortOrder,
}: getViewedContentTopicsParams): Promise<GetViewedContentTopicsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetViewedContentTopicsResponse> =
    await axios.get(
      `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_TOPICS_CONTENT_STATISTICS}/${topicId}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all read it content statistics for admin

interface GetViewedContentTopicsResponse {
  // {
  //   "readerName": "sarra11",
  //   "readerEmail": "sarra.guesmi+reader@dunetech.co.uk",
  //   "averageReadTime": 5,
  //   "visitCount": 2,
  //   "mostRecentVisitDate": "2025-01-29T08:09:59.3606869"
  // },
}

interface getLogNotificationParams {
  startDate: string;
  endDate: string;
  topicId: string;
  organisationId: string;
  eventType: string;
  notificationType: string;
  pageNumber: string;
  sortBy: string;
  sortOrder: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetViewedContentTopicsResponse>}
 */
export const getAllLogNotificationStatistics = async ({
  startDate,
  endDate,
  organisationId,
  eventType,
  notificationType,
  pageNumber,
  sortBy,
  sortOrder,
}: getLogNotificationParams): Promise<GetViewedContentTopicsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetViewedContentTopicsResponse> =
    await axios.get(
      `${URL.STATISTICS.BASE}${
        URL.STATISTICS.V1_ADMIN_GET_NOTIFICATIONS_LOG_STATISTICS
      }/?startDate=${startDate}&endDate=${endDate}&organisationId=${
        organisationId ? organisationId : ""
      }&eventType=${eventType}&notificationType=${notificationType}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=16`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};
