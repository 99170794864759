import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  GetApp,
} from "@mui/icons-material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import { columns } from "./columnsData";
import ListTable from "../../common/Table";
import UserModal from "./AddUserModal";
import OrganisatioFilter from "../Statisctics/OrganisationFilter";
import UploadUsersModal from "./UploadUsers";

import {
  addUserByAdmin,
  deleteUsers,
  editUserByAdmin,
  getAllUsersByAdmin,
} from "../../../store/api/users";
import { roles } from "../../../constants/routes";
import { getRole } from "../../../helpers/auth";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getTextColor } from "../../../helpers";

const Users = () => {
  const queryClient = useQueryClient();
  const role = getRole();
  const { primaryColor } = useOrganisation();
  const textColor = getTextColor(primaryColor) || "black";

  const [searchTerm, setSearchTerm] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("DateCreated");
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDescending, setIsDescending] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [IdUserToEdit, setIdUserToEdit] = useState("");
  const [isModalCSVOpen, setIsModalCSVOpen] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const [addUserError, setAddUserError] = useState(null);
  const [editUserError, setEditUserError] = useState(null);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "adminUsers",
      searchTerm,
      currentPage,
      sortBy,
      isDescending,
      organisation,
    ],
    queryFn: () =>
      getAllUsersByAdmin({
        searchTerm,
        currentPage,
        sortBy,
        isDescending,
        organisationId: organisation?.id,
      }),
  });

  const { mutate: addMutate } = useMutation({
    mutationFn: (user) =>
      addUserByAdmin({
        fullName: user.fullName,
        email: user.email,
        locationId: user.locations,
        userTypeId: user.userTypes,
        organisationId: user.organisationId,
      }),
    onSuccess: (data) => {
      queryClient.refetchQueries("adminUsers");
      setToast({
        open: true,
        message: "User added successfully",
        severity: "success",
      });
      setIsAddModalOpen(false);
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.title ||
        "An error occurred while adding the user";
      setToast({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    },
  });

  const { mutate: editMutate } = useMutation({
    mutationFn: (user) =>
      editUserByAdmin({
        id: IdUserToEdit,
        fullName: user.fullName,
        email: user.email,
        locationId: user.locations,
        userTypeId: user.userTypes,
      }),
    onSuccess: (data) => {
      queryClient.refetchQueries("adminUsers");
      setToast({
        open: true,
        message: "User updated successfully",
        severity: "success",
      });
      setIsEditMode(false);
      setIdUserToEdit("");
      setIsAddModalOpen(false);
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.title ||
        "An error occurred while editing the user";
      setToast({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    },
  });
  const { mutate: deleteMutate } = useMutation({
    mutationFn: () => deleteUsers([...selectedIds]),
    onSuccess: (data) => {
      setToast({
        open: true,
        message: "Users deleted successfully",
        severity: "success",
      });
      queryClient.refetchQueries("adminUsers");
      setSelectedIds([]);
    },
    onError: (error) => {
      setToast({
        open: true,
        message: "Error deleting users",
        severity: "error",
      });
    },
  });
  // ------------------------------

  useEffect(() => {
    if (!isLoading) {
      setAllUsers(data?.paginatedContent);
      setTotalPage(data?.totalPages);
    }
  }, [isLoading, data]);

  // ---------------------------------
  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchItem(term);
  };

  const handleItemSearch = () => {
    setSearchTerm(searchItem);
  };

  const handleOpenModal = () => {
    setIsEditMode(false);
    setIsAddModalOpen(true);
  };

  const handleEditUser = (id) => {
    setIdUserToEdit(id);
    setIsEditMode(true);
    setIsAddModalOpen(true);
  };

  const handleSubmitUser = (user) => {
    if (isEditMode) {
      editMutate({ ...user });
    } else {
      setAddUserError(null);
      addMutate({ ...user });
    }
  };

  const handleSelectAll = () => {
    if (selectedIds.length === allUsers.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(allUsers.map((user) => user.id));
    }
  };
  const handleSelect = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleDelete = () => {
    deleteMutate();
    setOpenDelete(false);
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenCSVModal = () => {
    setIsModalCSVOpen(true);
  };

  const handleCloseCSVModal = () => {
    setIsModalCSVOpen(false);
  };
  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    setAddUserError(null);
    setEditUserError(null);
  };
  const handleCloseToast = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <div>
      <Typography variant="h6">View all users</Typography>
      <Box>
        <Grid container flex={1} justifyContent={"space-between"}>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <TextField
              variant="outlined"
              placeholder="Search"
              value={searchItem}
              sx={{ flexGrow: 1 }}
              onChange={handleSearch}
              style={{ border: "1px solid black", borderRadius: "8px" }}
            />
            <IconButton onClick={handleItemSearch}>
              <SearchIcon />
            </IconButton>
            <Button
              variant="raised"
              endIcon={<ControlPointIcon />}
              sx={{
                backgroundColor: primaryColor,
                color: textColor,
                marginRight: 2,
              }}
              onClick={handleOpenModal}
            >
              Add user
            </Button>
            <Button
              variant="raised"
              endIcon={<GetApp />}
              sx={{ color: "#333", border: "1px solid #333" }}
              onClick={() => handleOpenCSVModal()}
            >
              Upload CSV
            </Button>
            <UploadUsersModal
              open={isModalCSVOpen}
              onClose={handleCloseCSVModal}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{ color: "#888" }}
              onClick={handleOpenDelete}
              disabled={selectedIds.length === 0}
            >
              Delete user
            </Button>
          </Box>
          {roles.SuperAdmin === role && (
            <OrganisatioFilter setOrganisation={setOrganisation} />
          )}
        </Grid>
      </Box>
      <ListTable
        data={allUsers}
        columns={columns({
          setSortBy,
          setIsDescending,
          isDescending,
          handleEditUser,
          handleSelectAll,
          selectedIds,
          handleSelect,
        })}
        isLoading={isLoading}
        totalPage={totalPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />

      <UserModal
        open={isAddModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitUser}
        isEdit={isEditMode}
        IdUserToEdit={IdUserToEdit}
        role={role}
      />
      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete the selected users?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting these users cannot be undone. Please confirm if you want to
            proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Users;
