import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getPreviewNewsletter } from "../../../store/api/content";
import { X as CloseIcon } from "lucide-react";

const PreviewNewsletter = ({ open, onClose }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 960px)");
  const [scale, setScale] = useState(1);

  const { data: newsletter, isLoading } = useQuery({
    queryKey: ["previewNewsletter"],
    queryFn: () => getPreviewNewsletter({}),
    enabled: open,
  });

  // Adjust scale based on screen size
  useEffect(() => {
    if (isMobile) {
      setScale(0.8);
    } else if (isTablet) {
      setScale(0.9);
    } else {
      setScale(1);
    }
  }, [isMobile, isTablet]);

  if (isLoading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
      >
        <div className="flex items-center justify-center h-40">
          <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full" />
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: isMobile ? "100vh" : "90vh",
          minHeight: isMobile ? "100vh" : "50vh",
          m: isMobile ? 0 : 2,
        },
      }}
    >
      <div className="sticky top-0 z-10 bg-white border-b">
        <div className="flex items-center justify-between p-4">
          <DialogTitle
            sx={{
              p: 0,
              fontSize: isMobile ? "1.2rem" : "1.5rem",
            }}
          >
            Preview Newsletter
          </DialogTitle>
          <IconButton
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
            size={isMobile ? "small" : "medium"}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <DialogContent
        sx={{
          p: isMobile ? 0 : 2,
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        <div
          className="w-full overflow-x-hidden"
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "top center",
            transition: "transform 0.3s ease",
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: newsletter || "" }}
            className="max-w-full"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewNewsletter;
