import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../common/Button";

import { convertBlobToBase64 } from "../../../../helpers/validator";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import { IMAGE_URL_AZURE } from "../../../../constants/env";

const ImageSelectionModal = ({ open, onClose, onImageSelect, images }) => {
  const { primaryColor } = useOrganisation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("dateAdded");
  const [order, setOrder] = useState("desc");
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleImageSelection = async (image) => {
    const response = await fetch(`${image.fileUrl}?${IMAGE_URL_AZURE}`, {
      method: "GET",
      headers: {
        Origin: window.location.origin,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const fileName = image?.title;
    const contentType = response.headers.get("Content-Type");
    const fileData = await convertBlobToBase64(blob);
    setSelectedImage({
      fileData,
      fileName,
      contentType,
      id: image.id,
      fileSize: image.fileSize,
      dateAdded: image.dateAdded,
    });
  };

  const handleContinue = () => {
    if (selectedImage) {
      onImageSelect(selectedImage);
      onClose();
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const formatFileSize = (size) => {
    return size.toFixed(3);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Images</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box mb={2}>
          <TextField
            fullWidth
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            size="small"
            sx={{ mb: 2 }}
          />
        </Box>

        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">Select</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "title"}
                    direction={orderBy === "title" ? order : "asc"}
                    onClick={() => handleSort("title")}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "fileSize"}
                    direction={orderBy === "fileSize" ? order : "asc"}
                    onClick={() => handleSort("fileSize")}
                  >
                    File Size (MB)
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "dateAdded"}
                    direction={orderBy === "dateAdded" ? order : "asc"}
                    onClick={() => handleSort("dateAdded")}
                  >
                    Date Added
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {images?.map((image) => (
                <TableRow
                  key={image.id}
                  hover
                  onClick={() => handleImageSelection(image)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Radio
                      checked={selectedImage?.id === image.id}
                      onChange={() => handleImageSelection(image)}
                    />
                  </TableCell>
                  <TableCell>{image.title}</TableCell>
                  <TableCell>{formatFileSize(image.fileSize)}</TableCell>
                  <TableCell>{formatDate(image.dateAdded)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={handleContinue}
          disabled={!selectedImage}
          text="Continue"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ImageSelectionModal;
