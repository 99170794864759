import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
  CircularProgress,
  Paper,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Alert,
} from "@mui/material";
import { SketchPicker } from "react-color";
import { FixedSizeList } from "react-window";
import ColorLensIcon from "@mui/icons-material/ColorLens";

import CreateImage from "../../AddContent/ContentForm/CreateContent";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import Button from "../../../common/Button";
import {
  fetchFonts,
  getToUpdateOnboardedOrganisation,
  onboardOrganisation,
} from "../../../../store/api/organisation";
import { getTextColor } from "../../../../helpers";

const LISTBOX_ITEM_SIZE = 48;

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;

  return (
    <div ref={ref}>
      <div {...other}>
        <FixedSizeList
          height={250}
          width="100%"
          itemSize={LISTBOX_ITEM_SIZE}
          itemCount={itemCount}
          overscanCount={5}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </FixedSizeList>
      </div>
    </div>
  );
});

const UpdateOnboarding = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const companyName = localStorage.getItem("companyName");
  const queryClient = useQueryClient();
  const { secondaryColor } = useOrganisation();
  const loadedFontsRef = useRef(new Set());

  const [logoImage, setLogoImage] = useState(null);
  const [primaryFont, setPrimaryFont] = useState("DM Sans");
  const [primaryColor, setPrimaryColor] = useState("#96f7dc");
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openToast, setOpenToast] = useState(false);

  // Fetch existing organization data
  const { data: orgData } = useQuery({
    queryKey: ["updateOnboardedOrganisation"],
    queryFn: () => getToUpdateOnboardedOrganisation({}),
    enabled: !!token,
  });

  // Fetch available fonts
  const { data: fonts = [], isLoading: isFontsLoading } = useQuery({
    queryKey: ["googleFonts"],
    queryFn: fetchFonts,
    staleTime: Infinity,
  });

  // Update mutation
  const { mutate } = useMutation({
    mutationFn: onboardOrganisation,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["onboardedOrganisation"] });
      // navigate(`/${companyName}/admin-dashboard`);
      setOpenToast(true); // Show success toast
    },
  });

  useEffect(() => {
    if (orgData) {
      setPrimaryColor(orgData.primaryColor || "#96f7dc");
      setPrimaryFont(orgData.primaryFont || "DM Sans");
      setLogoImage(orgData.logoImageLink || null);
    }
  }, [orgData]);

  const loadFont = (fontFamily) => {
    if (!loadedFontsRef.current.has(fontFamily)) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
        / /g,
        "+"
      )}&display=swap`;
      document.head.appendChild(link);
      loadedFontsRef.current.add(fontFamily);
    }
  };

  useEffect(() => {
    if (primaryFont) {
      loadFont(primaryFont);
    }
  }, [primaryFont]);

  const filteredFonts = fonts.filter((font) =>
    font.family.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSave = () => {
    mutate({
      primaryColor,
      primaryFont,
      logoImage: logoImage?.contentType ? logoImage : null,
      secondaryColor,
    });
  };
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Snackbar
        open={openToast}
        autoHideDuration={4000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Brand settings updated successfully!
        </Alert>
      </Snackbar>
      <Card elevation={2}>
        <CardHeader
          title="Update Brand Settings"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[50],
            borderBottom: 1,
            borderColor: "divider",
          }}
        />
        <CardContent>
          {/* Logo Section */}
          <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Logo
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <CreateImage
                  image={logoImage}
                  setImage={setLogoImage}
                  isLogo={true}
                  update={true}
                />
              </Box>
            </Box>
          </Paper>

          {/* Font and Color Section Container */}
          <Grid container spacing={3}>
            {/* Font Section */}
            <Grid item xs={12} md={7}>
              <Paper
                elevation={0}
                variant="outlined"
                sx={{
                  p: 3,
                  height: "100%", // Make paper fill grid item height
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Font Selection
                </Typography>

                <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                  <Autocomplete
                    fullWidth
                    options={filteredFonts}
                    getOptionLabel={(option) => option.family}
                    isOptionEqualToValue={(option, value) =>
                      option.family === value.family
                    }
                    loading={isFontsLoading}
                    onInputChange={(event, value) => setSearchQuery(value)}
                    onChange={(event, value) =>
                      value && setPrimaryFont(value.family)
                    }
                    ListboxComponent={ListboxComponent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Fonts"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isFontsLoading ? (
                                <CircularProgress size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    renderOption={(props, option) => {
                      loadFont(option.family);
                      return (
                        <MenuItem
                          {...props}
                          key={option.family}
                          style={{
                            fontFamily: option.family,
                            height: LISTBOX_ITEM_SIZE,
                          }}
                        >
                          {option.family}
                        </MenuItem>
                      );
                    }}
                    noOptionsText={
                      isFontsLoading ? "Loading..." : "No fonts found"
                    }
                  />

                  {primaryFont && (
                    <Box
                      sx={{
                        mt: 3,
                        p: 2,
                        border: 1,
                        borderColor: "divider",
                        borderRadius: 1,
                        fontFamily: primaryFont,
                        flex: 1, // Take remaining space
                        minHeight: "200px", // Minimum height for preview
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontFamily: primaryFont }}
                      >
                        Font Preview: {primaryFont}
                      </Typography>
                      <Typography sx={{ fontFamily: primaryFont }}>
                        The quick brown fox jumps over the lazy dog.
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 1,
                          fontFamily: primaryFont,
                        }}
                      >
                        ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz
                        1234567890
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>

            {/* Color Section */}
            <Grid item xs={12} md={5}>
              <Paper
                elevation={0}
                variant="outlined"
                sx={{
                  p: 3,
                  height: "100%", // Make paper fill grid item height
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Primary Color
                </Typography>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title="Click to change color" arrow>
                    <Paper
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                      sx={{
                        width: "100%",
                        height: "200px", // Fixed height for color preview
                        backgroundColor: primaryColor,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transition: "transform 0.2s",
                        "&:hover": {
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          color: getTextColor(primaryColor),
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                          },
                        }}
                      >
                        <ColorLensIcon />
                      </IconButton>
                    </Paper>
                  </Tooltip>

                  {isColorPickerOpen && (
                    <Box
                      sx={{
                        position: "absolute",
                        zIndex: 1200,
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          position: "fixed",
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                        onClick={() => setIsColorPickerOpen(false)}
                      />
                      <SketchPicker
                        color={primaryColor}
                        onChangeComplete={(color) => setPrimaryColor(color.hex)}
                        disableAlpha={true}
                        presetColors={[
                          "#D0021B",
                          "#F5A623",
                          "#F8E71C",
                          "#8B572A",
                          "#7ED321",
                          "#417505",
                          "#BD10E0",
                          "#9013FE",
                          "#4A90E2",
                          "#50E3C2",
                          "#B8E986",
                          "#000000",
                          "#4A4A4A",
                          "#9B9B9B",
                          "#FFFFFF",
                        ]}
                      />
                    </Box>
                  )}

                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      fontFamily: "monospace",
                      textAlign: "center",
                    }}
                  >
                    Selected color: {primaryColor}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          {/* Save Button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              onClick={handleSave}
              text="Save Changes"
              backgroundColor={primaryColor}
              className="px-6 py-2"
              sx={{
                color: getTextColor(primaryColor),
                "&:hover": {
                  opacity: 0.9,
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UpdateOnboarding;
