import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Paper,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  InputAdornment,
  Divider,
  TextField,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SendIcon from "@mui/icons-material/Send";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { format } from "date-fns";

import Button from "../../../common/Button";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import { getTextColor } from "../../../../helpers";
import {
  getSettingsNotifications,
  updateSettingsNotifications,
} from "../../../../store/api/organisation";

const EmailSchedulingConfig = () => {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();
  const [openToast, setOpenToast] = useState(false);

  // State for form values
  const [config, setConfig] = useState({
    dailyHour: 16,
    dailyMinute: 0,
    weeklyDay: 5,
    weeklyHour: 16,
    weeklyMinute: 0,
    newsleteerDay: 5,
    newsleteerHour: 16,
    newsleteerMinute: 30,
    completeNewsleteerDay: 6,
    completeNewsleteerHour: 17,
    completeNewsleteerMinute: 0,
    newsletterFrequency: 0,
    newesLetterStatringDay: null,
  });

  // Fetch existing configuration
  const { data: emailConfig, isLoading } = useQuery({
    queryKey: ["emailConfiguration"],
    queryFn: getSettingsNotifications,
  });

  // Update mutation
  const { mutate } = useMutation({
    mutationFn: updateSettingsNotifications,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["emailConfiguration"] });
      setOpenToast(true);
    },
  });

  useEffect(() => {
    if (emailConfig) {
      const normalizedConfig = {
        ...emailConfig,
        dailyMinute: emailConfig.dailyMinute >= 30 ? 30 : 0,
        weeklyMinute: emailConfig.weeklyMinute >= 30 ? 30 : 0,
        newsleteerMinute: emailConfig.newsleteerMinute >= 30 ? 30 : 0,
        completeNewsleteerMinute:
          emailConfig.completeNewsleteerMinute >= 30 ? 30 : 0,
        newsletterFrequency: emailConfig.newsletterFrequency ?? 0,
        newesLetterStatringDay: emailConfig.newesLetterStatringDay
          ? new Date(emailConfig.newesLetterStatringDay)
          : null,
      };
      setConfig(normalizedConfig);
    }
  }, [emailConfig]);
  useEffect(() => {
    if (
      (config.newsletterFrequency === 1 || config.newsletterFrequency === 2) &&
      config.newesLetterStatringDay
    ) {
      // Get the day of the week from the starting date (0-6)
      const dayOfWeek = config.newesLetterStatringDay.getDay();

      // Update the complete newsletter day to match the starting date's day
      handleChange(null, "completeNewsleteerDay", dayOfWeek);
    }
  }, [config.newsletterFrequency, config.newesLetterStatringDay]);

  const handleChange = (category, field, value) => {
    if (category) {
      setConfig((prev) => ({
        ...prev,
        [category]: {
          ...prev[category],
          [field]: value,
        },
      }));
    } else {
      setConfig((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleSave = () => {
    mutate(config);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  const isNewsletterConfigValid = () => {
    switch (config.newsletterFrequency) {
      case 0: // Weekly
        return (
          config.newsleteerDay !== undefined &&
          config.newsleteerHour !== undefined &&
          config.newsleteerMinute !== undefined &&
          config.completeNewsleteerDay !== undefined &&
          config.completeNewsleteerHour !== undefined &&
          config.completeNewsleteerMinute !== undefined
        );

      case 1: // Every 2 Weeks
      case 2: // Monthly
        return (
          config.newesLetterStatringDay &&
          config.newsleteerHour !== undefined &&
          config.newsleteerMinute !== undefined &&
          config.completeNewsleteerHour !== undefined &&
          config.completeNewsleteerMinute !== undefined
        );

      default:
        return false;
    }
  };
  const weekdays = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];
  const newsletterFrequencyOptions = [
    { value: 0, label: "Weekly" },
    { value: 1, label: "Every 2 Weeks" },
    { value: 2, label: "Monthly" },
  ];

  // Generate hours for time selection dropdown
  const hours = Array.from({ length: 24 }, (_, i) => ({
    value: i,
    label: i.toString().padStart(2, "0") + ":00",
  }));

  // Create time options for 30-minute intervals
  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    timeOptions.push({
      value: `${hour}:00`,
      label: `${hour.toString().padStart(2, "0")}:00`,
    });
    timeOptions.push({
      value: `${hour}:30`,
      label: `${hour.toString().padStart(2, "0")}:30`,
    });
  }

  // Function to convert hour and minute to time string format
  const getTimeValue = (hour, minute) =>
    `${hour}:${minute === 30 ? "30" : "00"}`;

  // Function to parse time string into hour and minute
  const parseTime = (timeString) => {
    const [hour, minute] = timeString.split(":");
    return {
      hour: parseInt(hour),
      minute: minute === "30" ? 30 : 0,
    };
  };

  if (isLoading) {
    return (
      <Container
        maxWidth="lg"
        sx={{ py: 4, display: "flex", justifyContent: "center" }}
      >
        <Typography>Loading configuration...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Snackbar
        open={openToast}
        autoHideDuration={4000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Email scheduling configuration saved successfully!
        </Alert>
      </Snackbar>
      <Card elevation={2}>
        <CardHeader
          title="Email Scheduling Configuration"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[50],
            borderBottom: 1,
            borderColor: "divider",
          }}
        />
        <CardContent>
          {/* Daily Email Configuration */}
          <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <NotificationsActiveIcon
                style={{ color: primaryColor }}
                sx={{ mr: 1 }}
              />
              <Typography variant="h6">Daily Email Schedule</Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Divider sx={{ mb: 3 }} />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Time</InputLabel>
                  <Select
                    value={getTimeValue(config.dailyHour, config.dailyMinute)}
                    label="Time"
                    onChange={(e) => {
                      const { hour, minute } = parseTime(e.target.value);
                      handleChange(null, "dailyHour", hour);
                      handleChange(null, "dailyMinute", minute);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccessTimeIcon style={{ color: primaryColor }} />
                      </InputAdornment>
                    }
                  >
                    {timeOptions.map((time) => (
                      <MenuItem key={time.value} value={time.value}>
                        {time.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          {/* Weekly Email Configuration */}
          <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <ScheduleIcon style={{ color: primaryColor }} sx={{ mr: 1 }} />
              <Typography variant="h6">Weekly Email Schedule</Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Divider sx={{ mb: 3 }} />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Day of Week</InputLabel>
                  <Select
                    value={config.weeklyDay}
                    label="Day of Week"
                    onChange={(e) =>
                      handleChange(null, "weeklyDay", e.target.value)
                    }
                  >
                    {weekdays.map((day) => (
                      <MenuItem key={day.value} value={day.value}>
                        {day.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Time</InputLabel>
                  <Select
                    value={getTimeValue(config.weeklyHour, config.weeklyMinute)}
                    label="Time"
                    onChange={(e) => {
                      const { hour, minute } = parseTime(e.target.value);
                      handleChange(null, "weeklyHour", hour);
                      handleChange(null, "weeklyMinute", minute);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccessTimeIcon style={{ color: primaryColor }} />
                      </InputAdornment>
                    }
                  >
                    {timeOptions.map((time) => (
                      <MenuItem key={time.value} value={time.value}>
                        {time.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          {/* Newsletter Email Configuration */}
          <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <SendIcon style={{ color: primaryColor }} sx={{ mr: 1 }} />
              <Typography variant="h6">Newsletter Configuration</Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Divider sx={{ mb: 3 }} />

            {/* Newsletter Frequency */}
            <Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
              Newsletter Frequency
            </Typography>
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Frequency</InputLabel>
                  <Select
                    value={config.newsletterFrequency}
                    label="Frequency"
                    onChange={(e) => {
                      const newFrequency = e.target.value;
                      switch (newFrequency) {
                        case 0: // Weekly
                          handleChange(null, "newesLetterStatringDay", null);
                          handleChange(null, "newsleteerDay", 5);
                          handleChange(null, "completeNewsleteerDay", 6);
                          break;
                        case 1: // Every 2 Weeks
                        case 2: // Monthly
                          handleChange(null, "newsleteerDay", undefined);
                          handleChange(
                            null,
                            "completeNewsleteerDay",
                            undefined
                          );
                          break;
                        default: // Weekly
                          handleChange(null, "newesLetterStatringDay", null);
                          handleChange(null, "newsleteerDay", 5);
                          handleChange(null, "completeNewsleteerDay", 6);
                          break;
                      }

                      // Update frequency
                      handleChange(null, "newsletterFrequency", newFrequency);
                    }}
                  >
                    {newsletterFrequencyOptions.map((freq) => (
                      <MenuItem key={freq.value} value={freq.value}>
                        {freq.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {(config.newsletterFrequency === 1 ||
                config.newsletterFrequency === 2) && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    type="date"
                    label="Newsletter Starting Date"
                    InputLabelProps={{ shrink: true }}
                    value={
                      config.newesLetterStatringDay
                        ? format(config.newesLetterStatringDay, "yyyy-MM-dd")
                        : ""
                    }
                    onChange={(e) => {
                      const newDate = e.target.value
                        ? new Date(e.target.value)
                        : null;

                      if (newDate) {
                        // Get the day of the week (0-6)
                        const dayOfWeek = newDate.getDay();
                        console.log(newDate);
                        handleChange(null, "newesLetterStatringDay", newDate);
                        handleChange(null, "newsleteerDay", dayOfWeek);
                        handleChange(null, "completeNewsleteerDay", dayOfWeek);
                      } else {
                        handleChange(null, "newesLetterStatringDay", null);
                        handleChange(null, "newsleteerDay", undefined);
                        handleChange(null, "completeNewsleteerDay", undefined);
                      }
                    }}
                    error={!config.newesLetterStatringDay}
                    helperText={
                      !config.newesLetterStatringDay
                        ? "Starting date is required"
                        : ""
                    }
                  />
                </Grid>
              )}
            </Grid>

            {/* Newsletter Sending Schedule */}
            <Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
              Newsletter Sending Schedule
            </Typography>
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {/* Day selection for Weekly frequency */}
              {
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Day of Week</InputLabel>
                    <Select
                      value={config.newsleteerDay}
                      label="Day of Week"
                      onChange={(e) =>
                        handleChange(null, "newsleteerDay", e.target.value)
                      }
                      disabled={
                        config.newsletterFrequency === 1 ||
                        config.newsletterFrequency === 2
                      }
                    >
                      {weekdays.map((day) => (
                        <MenuItem key={day.value} value={day.value}>
                          {day.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              }

              {/* Time selection - always available */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Time</InputLabel>
                  <Select
                    value={getTimeValue(
                      config.newsleteerHour,
                      config.newsleteerMinute
                    )}
                    label="Time"
                    onChange={(e) => {
                      const { hour, minute } = parseTime(e.target.value);
                      handleChange(null, "newsleteerHour", hour);
                      handleChange(null, "newsleteerMinute", minute);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccessTimeIcon style={{ color: primaryColor }} />
                      </InputAdornment>
                    }
                  >
                    {timeOptions.map((time) => (
                      <MenuItem key={time.value} value={time.value}>
                        {time.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* Newsletter Completion Schedule */}
            <Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
              Newsletter Completion Schedule
            </Typography>
            <Grid container spacing={3}>
              {/* Day selection for Weekly frequency */}
              {
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Day of Week</InputLabel>
                    <Select
                      value={config.completeNewsleteerDay}
                      label="Day of Week"
                      onChange={(e) =>
                        handleChange(
                          null,
                          "completeNewsleteerDay",
                          e.target.value
                        )
                      }
                    >
                      {weekdays.map((day) => (
                        <MenuItem key={day.value} value={day.value}>
                          {day.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              }

              {/* Time selection - always available */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Time</InputLabel>
                  <Select
                    value={getTimeValue(
                      config.completeNewsleteerHour,
                      config.completeNewsleteerMinute
                    )}
                    label="Time"
                    onChange={(e) => {
                      const { hour, minute } = parseTime(e.target.value);
                      handleChange(null, "completeNewsleteerHour", hour);
                      handleChange(null, "completeNewsleteerMinute", minute);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccessTimeIcon style={{ color: primaryColor }} />
                      </InputAdornment>
                    }
                  >
                    {timeOptions.map((time) => (
                      <MenuItem key={time.value} value={time.value}>
                        {time.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          {/* Save Button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              onClick={handleSave}
              text="Save Configuration"
              disabled={!isNewsletterConfigValid()}
              backgroundColor={primaryColor}
              className="px-6 py-2"
              sx={{
                color: getTextColor(primaryColor),
                "&:hover": {
                  opacity: 0.9,
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default EmailSchedulingConfig;
