import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ArticleIcon from "@mui/icons-material/Article";
import { InputAdornment } from "@mui/material";
import { format } from "date-fns";
import Button from "../../common/Button";
import CustomSwitch from "../../common/Swicth";

const NewsletterConfigModal = ({
  open,
  onClose,
  onSave,
  primaryColor,
  initialConfig,
}) => {
  const [config, setConfig] = useState({
    newsletterFrequency: 0, // Default to weekly
    newesLetterStatringDay: null,
    newsleteerDay: 5, // Friday
    newsleteerHour: 16,
    newsleteerMinute: 0,
    completeNewsleteerDay: 6, // Saturday
    completeNewsleteerHour: 17,
    completeNewsleteerMinute: 0,
    isNewsLetterHaveHeroTail: true,
    newsLetterHeroCount: 5,
  });

  useEffect(() => {
    if (initialConfig) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        ...initialConfig,
        // Ensure these fields are set or default
        newsletterFrequency: initialConfig.newsletterFrequency ?? 0,
        newesLetterStatringDay: initialConfig.newesLetterStatringDay
          ? new Date(initialConfig.newesLetterStatringDay)
          : null,
        newsleteerHour: initialConfig.newsleteerHour ?? 16,
        newsleteerMinute: initialConfig.newsleteerMinute ?? 0,
        completeNewsleteerHour: initialConfig.completeNewsleteerHour ?? 17,
        completeNewsleteerMinute: initialConfig.completeNewsleteerMinute ?? 0,
        newsLetterHeroCount: initialConfig.newsLetterHeroCount ?? 5,
        isNewsLetterHaveHeroTail:
          initialConfig.isNewsLetterHaveHeroTail ?? true,
      }));
    }
  }, [initialConfig]);

  useEffect(() => {
    if (
      (config.newsletterFrequency === 1 || config.newsletterFrequency === 2) &&
      config.newesLetterStatringDay
    ) {
      const dayOfWeek = config.newesLetterStatringDay.getDay();

      handleChange(null, "completeNewsleteerDay", dayOfWeek);
    }
  }, [config.newsletterFrequency, config.newesLetterStatringDay]);

  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    timeOptions.push({
      value: `${hour}:00`,
      label: `${hour.toString().padStart(2, "0")}:00`,
    });
    timeOptions.push({
      value: `${hour}:30`,
      label: `${hour.toString().padStart(2, "0")}:30`,
    });
  }

  const weekdays = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];

  const newsletterFrequencyOptions = [
    { value: 0, label: "Weekly" },
    { value: 1, label: "Every 2 Weeks" },
    { value: 2, label: "Monthly" },
  ];

  const contentOptions = Array.from({ length: 8 }, (_, i) => ({
    value: i + 3,
    label: `${i + 3} pieces`,
  }));

  const getTimeValue = (hour, minute) =>
    `${hour}:${minute === 30 ? "30" : "00"}`;

  const parseTime = (timeString) => {
    const [hour, minute] = timeString.split(":");
    return {
      hour: parseInt(hour),
      minute: minute === "30" ? 30 : 0,
    };
  };

  const handleChange = (category, field, value) => {
    if (category) {
      setConfig((prev) => ({
        ...prev,
        [category]: {
          ...prev[category],
          [field]: value,
        },
      }));
    } else {
      setConfig((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleFrequencyChange = (newFrequency) => {
    switch (newFrequency) {
      case 0: // Weekly
        handleChange(null, "newesLetterStatringDay", null);
        handleChange(null, "newsleteerDay", 5);
        handleChange(null, "completeNewsleteerDay", 6);
        break;
      case 1: // Every 2 Weeks
      case 2: // Monthly
        handleChange(null, "newsleteerDay", undefined);
        handleChange(null, "completeNewsleteerDay", undefined);
        break;
      default:
        handleChange(null, "newesLetterStatringDay", null);
        handleChange(null, "newsleteerDay", 5);
        handleChange(null, "completeNewsleteerDay", 6);
        break;
    }
    handleChange(null, "newsletterFrequency", newFrequency);
  };

  const isNewsletterConfigValid = () => {
    switch (config.newsletterFrequency) {
      case 0: // Weekly
        return (
          config.newsleteerDay !== undefined &&
          config.newsleteerHour !== undefined &&
          config.newsleteerMinute !== undefined &&
          config.completeNewsleteerDay !== undefined &&
          config.completeNewsleteerHour !== undefined &&
          config.completeNewsleteerMinute !== undefined
        );

      case 1: // Every 2 Weeks
      case 2: // Monthly
        return (
          config.newesLetterStatringDay &&
          config.newsleteerHour !== undefined &&
          config.newsleteerMinute !== undefined &&
          config.completeNewsleteerHour !== undefined &&
          config.completeNewsleteerMinute !== undefined
        );

      default:
        return false;
    }
  };

  const handleSave = () => {
    if (isNewsletterConfigValid()) {
      onSave(config);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: (theme) => theme.palette.grey[50],
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        Newsletter Configuration
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        {/* Schedule Configuration */}
        <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <ScheduleIcon style={{ color: primaryColor }} sx={{ mr: 1 }} />
            <Typography variant="h6">Schedule Settings</Typography>
          </Box>
          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Frequency</InputLabel>
                <Select
                  value={config.newsletterFrequency}
                  label="Frequency"
                  onChange={(e) => handleFrequencyChange(e.target.value)}
                >
                  {newsletterFrequencyOptions.map((freq) => (
                    <MenuItem key={freq.value} value={freq.value}>
                      {freq.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {(config.newsletterFrequency === 1 ||
              config.newsletterFrequency === 2) && (
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  required
                  type="date"
                  label="Newsletter Starting Date"
                  InputLabelProps={{ shrink: true }}
                  value={
                    config.newesLetterStatringDay
                      ? format(config.newesLetterStatringDay, "yyyy-MM-dd")
                      : ""
                  }
                  onChange={(e) => {
                    const newDate = e.target.value
                      ? new Date(e.target.value)
                      : null;

                    if (newDate) {
                      // Get the day of the week (0-6)
                      const dayOfWeek = newDate.getDay();
                      handleChange(null, "newesLetterStatringDay", newDate);
                      handleChange(null, "newsleteerDay", dayOfWeek);
                      handleChange(null, "completeNewsleteerDay", dayOfWeek);
                    } else {
                      handleChange(null, "newesLetterStatringDay", null);
                      handleChange(null, "newsleteerDay", undefined);
                      handleChange(null, "completeNewsleteerDay", undefined);
                    }
                  }}
                  error={!config.newesLetterStatringDay}
                  helperText={
                    !config.newesLetterStatringDay
                      ? "Starting date is required"
                      : ""
                  }
                />
              </Grid>
            )}

            {
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Day of Week</InputLabel>
                  <Select
                    value={config.newsleteerDay}
                    label="Day of Week"
                    onChange={(e) =>
                      handleChange(null, "newsleteerDay", e.target.value)
                    }
                    disabled={
                      config.newsletterFrequency === 1 ||
                      config.newsletterFrequency === 2
                    }
                  >
                    {weekdays.map((day) => (
                      <MenuItem key={day.value} value={day.value}>
                        {day.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            }

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Time</InputLabel>
                <Select
                  value={getTimeValue(
                    config.newsleteerHour,
                    config.newsleteerMinute
                  )}
                  label="Time"
                  onChange={(e) => {
                    const { hour, minute } = parseTime(e.target.value);
                    handleChange(null, "newsleteerHour", hour);
                    handleChange(null, "newsleteerMinute", minute);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccessTimeIcon style={{ color: primaryColor }} />
                    </InputAdornment>
                  }
                >
                  {timeOptions.map((time) => (
                    <MenuItem key={time.value} value={time.value}>
                      {time.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        {/* Newsletter Completion Schedule */}
        <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <ScheduleIcon style={{ color: primaryColor }} sx={{ mr: 1 }} />
            <Typography variant="h6">Newsletter Completion Schedule</Typography>
          </Box>
          <Divider sx={{ mb: 3 }} />

          <Grid item container xs={12} sm={12}>
            <Grid item xs={6} sm={4} mr={2}>
              <FormControl fullWidth>
                <InputLabel>Day of Week</InputLabel>
                <Select
                  value={config.completeNewsleteerDay}
                  label="Day of Week"
                  onChange={(e) =>
                    handleChange(null, "completeNewsleteerDay", e.target.value)
                  }
                >
                  {weekdays.map((day) => (
                    <MenuItem key={day.value} value={day.value}>
                      {day.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Time</InputLabel>
                <Select
                  value={getTimeValue(
                    config.completeNewsleteerHour,
                    config.completeNewsleteerMinute
                  )}
                  label="Time"
                  onChange={(e) => {
                    const { hour, minute } = parseTime(e.target.value);
                    handleChange(null, "completeNewsleteerHour", hour);
                    handleChange(null, "completeNewsleteerMinute", minute);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccessTimeIcon style={{ color: primaryColor }} />
                    </InputAdornment>
                  }
                >
                  {timeOptions.map((time) => (
                    <MenuItem key={time.value} value={time.value}>
                      {time.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        {/* Content Configuration */}
        <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <ArticleIcon style={{ color: primaryColor }} sx={{ mr: 1 }} />
            <Typography variant="h6">Content Settings</Typography>
          </Box>
          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={config.isNewsLetterHaveHeroTail}
                    onChange={(e) =>
                      handleChange(
                        null,
                        "isNewsLetterHaveHeroTail",
                        e.target.checked
                      )
                    }
                    primaryColor={primaryColor || "#96f7dc"}
                  />
                }
                label="Show Hero Tile"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Number of Content Items</InputLabel>
                <Select
                  value={config.newsLetterHeroCount}
                  label="Number of Content Items"
                  onChange={(e) =>
                    handleChange(null, "newsLetterHeroCount", e.target.value)
                  }
                >
                  {contentOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        {/* Action Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <Button
            onClick={onClose}
            text="Cancel"
            sx={{ mr: 2 }}
            backgroundColor="white"
          />
          <Button
            onClick={handleSave}
            text="Save Configuration"
            disabled={!isNewsletterConfigValid()}
            backgroundColor={primaryColor}
            className="px-6 py-2"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NewsletterConfigModal;
