import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import DataCard from "../DataCard";
import Loading from "../../../common/Loading";
import Button from "../../../common/Button";
import Table from "../../../common/Table";
import { columns, filteredColumns, readerColumns } from "./columns";
import CustomPieChart from "./CustomPieChart";
import { darkenColor, exportToCSV } from "../../../../helpers";
import {
  getAllCreatedContentStatistics,
  getAllReadContentStatistics,
  getAllSharedContentStatistics,
  getAllTopicsContentStatistics,
  getAllTypesContentStatistics,
  getAllViewedContentStatistics,
  getContentStatistics,
} from "../../../../store/api/statistics";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const ContentTab = ({ tabIndex, index, startDate, endDate, organisation }) => {
  const { primaryColor } = useOrganisation();

  const cardsData = [
    {
      title: "Content Created",
      key: "created",
      countKey: "created",
      getDataFunction: getAllCreatedContentStatistics,
    },
    {
      title: "Content Views",
      key: "views",
      countKey: "views",
      getDataFunction: getAllViewedContentStatistics,
    },
    {
      title: "Content Shares",
      key: "shared",
      countKey: "shares",
      getDataFunction: getAllSharedContentStatistics,
    },
    {
      title: "Avg Read Time",
      key: "readTime",
      countKey: "avgReadTime",
      suffix: " sec",
    },
  ];

  const [selectedCard, setSelectedCard] = useState({
    key: "created",
    getDataFunction: getAllCreatedContentStatistics,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterType, setFilterType] = useState(null); // 'topic' or 'type'
  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(null);
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(null);
  const [filteredCurrentPage, setFilteredCurrentPage] = useState(1);
  const [readersCurrentPage, setReadersCurrentPage] = useState(1);

  const [sortBy, setSortBy] = useState("views");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filteredSortBy, setFilteredSortBy] = useState("viewcount");
  const [filteredSortOrder, setFilteredSortOrder] = useState("desc");
  const [readersSortBy, setReadersSortBy] = useState("readername");
  const [readersSortOrder, setReadersSortOrder] = useState("asc");

  const { data: statistics, isLoading } = useQuery({
    queryKey: ["contentStatistics", startDate, endDate, tabIndex, organisation],
    queryFn: () =>
      getContentStatistics({
        startDate,
        endDate,
        organisationId: organisation,
      }),
  });

  const { data: contentStatistics, isLoading: loadContent } = useQuery({
    queryKey: [
      selectedCard?.key,
      startDate,
      endDate,
      currentPage,
      sortBy,
      sortOrder,
    ],
    queryFn: () =>
      selectedCard.getDataFunction({
        startDate,
        endDate,
        pageNumber: currentPage,
        sortBy,
        sortOrder,
      }),
    enabled: !!selectedCard?.getDataFunction,
  });

  const { data: readerStatistics, isLoading: loadingReaders } = useQuery({
    queryKey: [
      "readerStatistics",
      selectedArticle,
      startDate,
      endDate,
      readersCurrentPage,
      readersSortBy,
      readersSortOrder,
    ],
    queryFn: () =>
      getAllReadContentStatistics({
        contentId: selectedArticle?.id,
        startDate,
        endDate,
        pageNumber: readersCurrentPage,
        sortBy: readersSortBy,
        sortOrder: readersSortOrder,
      }),
    enabled: !!selectedArticle?.id,
  });

  const { data: filteredContent, isLoading: loadingFiltered } = useQuery({
    queryKey: [
      "filteredContent",
      filterType,
      selectedFilter,
      startDate,
      endDate,
      filteredCurrentPage,
      filteredSortBy,
      filteredSortOrder,
    ],
    queryFn: () =>
      filterType === "topic"
        ? getAllTopicsContentStatistics({
            topicId: selectedFilter,
            startDate,
            endDate,
            pageNumber: filteredCurrentPage,
            sortBy: filteredSortBy,
            sortOrder: filteredSortOrder,
          })
        : getAllTypesContentStatistics({
            typeId: selectedFilter,
            startDate,
            endDate,
            pageNumber: filteredCurrentPage,
            sortBy: filteredSortBy,
            sortOrder: filteredSortOrder,
          }),
    enabled: !!selectedFilter && !!filterType,
  });

  const handleCardClick = (cardData) => {
    if (cardData.getDataFunction) {
      setSelectedCard({
        key: cardData?.key,
        getDataFunction: cardData.getDataFunction,
      });
      setCurrentPage(1);
      setSelectedArticle(null);
      setSelectedFilter(null);
      setFilterType(null);
      setSelectedFilterName(null);
      setSelectedTopicIndex(null);
      setSelectedTypeIndex(null);
      setSortBy("views");
      setSortOrder("desc");
    }
  };

  const handleArticleClick = (article) => {
    setSelectedFilter(null);
    setFilterType(null);
    setSelectedFilterName(null);
    setSelectedArticle({
      id: article?.contentId,
      title: article?.title,
    });
    setReadersCurrentPage(1);
    // Reset readers sort to default
    setReadersSortBy("readername");
    setReadersSortOrder("asc");
  };

  const handleArticleClickOnChart = (article) => {
    setSelectedFilter(null);
    setFilterType(null);
    setSelectedFilterName(null);
    setSelectedArticle({
      id: article?.articleId,
      title: article?.articleTitle,
    });
    setReadersCurrentPage(1);
    setReadersSortBy("readername");
    setReadersSortOrder("asc");
  };

  const handleBackClick = () => {
    setSelectedArticle(null);
  };

  const handlePieChartClick = (article) => {
    setSelectedFilter(null);
    setFilterType(null);
    setSelectedFilterName(null);
    setSelectedArticle({
      id: article?.id,
      title: article?.name,
    });
    setReadersCurrentPage(1);
    setReadersSortBy("readername");
    setReadersSortOrder("asc");
  };

  const handleBarClick = (event, elements, chart) => {
    setSelectedArticle(null);

    if (elements && elements.length > 0) {
      const clickedIndex = elements[0].index;
      const chartTitle = event.chart.canvas
        .closest(".MuiPaper-root")
        .querySelector("h6").textContent;

      if (chartTitle.includes("Topic")) {
        setFilterType("topic");
        setSelectedFilter(statistics?.mvByTopic[clickedIndex].id);
        setSelectedFilterName(statistics?.mvByTopic[clickedIndex].name);
        setSelectedTopicIndex(clickedIndex); // Set the selected topic index
        setSelectedTypeIndex(null);
      } else {
        setFilterType("type");
        setSelectedFilter(statistics?.mvByType[clickedIndex].id);
        setSelectedFilterName(statistics?.mvByType[clickedIndex].name);
        setSelectedTypeIndex(clickedIndex); // Set the selected type index
        setSelectedTopicIndex(null);
      }
      setFilteredCurrentPage(1);
      // Reset filtered sort to default
      setFilteredSortBy("viewcount");
      setFilteredSortOrder("desc");
    }
  };

  const handleBackToOverview = () => {
    setSelectedFilter(null);
    setFilterType(null);
    setSelectedFilterName(null);
    setSelectedTopicIndex(null);
    setSelectedTypeIndex(null);
  };

  // Handle sorting for main content table
  const handleSort = (columnId) => {
    // Toggle order if same column is clicked
    if (sortBy === columnId.toLowerCase()) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set new column and default to ascending
      setSortBy(columnId.toLowerCase());
      setSortOrder("asc");
    }
    setCurrentPage(1); // Reset to first page when sorting changes
  };

  // Handle sorting for filtered content table
  const handleFilteredSort = (columnId) => {
    if (filteredSortBy === columnId.toLowerCase()) {
      setFilteredSortOrder(filteredSortOrder === "asc" ? "desc" : "asc");
    } else {
      setFilteredSortBy(columnId.toLowerCase());
      setFilteredSortOrder("asc");
    }
    setFilteredCurrentPage(1);
  };

  // Handle sorting for readers table
  const handleReadersSort = (columnId) => {
    if (readersSortBy === columnId.toLowerCase()) {
      setReadersSortOrder(readersSortOrder === "asc" ? "desc" : "asc");
    } else {
      setReadersSortBy(columnId.toLowerCase());
      setReadersSortOrder("asc");
    }
    setReadersCurrentPage(1);
  };

  // Determine if we're in the overview state or a detail view
  const isOverviewState = !selectedArticle && !selectedFilter;

  const chartOptions = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    onClick: handleBarClick,
    onHover: (event, chartElements) => {
      event.native.target.style.cursor = chartElements.length
        ? "pointer"
        : "default";
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "#f0f0f0",
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const getTopicDataset = () => {
    return [
      {
        data: statistics?.mvByTopic?.map((el) => el.count) || [],
        backgroundColor: statistics?.mvByTopic?.map((el, index) =>
          index === selectedTopicIndex ? "#8667E8" : "#A78BFA"
        ),
        borderColor: statistics?.mvByTopic?.map((el, index) =>
          index === selectedTopicIndex ? "#4F46E5" : "transparent"
        ),
        borderWidth: statistics?.mvByTopic?.map((el, index) =>
          index === selectedTopicIndex ? 2 : 0
        ),
        borderRadius: 4,
        barThickness: 20,
      },
    ];
  };

  // Create a function to get custom dataset for Type chart
  const getTypeDataset = () => {
    return [
      {
        data: statistics?.mvByType?.map((el) => el.count) || [],
        backgroundColor: statistics?.mvByType?.map((el, index) =>
          index === selectedTypeIndex
            ? darkenColor(primaryColor, 10)
            : primaryColor
        ),
        borderColor: statistics?.mvByType?.map((el, index) =>
          index === selectedTypeIndex
            ? darkenColor(primaryColor, 30)
            : "transparent"
        ),
        borderWidth: statistics?.mvByType?.map((el, index) =>
          index === selectedTypeIndex ? 2 : 0
        ),
        borderRadius: 4,
        barThickness: 20,
      },
    ];
  };

  const mostViewedByTopicData = {
    labels: statistics?.mvByTopic?.map((el) => el.name) || [],
    datasets: getTopicDataset(),
  };

  const cloudSecurityData = {
    labels: statistics?.mvByType?.map((el) => el.name) || [],
    datasets: getTypeDataset(),
  };

  if (isLoading && !statistics) {
    return <Loading />;
  }

  return (
    <Box sx={{ maxWidth: "1200px", mx: "auto", px: { xs: 2, sm: 3, md: 4 } }}>
      <Grid container spacing={2} alignItems="stretch" justifyContent="center">
        {cardsData.map((cardData) => (
          <Grid item xs={12} sm={6} md={3} key={cardData?.key}>
            <DataCard
              title={cardData.title}
              count={
                cardData.suffix
                  ? `${statistics?.[cardData.countKey] || 0}${cardData.suffix}`
                  : statistics?.[cardData.countKey] || 0
              }
              onClick={() => {
                handleCardClick(cardData);
              }}
              isSelected={selectedCard.key === cardData.key && isOverviewState}
              backgroundColor="#F3F4F6"
              isActivated={isOverviewState}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center">
        <Grid item xs={12} md={4}>
          <CustomPieChart
            title="Most Viewed Articles"
            data={statistics?.mvArticles}
            onArticleClick={handlePieChartClick}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "100%",
              minHeight: "300px",
              "& h6": {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
              },
            }}
          >
            <Typography variant="h6">Most Viewed by Topic</Typography>
            <div style={{ height: "250px" }}>
              <Bar data={mostViewedByTopicData} options={chartOptions} />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "100%",
              minHeight: "300px",
              "& h6": {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
              },
            }}
          >
            <Typography variant="h6">Most Viewed by Type</Typography>
            <div style={{ height: "250px" }}>
              <Bar data={cloudSecurityData} options={chartOptions} />
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          text="Export CSV"
          onClick={() => exportToCSV(contentStatistics?.paginatedContent)}
        />
      </Box>

      {selectedFilter ? (
        <Box sx={{ mt: 3 }}>
          <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleBackToOverview} sx={{ mr: 2 }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              Articles by {filterType === "topic" ? "Topic" : "Type"}:{" "}
              {selectedFilterName}
            </Typography>
          </Box>

          {loadingFiltered ? (
            <Loading />
          ) : (
            <Box sx={{ overflowX: "auto", width: "100%" }}>
              <Table
                data={filteredContent?.paginatedContent}
                columns={filteredColumns({
                  onRowClick: handleArticleClickOnChart,
                  onSort: handleFilteredSort,
                  sortBy: filteredSortBy,
                  sortOrder: filteredSortOrder,
                })}
                currentPage={filteredCurrentPage}
                totalPage={filteredContent?.totalPages}
                setCurrentPage={setFilteredCurrentPage}
              />
            </Box>
          )}
        </Box>
      ) : !selectedArticle ? (
        loadContent && selectedCard ? (
          <Loading />
        ) : (
          <Box sx={{ overflowX: "auto", width: "100%" }}>
            <Table
              data={contentStatistics?.paginatedContent}
              columns={columns({
                onRowClick: handleArticleClick,
                onSort: handleSort,
                sortBy,
                sortOrder,
              })}
              currentPage={currentPage}
              totalPage={contentStatistics?.totalPages}
              setCurrentPage={setCurrentPage}
            />
          </Box>
        )
      ) : null}

      {selectedArticle && (
        <Box
          sx={{ maxWidth: "1200px", mx: "auto", px: { xs: 2, sm: 3, md: 4 } }}
        >
          <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              Reader Statistics: {selectedArticle.title || selectedArticle.name}
            </Typography>
          </Box>

          {loadingReaders ? (
            <Loading />
          ) : (
            <Box sx={{ overflowX: "auto", width: "100%" }}>
              <Table
                data={readerStatistics?.paginatedContent}
                columns={readerColumns({
                  onSort: handleReadersSort,
                  sortBy: readersSortBy,
                  sortOrder: readersSortOrder,
                })}
                currentPage={readersCurrentPage}
                totalPage={readerStatistics?.totalPages}
                setCurrentPage={setReadersCurrentPage}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ContentTab;
