import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Typography, Link } from "@mui/material";

import Input from "../../common/Input";
import Toast from "../../common/Toast";

import { login, superAdminLogin } from "../../../store/api/users";
import { getOnboardedOrganisation } from "../../../store/api/organisation";
import Button from "../../common/Button";
import "./style.css";
import { IMAGE_URL_AZURE } from "../../../constants/env";

const Login = ({ superadmin, primaryColor }) => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const companyName = localStorage.getItem("companyName");
  const logoCompany = localStorage.getItem("logoCompany");
  const textColor = "black";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isToken, setIsToken] = useState(false);
  const [errors, setErrors] = useState({ email: false, password: false });
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    password: "",
  });
  const [toast, setToast] = useState({ message: null, status: null });
  // Add a new state to force toast reset
  const [toastKey, setToastKey] = useState(0);

  const navigate = useNavigate();
  const { data, isFetched } = useQuery({
    queryKey: ["loginonboardedOrganisation", { context: "login" }],
    queryFn: () => getOnboardedOrganisation({}),
    enabled: !!token && !superadmin, // Ensure the query only runs when a token is present
  });

  // Reset toast when user interacts with form
  const resetToast = () => {
    if (toast.message) {
      setToast({ message: null, status: null });
    }
  };

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const { mutate } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      localStorage.setItem("token", JSON.stringify(data.accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));

      // Reset toast first to ensure it shows up again
      setToast({ message: null, status: null });
      setTimeout(() => {
        setToast({ message: "Login successful!", status: 200 });
        // Increment the key to force re-render
        setToastKey((prev) => prev + 1);
      }, 10);

      // Check if there's a pending article to redirect to
      const pendingArticleId = localStorage.getItem("pendingArticleId");
      const pendingArticleSource = localStorage.getItem("pendingArticleSource");

      if (pendingArticleId) {
        // Clear the stored values
        // localStorage.removeItem("pendingArticleId");
        // localStorage.removeItem("pendingArticleSource");

        // Redirect to the article
        window.location.href = `/${companyName}/article/details/${pendingArticleId}?ComingFrom=${
          pendingArticleSource || "email"
        }`;
      } else {
        setIsToken(true);
      }
    },
    onError: (error) => {
      let errorMessage = "Something went wrong.";
      let status = null;

      if (error.response) {
        status = error.response.status;
        // Prioritize the backend error message when available
        if (error.response.data && error.response.data.title) {
          errorMessage = error.response.data.title;
        } else {
          // Default error messages based on status code if no title is provided
          switch (status) {
            case 400:
              errorMessage = "Bad request. Please check your input.";
              break;
            case 401:
              errorMessage =
                "Incorrect email or password. Please check your credentials.";
              break;
            case 500:
              errorMessage = "Server error. Please try again later.";
              break;
            default:
              errorMessage = "Something went wrong.";
          }
        }
      }

      // Reset toast first to ensure it shows up again
      setToast({ message: null, status: null });
      setTimeout(() => {
        setToast({ message: errorMessage, status });
        // Increment the key to force re-render
        setToastKey((prev) => prev + 1);
      }, 10);
    },
  });
  //adminLogin
  // const { mutate } = useMutation({
  //   mutationFn: login,
  //   onSuccess: (data) => {
  //     localStorage.setItem("token", JSON.stringify(data.accessToken));
  //     localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
  //     // Reset toast first to ensure it shows up again
  //     setToast({ message: null, status: null });
  //     setTimeout(() => {
  //       setToast({ message: "Login successful!", status: 200 });
  //       // Increment the key to force re-render
  //       setToastKey((prev) => prev + 1);
  //     }, 10);
  //     setIsToken(true);
  //   },

  // });

  //SuperadminLogin
  const { mutate: SuperAdminMutate, isPending: SuperAdminPending } =
    useMutation({
      mutationFn: superAdminLogin,
      onSuccess: (data) => {
        //TODO save user Data
        localStorage.setItem("token", JSON.stringify(data.accessToken));
        localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
        // Reset toast first to ensure it shows up again
        setToast({ message: null, status: null });
        setTimeout(() => {
          setToast({ message: "Login successful!", status: 200 });
          // Increment the key to force re-render
          setToastKey((prev) => prev + 1);
        }, 10);
        window.location.href = `/workspace/superadmin-dashboard`;
      },
      onError: (error) => {
        let errorMessage = "Something went wrong.";
        let status = null;

        if (error.response) {
          status = error.response.status;
          // Prioritize the backend error message when available
          if (error.response.data && error.response.data.title) {
            errorMessage = error.response.data.title;
          } else {
            // Default error messages based on status code if no title is provided
            switch (status) {
              case 400:
                errorMessage = "Bad request. Please check your input.";
                break;
              case 401:
                errorMessage =
                  "Incorrect email or password. Please check your credentials.";
                break;
              case 500:
                errorMessage = "Server error. Please try again later.";
                break;
              default:
                errorMessage = "Something went wrong.";
            }
          }
        }

        // Reset toast first to ensure it shows up again
        setToast({ message: null, status: null });
        setTimeout(() => {
          setToast({ message: errorMessage, status });
          // Increment the key to force re-render
          setToastKey((prev) => prev + 1);
        }, 10);
      },
    });

  useEffect(() => {
    if (isFetched) {
      if (data?.isOnBoarded === false) {
        window.location.href = `/${companyName.trim()}/onboarding`;
      } else if (data?.isOnBoarded === true) {
        window.location.href = `/${companyName.trim()}/dashboard`;
      } else if (!token) {
        // If token is missing, redirect to login
        navigate("/");
      }
    }
  }, [data, isFetched, token, navigate]);

  useEffect(() => {
    console.log(superadmin);

    if (!!superadmin) {
      handleLocalStorage();
    }
  }, [location, superadmin]);

  const handleLocalStorage = () => {
    localStorage.removeItem("logoCompany");
    localStorage.removeItem("organisationId");
    localStorage.clear();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      SubmitForm();
    }
  };

  // Reset toast on user input
  const handleInputChange = (setter, field) => (value) => {
    resetToast();

    // Reset error for this field
    if (field === "email") {
      setErrors((prev) => ({ ...prev, email: false }));
      setErrorMessages((prev) => ({ ...prev, email: "" }));
    } else if (field === "password") {
      setErrors((prev) => ({ ...prev, password: false }));
      setErrorMessages((prev) => ({ ...prev, password: "" }));
    }

    setter(value);
  };

  const validateEmail = () => {
    if (email.trim() === "") {
      setErrors((prev) => ({ ...prev, email: true }));
      setErrorMessages((prev) => ({ ...prev, email: "Email is required" }));
      return false;
    } else if (!isValidEmail(email)) {
      setErrors((prev) => ({ ...prev, email: true }));
      setErrorMessages((prev) => ({
        ...prev,
        email: "Please enter a valid email address",
      }));
      return false;
    }
    return true;
  };

  const SubmitForm = () => {
    let hasError = false;
    const newErrors = { email: false, password: false };
    const newErrorMessages = { email: "", password: "" };
    let organisationId = localStorage.getItem("organisationId");

    // Validate email
    if (email.trim() === "") {
      newErrors.email = true;
      newErrorMessages.email = "Email is required";
      hasError = true;
    } else if (!isValidEmail(email)) {
      newErrors.email = true;
      newErrorMessages.email = "Please enter a valid email address";
      hasError = true;
    }

    // Validate password
    if (password.trim() === "") {
      newErrors.password = true;
      newErrorMessages.password = "Password is required";
      hasError = true;
    }

    setErrors(newErrors);
    setErrorMessages(newErrorMessages);

    if (!hasError) {
      if (superadmin) {
        SuperAdminMutate({
          email: email,
          password: password,
        });
      } else {
        mutate({
          email: email,
          password: password,
          organisationId,
        });
      }
    } else {
      // Show toast for validation errors
      setToast({ message: null, status: null });
      setTimeout(() => {
        setToast({
          message: "Please fix the form errors before submitting.",
          status: 400,
        });
        setToastKey((prev) => prev + 1);
      }, 10);
    }
  };

  const colors = {
    text: "#000000",
    link: "#333333",
    background: "#ffffff",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        onKeyPress={handleKeyPress}
      >
        {logoCompany && !superadmin && (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={`${logoCompany}?${IMAGE_URL_AZURE}`}
              alt="Preview"
              style={{
                width: "30%",
                height: "auto",
              }}
            />
          </Box>
        )}
        <Typography
          variant="h5"
          textAlign="center"
          color={textColor}
          fontWeight="bold"
        >
          Log in
        </Typography>
        <Input
          required={true}
          id="email"
          name="email"
          onChange={handleInputChange(setEmail, "email")}
          value={email}
          label="Email address"
          type="email"
          error={errors.email}
          helperText={errors.email ? errorMessages.email : ""}
          style={{ backgroundColor: "white" }}
          onBlur={validateEmail}
        />
        <Input
          required={true}
          onChange={handleInputChange(setPassword, "password")}
          value={password}
          label="Password"
          type="password"
          error={errors.password}
          helperText={errors.password ? errorMessages.password : ""}
          style={{ backgroundColor: "white" }}
        />
        <Link
          href={"/forgetPassword"}
          color={colors.link}
          sx={{ marginBottom: 2, textAlign: "center" }}
        >
          <Typography
            variant="body2"
            textAlign="center"
            color={textColor}
            fontWeight="bold"
          >
            Forgot your password?
          </Typography>
        </Link>

        <Button
          backgroundColor={primaryColor}
          onClick={SubmitForm}
          text="Continue"
        />
      </Box>
      <Link
        href={"/"}
        color={colors.link}
        sx={{ marginBottom: 2, marginTop: 2 }}
      >
        <Typography
          variant="body2"
          textAlign="center"
          color={textColor}
          fontWeight="bold"
          onClick={handleLocalStorage}
        >
          Change Vendor name
        </Typography>
      </Link>
      {toast.message && (
        <Toast key={toastKey} message={toast.message} status={toast.status} />
      )}
    </Box>
  );
};

export default Login;
