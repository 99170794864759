import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface ToastProps {
  message: string;
  status: number | null;
}

const Toast: React.FC<ToastProps> = ({ message, status }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let severity: "success" | "info" | "warning" | "error" = "error";

  switch (status) {
    case 200:
      severity = "success";
      break;
    case 400:
    case 500:
      severity = "error";
      break;
    case 401:
      severity = "warning";
      break;
    default:
      severity = "error";
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={severity} variant="filled" onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
