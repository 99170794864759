import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import DataCard from "../DataCard";
import Loading from "../../../common/Loading";
import Table from "../../../common/Table";
import Button from "../../../common/Button";
import { notificationColumns } from "./columns";
import {
  getAllNotificationStatistics,
  getAllLogNotificationStatistics,
} from "../../../../store/api/statistics";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import { exportToCSV } from "../../../../helpers";

const NotificationTab = ({
  tabIndex,
  index,
  startDate,
  endDate,
  organisation,
}) => {
  const { primaryColor, primaryColorLight } = useOrganisation();
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [notificationType, setNotificationType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBarIndex, setSelectedBarIndex] = useState(null);
  const [sortBy, setSortBy] = useState("name"); // Default sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Default sort order

  const cardsData = [
    {
      title: "Email Click Rate",
      key: "Email",
      countKey: "emailClickRate",
      suffix: "%",
    },
    {
      title: "Push Click Rate",
      key: "Push",
      countKey: "pushClickRate",
      suffix: "%",
    },
    // {
    //   title: "SMS Click Rate",
    //   key: "Sms",
    //   countKey: "smsClickRate",
    //   suffix: "%",
    // },
  ];

  // Main statistics query
  const { data: statistics, isLoading } = useQuery({
    queryKey: [
      "notificationStatistics",
      startDate,
      endDate,
      tabIndex,
      organisation,
    ],
    queryFn: () =>
      getAllNotificationStatistics({
        startDate,
        endDate,
        organisationId: organisation,
      }),
  });

  const { data: logStatistics, isLoading: isLoadingLogs } = useQuery({
    queryKey: [
      "notificationLogStatistics",
      startDate,
      endDate,
      selectedEventType,
      notificationType,
      currentPage,
      sortBy,
      sortOrder,
    ],
    queryFn: () =>
      getAllLogNotificationStatistics({
        startDate,
        endDate,
        notificationType: notificationType,
        organisationId: organisation,
        eventType: selectedEventType,
        pageNumber: currentPage,
        sortBy: sortBy, // Add sortBy parameter
        sortOrder: sortOrder, // Add sortOrder parameter
      }),
    enabled: !!selectedEventType && !!notificationType,
  });

  const getCustomDataset = () => {
    return [
      {
        label: "Click Events",
        data: [
          statistics?.emails || 0,
          statistics?.pushs || 0,
          // statistics?.sms || 0,
        ],
        backgroundColor: [0, 1].map((index) =>
          index === selectedBarIndex ? primaryColor : "grey"
        ),
        borderColor: [0, 1].map((index) =>
          index === selectedBarIndex
            ? darkenColor(primaryColor, 20)
            : "transparent"
        ),
        borderWidth: [0, 1].map((index) =>
          index === selectedBarIndex ? 2 : 0
        ),
        borderRadius: 4,
        barThickness: 20,
      },
    ];
  };

  const darkenColor = (color, percent) => {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const R = Math.max(0, (num >> 16) - amt);
    const G = Math.max(0, ((num >> 8) & 0x00ff) - amt);
    const B = Math.max(0, (num & 0x0000ff) - amt);
    return `#${(0x1000000 + (R << 16) + (G << 8) + B).toString(16).slice(1)}`;
  };

  const chartData = {
    labels: [
      "Email Clicks",
      "Push Clicks",
      //  "SMS Clicks"
    ],
    datasets: getCustomDataset(),
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (_, elements) => {
      if (elements.length > 0) {
        const clickedIndex = elements[0].index;
        const types = [
          "Email",
          "Push",
          //  "Sms"
        ];

        setSelectedEventType(null);
        setNotificationType(null);

        setSelectedBarIndex(clickedIndex);

        setTimeout(() => {
          setNotificationType(types[clickedIndex]);
          setSelectedEventType("Sent");
          setCurrentPage(1);
        }, 0);
      }
    },
    onHover: (event, chartElements) => {
      event.native.target.style.cursor = chartElements.length
        ? "pointer"
        : "default";
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: "#f0f0f0",
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // Handle card click with state update for selected bar
  const handleCardClick = (cardData, index) => {
    setSelectedBarIndex(null);
    setNotificationType(cardData.key);
    setSelectedEventType("Click");
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSort = (column) => {
    if (sortBy === column.toLowerCase()) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column.toLowerCase());
      setSortOrder("asc");
    }
    setCurrentPage(1);
  };

  const getColumnsWithSorting = () => {
    return notificationColumns({
      onRowClick: (rowData) => console.log("Row clicked:", rowData),
      onSort: handleSort,
      currentSortBy: sortBy,
      currentSortOrder: sortOrder,
      primaryColor: primaryColorLight,
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ maxWidth: "1200px", mx: "auto" }}>
      <Grid
        container
        spacing={2}
        sx={{ mt: 2, padding: 0 }}
        justifyContent={"space-between"}
      >
        <Grid item xs={12} md={5.5}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "100%",
              minHeight: "300px",
              "& h6": {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
              },
            }}
          >
            <Typography variant="h6">Notification Click Rates</Typography>
            <div style={{ height: "250px" }}>
              <Bar data={chartData} options={chartOptions} />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {cardsData.map((cardData, index) => (
              <Grid
                item
                xs={12}
                sm={6} // Last card takes full width on smaller screens
                key={cardData.key}
              >
                <DataCard
                  title={cardData.title}
                  count={`${statistics?.[cardData.countKey]?.toFixed(2) || 0}${
                    cardData.suffix
                  }`}
                  onClick={() => handleCardClick(cardData, index)}
                  isSelected={selectedEventType === cardData.key}
                  backgroundColor="#F3F4F6"
                  isActivated={!selectedBarIndex}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {selectedEventType && notificationType ? (
        <>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              text="Export CSV"
              onClick={() => exportToCSV(logStatistics?.paginatedContent)}
            />
          </Box>
          <Box>
            <Typography>
              {selectedEventType}{" "}
              {notificationType === "Push" ? "Notifications" : notificationType}
            </Typography>
          </Box>
          <Box sx={{ overflowX: "auto", width: "100%", mt: 3 }}>
            {isLoadingLogs ? (
              <Loading />
            ) : (
              <Table
                data={logStatistics?.paginatedContent}
                columns={getColumnsWithSorting()}
                currentPage={logStatistics?.pageNumber || 1}
                totalPage={logStatistics?.totalPages || 1}
                setCurrentPage={handlePageChange}
              />
            )}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default NotificationTab;
