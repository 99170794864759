import { createBrowserRouter } from "react-router-dom";
import { getRole } from "../../helpers/auth";
import { roles } from "../../constants/routes";

import Login from "../auth/Login";
import ForgetPassword from "../auth/ForgetPassword";
import Dashboard from "../Dashboard";
import CompanyLogin from "../auth/CompanyLogin";
import ResetPassword from "../auth/ResetPassword";
import Reset from "../../compnents/pages/ForgetPassword/Reset";
import NotFoundPage from "../NotFoundPage";
import CreateContent from "../Admin/AddContent";
import AdminDashboard from "../Admin/AdminDashboard";
import PrivateRoute from "./PrivateRoute";
import AllContent from "../Admin/AllContent";
import AllUsers from "../Admin/Users";
import Config from "../Admin/Settings/Config";
import AccountSetting from "../Settings/Account";
import Preferences from "../Settings/Preferences";
import AllNewsLetter from "../Admin/NewsLetter";
import ArticleDetails from "../Dashboard/ArticleDetails";
import Statistics from "../Admin/Statistics";
import AllOrganisation from "../Admin/AllOrganisation";
import Onboarding from "../auth/Onboarding";
import AuthRedirect from "./AuthRedirect";
import Broadcast from "../Admin/Broadcast";
import AllApprovedContent from "../Admin/ApprovedContent";
import QueuedContent from "../Admin/QueuedContent";
import RssFeed from "../Admin/RssFeed";
import Layout from "../Layout";
import EmailHandler from "../Emailhandler";

const companyName = localStorage.getItem("companyName");

export const routesConfig = [
  {
    path: "/:companyName/article/details/:id",
    element: <EmailHandler />,
  },
  {
    path: `/`,
    element: <AuthRedirect element={<CompanyLogin />} />,
  },
  {
    path: `/workspace`,
    element: <Login superadmin={true} />,
  },
  {
    path: `/:companyName/login`,
    element: <AuthRedirect element={<Login />} />,
  },
  {
    path: `/reset-password`,
    element: <ResetPassword />,
  },
  {
    path: `/reset`,
    element: <Reset />,
  },
  {
    path: `/forgetPassword`,
    element: <ForgetPassword />,
  },
  // Private Routes
  {
    path: `/${companyName}/onboarding`,
    element: <Onboarding />,
    requiredRoles: [roles.Admin],
  },
  {
    path: `/${companyName}/dashboard`,
    element: <Dashboard />,
    requiredRoles: [
      roles.Reader,
      roles.Editor,
      roles.Admin,
      roles.ContentCreator,
    ],
  },
  {
    path: `/${companyName}/article/details/:id`,
    element: <ArticleDetails />,
    requiredRoles: [
      roles.Reader,
      roles.Editor,
      roles.Admin,
      roles.ContentCreator,
    ],
  },
  {
    path: `/${companyName}/settings/preferences`,
    element: <Preferences />,
    requiredRoles: [
      roles.Reader,
      roles.Editor,
      roles.Admin,
      roles.ContentCreator,
    ],
  },
  {
    path: `/${companyName}/settings/account`,
    element: <AccountSetting />,
    requiredRoles: [
      roles.Reader,
      roles.Editor,
      roles.Admin,
      roles.ContentCreator,
    ],
  },
  {
    path: `/${companyName}/admin-dashboard`,
    element: <AdminDashboard />,
    requiredRoles: [
      roles.Editor,
      roles.Admin,
      roles.SuperAdmin,
      roles.ContentCreator,
    ],
  },
  {
    path: `/workspace/superadmin-dashboard`,
    element: <AdminDashboard />,
    requiredRoles: [roles.SuperAdmin],
  },
  {
    path: `/${companyName}/RSS-Feed`,
    element: <RssFeed />,
    requiredRoles: [roles.Editor, roles.Admin],
  },
  {
    path: `/${companyName}/create-content`,
    element: <CreateContent />,
    requiredRoles: [roles.Editor, roles.Admin, roles.ContentCreator],
  },
  {
    path: `/${companyName}/edit-content/:contentId`,
    element: <CreateContent />,
    requiredRoles: [roles.Editor, roles.Admin, roles.ContentCreator],
  },
  {
    path: `/${companyName}/all-content`,
    element: <AllContent />,
    requiredRoles: [roles.Editor, roles.Admin, roles.ContentCreator],
  },
  {
    path: `/${companyName}/all-content/approve`,
    element: <AllApprovedContent />,
    requiredRoles: [roles.Admin, roles.Editor],
  },
  {
    path: `/${companyName}/all-content/schedule`,
    element: <QueuedContent />,
    requiredRoles: [roles.Admin, roles.Editor],
  },
  {
    path: `/${companyName}/all-newsletter`,
    element: <AllNewsLetter />,
    requiredRoles: [roles.Editor, roles.Admin],
  },
  // {
  //   path: `/${companyName}/updateOnboarding`,
  //   element: <Onboarding update={true} />,
  //   requiredRoles: [roles.Editor, roles.Admin],
  // },
  {
    path: `/${companyName}/broadcast`,
    element: <Broadcast />,
    requiredRoles: [roles.Admin],
  },
  {
    path: `/${companyName}/all-users`,
    element: <AllUsers />,
    requiredRoles: [roles.Admin, roles.SuperAdmin],
  },
  {
    path: `/${companyName}/all-organisation`,
    element: <AllOrganisation />,
    requiredRoles: [roles.SuperAdmin],
  },
  {
    path: `/${companyName}/statistics`,
    element: <Statistics />,
    requiredRoles: [roles.Admin, roles.SuperAdmin],
  },
  {
    path: `/${companyName}/settings/config`,
    element: <Config />,
    requiredRoles: [roles.Admin, roles.SuperAdmin],
  },
  {
    path: `/*`,
    element: <NotFoundPage />,
    requiredRoles: [roles.Reader, roles.Editor, roles.Admin, roles.SuperAdmin],
  },
];

const createRoleBasedRouter = (userRole) => {
  const filteredRoutes = routesConfig.map((route) => {
    return {
      ...route,
      element: route.requiredRoles ? (
        <Layout>
          <PrivateRoute
            element={route.element}
            requiredRoles={route.requiredRoles}
            userRole={userRole}
          />
        </Layout>
      ) : (
        <Layout>{route.element}</Layout>
      ),
    };
  });

  return createBrowserRouter(filteredRoutes);
};

export const router = () => {
  let role = getRole();
  return createRoleBasedRouter(role);
};
