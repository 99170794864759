import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";
import { GOOGLE_API_KEY } from "../../constants/env";

// -----------------------------------------------------
// Define types for GET organisation for admin

interface OrganisationResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}
interface getAdminOrganisationParams {
  searchTerm: string;
  currentPage: string;
  sortBy: string;
  isDescending: boolean;
}
/**
 * @method GET
 * @route
 * @returns {Promise<OrganisationResponse>} returns a Promise either an error or the response object
 */
export const getAllOrganisation = async ({
  searchTerm,
  currentPage,
  sortBy,
  isDescending,
}: getAdminOrganisationParams): Promise<OrganisationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<OrganisationResponse> = await axios.get(
    `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_SUPERADMIN_GET_ALL_ORGANISATION}?searchTerm=${searchTerm}&pageNumber=${currentPage}&pageSize=10&sortBy=${sortBy}&isDescending=${isDescending}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -------------------------------------------------
// Define types for the add organisation
interface addOrganisationParams {
  title: string;
  email: string;
  phoneNumber: string;
  location: string;
}

interface OrganisationResponse {
  msg: string;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<OrganisationResponse>}
 */
export const addOrganisation = async ({
  title,
  email,
  phoneNumber,
  location,
}: addOrganisationParams): Promise<OrganisationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<OrganisationResponse> = await axios.post(
      `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_SUPERADMIN_ADD_ORGANISATION}`,
      {
        title,
        email,
        phoneNumber,
        location,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -------------------------------------------------
// Define types for the edit organisation
interface editOrganisationParams {
  title: string;
  email: string;
  phoneNumber: string;
  location: string;
  organisationId: string;
}

interface OrganisationResponse {
  msg: string;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<OrganisationResponse>}
 */
export const editOrganisation = async ({
  title,
  email,
  phoneNumber,
  location,
  organisationId,
}: editOrganisationParams): Promise<OrganisationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<OrganisationResponse> = await axios.put(
      `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_SUPERADMIN_ADMIN_UPDATE_ORGANISATION}/${organisationId}`,
      {
        title,
        email,
        phoneNumber,
        location,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -------------------------------------------------
// Define types for the delete organisation
interface deleteOrganisationParams {
  organisationIds: [];
}

interface OrganisationResponse {
  msg: string;
}

/**
 * @method PUT
 * @route
 * @body {Object} url
 * @returns {Promise<OrganisationResponse>}
 */
export const deleteOrganisation = async (
  organisationIds: deleteOrganisationParams
): Promise<OrganisationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  try {
    const response: AxiosResponse<OrganisationResponse> = await axios.put(
      `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_SUPERADMIN_DELETE_ORGANISATION}`,
      organisationIds,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -------------------------------------------------
// Define types for the get template for edit organisation
interface UpdateTemplateOrganisationParams {
  organisationId: string;
}

interface OrganisationResponse {
  title: string;
  email: string;
  phoneNumber: string;
  location: string;
}

/**
 * @method PUT
 * @route
 * @body {Object} url
 * @returns {Promise<OrganisationResponse>}
 */
export const getUpdateTemplateOrganisation = async (
  organisationId: UpdateTemplateOrganisationParams
): Promise<OrganisationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<OrganisationResponse> = await axios.get(
      `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_SUPERADMIN_ADMIN_GET_UPDATE_TEMPLATE_ORGANISATION}/${organisationId}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// -----------------------------------------------------
// Define types for GET articles for admin

interface OrganisationResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}
interface getAdminOrganisationParams {
  searchTerm: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<OrganisationResponse>} returns a Promise either an error or the response object
 */
export const getOrganisation = async ({
  searchTerm,
}: getAdminOrganisationParams): Promise<OrganisationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<OrganisationResponse> = await axios.get(
    `${URL.ORGANISATION.BASE}${
      URL.ORGANISATION.V1_SUPERADMIN_ADMIN_AUTOCOMPLETE_ORGANISATION
    }?${searchTerm !== "" && `searchTerm=${searchTerm}`}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -------------------------------------------------
// Define types for the add onboarding organisation

interface imageParams {
  fileName: string;
  contentType: string;
  fileData: string;
}

interface onboardOrganisationParams {
  primaryColor: string;
  primaryFont: string;
  location: string;
  email: string;
  timeZoneId: string;
  logoImage: imageParams;
  topics: [string];
  secondaryColor?: string;
}

interface onboardOrganisationResponse {
  msg: string;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<onboardOrganisationResponse>}
 */
export const onboardOrganisation = async ({
  primaryColor,
  primaryFont,
  location,
  email,
  timeZoneId,
  logoImage,
  topics,
  secondaryColor,
}: onboardOrganisationParams): Promise<onboardOrganisationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<onboardOrganisationResponse> =
      await axios.post(
        `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_ADMIN_ONBOARD_ORGANISATION}`,
        {
          primaryColor,
          primaryFont,
          location,
          email,
          timeZoneId,
          logoImage,
          topics,
          secondaryColor,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for GET ONBOARDED Organisation

interface onboardingOrganisationResponse {
  isOnBoarded: false;
  primaryColor: null;
  primaryFont: null;
  logoLink: null;
}

/**
 * @method GET
 * @route
 * @returns {Promise<onboardingOrganisationResponse>} returns a Promise either an error or the response object
 */
export const getOnboardedOrganisation =
  async (): Promise<onboardingOrganisationResponse> => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

    const response: AxiosResponse<onboardingOrganisationResponse> =
      await axios.get(
        `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_ADMIN_GET_ONBOARDED_ORGANISATION}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    return response.data;
  };
// -----------------------------------------------------
// Define types for GET ONBOARDED Organisation

interface onboardingOrganisationResponse {
  isOnBoarded: false;
  primaryColor: null;
  primaryFont: null;
  logoLink: null;
}

/**
 * @method GET
 * @route
 * @returns {Promise<onboardingOrganisationResponse>} returns a Promise either an error or the response object
 */
export const getToUpdateOnboardedOrganisation =
  async (): Promise<onboardingOrganisationResponse> => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

    const response: AxiosResponse<onboardingOrganisationResponse> =
      await axios.get(
        `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_ADMIN_UPDATE_ONBOARD_ORGANISATION}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    return response.data;
  };

// -----------------------------------------------------
// Define types for GET ONBOARDED Organisation

interface topicsOrganisationResponse {
  topics: [];
}
interface topicsOrganisationParams {
  searchTerm: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<topicsOrganisationResponse>} returns a Promise either an error or the response object
 */
export const getTopicsOnboardedOrganisation = async ({
  searchTerm,
}: topicsOrganisationParams): Promise<topicsOrganisationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<topicsOrganisationResponse> = await axios.get(
    `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_ADMIN_GET_TOPICS_ORGANISATION}/${searchTerm}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for GET ONBOARDED Organisation
interface Timezone {
  id: string;
  title: string;
  isSelected: boolean;
}
interface timezoneOrganisationResponse {
  data: [Timezone];
}

/**
 * @method GET
 * @route
 * @returns {Promise<timezoneOrganisationResponse>} returns a Promise either an error or the response object
 */
export const getTimezoneOnboardedOrganisation =
  async (): Promise<timezoneOrganisationResponse> => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

    const response: AxiosResponse<timezoneOrganisationResponse> =
      await axios.get(
        `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_ADMIN_GET_TIMEZONE_ORGANISATION}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    return response.data;
  };

// -----------------------------------------------------
// Define types for GET articles for admin

interface verifyOrganisationResponse {
  id: string;
  isOnBoarded: boolean;
  primaryColor: string;
  primaryFont: string;
  logoLink: string;
}
interface verifyOrganisationParams {
  organisationName: string;
}

/**
 * @method GET
 * @route
 * @returns {Promise<verifyOrganisationResponse>} returns a Promise either an error or the response object
 */
export const verifyOrganisation = async ({
  organisationName,
}: verifyOrganisationParams): Promise<verifyOrganisationResponse> => {
  const response: AxiosResponse<verifyOrganisationResponse> = await axios.get(
    `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_ADMIN_VERIFY_ORGANISATION}/${organisationName}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

// -------------------------------------------------
// Define types for the add organisation
interface updateSettingsNotificationsParams {
  weeklyDay: number;
  weeklyHour: number;
  weeklyMinute: number;
  newsleteerDay: number;
  newsleteerHour: number;
  newsleteerMinute: number;
  completeNewsleteerDay: number;
  completeNewsleteerHour: number;
  completeNewsleteerMinute: number;
  dailyHour: number;
  dailyMinute: number;
  newesLetterStatringDay: Date;
  newsletterFrequency: number;
  newsLetterHeroCount: number;
  isNewsLetterHaveHeroTail: boolean;
}

interface updateSettingsNotificationsResponse {
  msg: string;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<OrganisationResponse>}
 */
export const updateSettingsNotifications = async ({
  weeklyDay,
  weeklyHour,
  weeklyMinute,
  newsleteerDay,
  newsleteerHour,
  newsleteerMinute,
  completeNewsleteerDay,
  completeNewsleteerHour,
  completeNewsleteerMinute,
  dailyHour,
  dailyMinute,
  newesLetterStatringDay,
  newsletterFrequency,
  newsLetterHeroCount,
  isNewsLetterHaveHeroTail,
}: updateSettingsNotificationsParams): Promise<updateSettingsNotificationsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<updateSettingsNotificationsResponse> =
      await axios.put(
        `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_ADMIN_UPDATE_NOTIFICATION_SETTINGS_ORGANISATION}`,
        {
          weeklyDay,
          weeklyHour,
          weeklyMinute,
          newsleteerDay,
          newsleteerHour,
          newsleteerMinute,
          completeNewsleteerDay,
          completeNewsleteerHour,
          completeNewsleteerMinute,
          dailyHour,
          dailyMinute,
          newesLetterStatringDay,
          newsletterFrequency,
          newsLetterHeroCount,
          isNewsLetterHaveHeroTail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -------------------------------------------------
// Define types for the add organisation
interface GETSettingsNotificationsResponse {
  weeklyDay: number;
  weeklyHour: number;
  weeklyMinute: number;
  newsleteerDay: number;
  newsleteerHour: number;
  newsleteerMinute: number;
  completeNewsleteerDay: number;
  completeNewsleteerHour: number;
  completeNewsleteerMinute: number;
  dailyHour: number;
  dailyMinute: number;
}

interface getSettingsNotificationsParams {}

/**
 * @method GET
 * @route
 * @body {Object} url
 * @returns {Promise<GETSettingsNotificationsResponse>}
 */
export const getSettingsNotifications =
  async ({}: getSettingsNotificationsParams): Promise<updateSettingsNotificationsResponse> => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

    try {
      const response: AxiosResponse<updateSettingsNotificationsResponse> =
        await axios.get(
          `${URL.ORGANISATION.BASE}${URL.ORGANISATION.V1_ADMIN_GET_NOTIFICATION_SETTINGS_TEMPLATE_ORGANISATION}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
// -----------------------------------------------------
export const fetchFonts = async () => {
  const response = await fetch(
    `${URL.ORGANISATION.FONT_API_URL}?key=${GOOGLE_API_KEY}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch fonts");
  }
  const data = await response.json();
  return data.items || [];
};
