import React, { useState } from "react";
import { TextField, MenuItem, Select } from "@mui/material";
import { format, parse, subYears, isAfter, isBefore, addDays } from "date-fns";

const DateFilter = ({
  dateRange,
  setDateRange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  // Set min date to 1 year ago and max date to today
  const today = new Date();
  const minDate = format(subYears(today, 1), "yyyy-MM-dd");
  const maxDate = format(today, "yyyy-MM-dd");

  const handleDateRangeChange = (event) => {
    const range = event.target.value;
    setDateRange(range);

    if (range === "Today") {
      const now = new Date();
      const todayStart = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      setStartDate(format(todayStart, "yyyy-MM-dd'T'00:00:00")); // Include time starting at midnight
      setEndDate(format(now, "yyyy-MM-dd'T'HH:mm:ss")); // Current time
    } else if (range === "Last Week") {
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7);
      setStartDate(format(lastWeek, "yyyy-MM-dd"));
      setEndDate(format(today, "yyyy-MM-dd'T'HH:mm:ss"));
    } else if (range === "Last 30 Days") {
      const last30Days = new Date(today);
      last30Days.setDate(today.getDate() - 30);
      setStartDate(format(last30Days, "yyyy-MM-dd"));
      setEndDate(format(today, "yyyy-MM-dd"));
    }
  };
  const handleCustomDateChange = (event, isStart) => {
    const value = event.target.value;
    const parsedDate = parse(value, "yyyy-MM-dd", new Date());

    if (isStart) {
      // Ensure start date isn't after end date
      if (isAfter(parsedDate, parse(endDate, "yyyy-MM-dd", new Date()))) {
        setStartDate(endDate);
      } else {
        setStartDate(value);
      }
    } else {
      // Ensure end date isn't before start date
      if (isBefore(parsedDate, parse(startDate, "yyyy-MM-dd", new Date()))) {
        setEndDate(startDate);
      } else {
        setEndDate(value);
      }
    }
    setDateRange("Custom");
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <Select
        value={dateRange}
        onChange={handleDateRangeChange}
        variant="outlined"
        style={{ minWidth: 150 }}
      >
        <MenuItem value="Today">Today</MenuItem>
        <MenuItem value="Last Week">Last Week</MenuItem>
        <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
        <MenuItem value="Custom">Custom</MenuItem>
      </Select>

      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => handleCustomDateChange(e, true)}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: minDate,
          max: endDate, // Start date can't be after current end date
        }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => handleCustomDateChange(e, false)}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: startDate, // End date can't be before current start date
        }}
      />
    </div>
  );
};

export default DateFilter;
