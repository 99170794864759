import React from "react";
import { Select, MenuItem, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getTemplateCreationUser } from "../../../../store/api/users";
import Loading from "../../../common/Loading";
import Button from "../../../common/Button";

const AddTypeUser = ({
  selectedType,
  handleTypeChange,
  handleBackStep,
  handleNextStep,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ["UserLocationAndType"],
    queryFn: () => getTemplateCreationUser(),
  });
  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <Typography variant="body1">
        Select the type of users are adding
      </Typography>
      <Select
        value={selectedType}
        onChange={(e) => handleTypeChange(e, data.userTypes)}
        fullWidth
      >
        {data.userTypes.map((userType) => (
          <MenuItem
            key={userType.id}
            value={userType.id}
            data-title={userType.title}
          >
            {userType.title}
          </MenuItem>
        ))}
      </Select>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <Button
          onClick={handleNextStep}
          disabled={!selectedType}
          text="Upload to this type"
        />
        <Button onClick={handleBackStep} text="Back" backgroundColor="white" />
      </div>
    </div>
  );
};

export default AddTypeUser;
