import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Chip,
  CardMedia,
} from "@mui/material";
import Button from "../../../common/Button";
import { getRole } from "../../../../helpers/auth";
import { roles } from "../../../../constants/routes";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import ArticleResources from "../../Dashboard/ArticleDetails/ArticleResource";
import { IMAGE_URL_AZURE } from "../../../../constants/env";

const ContentPreview = ({
  setStep,
  step,
  content,
  image,
  title,
  topics,
  scheduledDate,
  handleAddContent,
  modal,
  isLoadAddContent,
  publish,
  files,
  isEdit,
  status,
}) => {
  const role = getRole();
  const { primaryColor } = useOrganisation();
  const [selectedFile, setSelectedFile] = useState(null); // State to handle file preview

  // Function to handle file click (for images/videos)
  const handleFileClick = (file) => {
    if (
      file.contentType.startsWith("image/") ||
      file.contentType.startsWith("video/")
    ) {
      setSelectedFile(file); // Open the file in a dialog for preview
    } else {
      // For non-image/video files, open in a new tab (or trigger download)
      window.open(file.url + `?${IMAGE_URL_AZURE}`, "_blank");
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{ textAlign: "center", marginBottom: 4 }}
      >
        <Button
          text="Back"
          backgroundColor="white"
          onClick={() => setStep(step - 1)}
        />
        <Button
          text={isEdit ? "Edit Content" : "Submit for approval"}
          onClick={() => {
            if (scheduledDate) {
              handleAddContent({ publish: false });
            }
          }}
          disabled={isLoadAddContent}
          isLoading={isLoadAddContent && !publish}
        />
        {(roles.Admin === role || roles.Editor === role) &&
          (!isEdit || (isEdit && status === "Pending")) &&
          roles.ContentCreator !== role && (
            <Button
              text="Publish"
              onClick={() => {
                if (scheduledDate) {
                  handleAddContent({ publish: true });
                }
              }}
              disabled={isLoadAddContent}
              isLoading={isLoadAddContent && publish}
            />
          )}
      </Box>

      <Box sx={{ padding: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ marginBottom: 2 }}
            >
              {new Date(scheduledDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    overflow: "hidden",
                    borderRadius: 2,
                  }}
                >
                  {modal ? (
                    <img
                      src={image}
                      alt="Preview"
                      style={{ width: "100%", height: "auto" }}
                    />
                  ) : isEdit && !image.fileData ? (
                    <img
                      src={image.url + `?${IMAGE_URL_AZURE}`}
                      alt="Preview"
                      style={{ width: "100%", height: "auto" }}
                    />
                  ) : image?.contentType ? (
                    // <img
                    //   src={`data:${image?.contentType};base64,${image?.fileData}`}
                    //   alt="Preview"
                    //   style={{ width: "100%", height: "auto" }}
                    // />
                    <CardMedia
                      component="img"
                      image={`data:${image?.contentType};base64,${image?.fileData}`}
                      // image={`${
                      //   data?.mainImage
                      // }?${IMAGE_URL_AZURE}`}
                      // alt="Article Image"
                    />
                  ) : (
                    <img
                      src={
                        "https://static.thenounproject.com/png/1765551-200.png"
                      }
                      alt="Preview"
                      style={{ width: "100%", height: "auto" }}
                    />
                  )}
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  {topics.map((el, index) => (
                    <Chip
                      key={index}
                      label={el.title}
                      sx={{
                        backgroundColor: primaryColor,
                        color: "black",
                        marginRight: 1,
                        marginBottom: 1,
                      }}
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  <Box
                    sx={{
                      display: "-webkit-box",
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </Box>
                </Typography>
                {files && files.length > 0 && (
                  <Box className="mt-4">
                    <Typography
                      variant="subtitle2"
                      className="text-gray-600 mb-2"
                    >
                      Related Files:
                    </Typography>
                    <ArticleResources
                      files={files}
                      onFileClick={handleFileClick}
                      primaryColor={primaryColor}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContentPreview;
