import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { forgetPassword } from "../../../store/api/users";
import { Box, Typography, TextField } from "@mui/material";
import Toast from "../../common/Toast";
import { useOrganisation } from "../../../hooks/useOrganisation";
import Button from "../../common/Button";

// Define types for the toast state
interface ToastState {
  message: string | null;
  status: number | null;
}

// Define types for the errors state
interface ErrorsState {
  email: boolean;
}

// Component definition
const ForgetPasswordPage: React.FC = () => {
  const { primaryColorDark } = useOrganisation();

  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<ErrorsState>({ email: false });
  const [toast, setToast] = useState<ToastState>({
    message: null,
    status: null,
  });

  const validateForm = () => {
    let hasError = false;
    const newErrors = { email: false };

    if (email.trim() === "") {
      newErrors.email = true;
      hasError = true;
    }
    setErrors(newErrors);
    return !hasError;
  };

  const SubmitForm = () => {
    if (validateForm()) {
      ForgetPasswordMutate.mutate({ email });
    }
  };

  const ForgetPasswordMutate = useMutation({
    mutationFn: forgetPassword,
    onSuccess: () => {
      setToast({ message: "Password reset email sent!", status: 200 });
    },
    onError: (error: any) => {
      let errorMessage = "Something went wrong.";
      if (error.response) {
        const status = error.response.status;
        switch (status) {
          case 400:
            errorMessage = "Bad request. Please check your input.";
            break;
          case 401:
            errorMessage =
              "Incorrect email or password. Please check your credentials.";
            break;
          case 500:
            errorMessage = "Server error. Please try again later.";
            break;
          default:
            errorMessage = "Something went wrong.";
        }
      }
      setToast({ message: errorMessage, status: null });
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "white",
        padding: 4,
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          width: "100%",
          textAlign: "center",
          border: "1px solid #ccc",
          borderRadius: 2,
          padding: 4,
          bgcolor: "background.paper",
          boxShadow: 3,
        }}
      >
        {toast.status !== 200 ? (
          <>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Forgotten password reset:
            </Typography>
            <TextField
              fullWidth
              required
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              label="Email*"
              variant="outlined"
              error={errors.email}
              helperText={errors.email ? "Email is required" : ""}
              sx={{ mb: 2 }}
            />
            <Button onClick={SubmitForm} text="Reset Password" />

            <Typography variant="body2" sx={{ mb: 2 }}>
              <NavLink
                to="/"
                style={{ color: primaryColorDark, textDecoration: "none" }}
              >
                Log in
              </NavLink>
            </Typography>
          </>
        ) : (
          <Typography variant="body1" sx={{ mb: 2 }}>
            We have sent password recovery instructions to your email. Thank
            you!
          </Typography>
        )}
        <Typography variant="body2">
          <NavLink
            to="/"
            style={{ color: primaryColorDark, textDecoration: "none" }}
          >
            Want to register?
          </NavLink>
        </Typography>
      </Box>
      {toast.message && <Toast message={toast.message} status={toast.status} />}
    </Box>
  );
};

export default ForgetPasswordPage;
