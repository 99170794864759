import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCheckAuth } from "../../hooks/auth";
import Loading from "../../compnents/common/Loading";

const AuthRedirect = ({ element }) => {
  const navigate = useNavigate();
  const { refetch, data: isAuthenticated, isLoading } = useCheckAuth();
  const companyName = localStorage.getItem("companyName");
  const token = localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    refetch().then((res) => {
      if (res.data) {
        navigate(`/${companyName}/dashboard`, { replace: true });
      }
    });
  }, [refetch, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const articleId = searchParams.get("articleId");
    const source = searchParams.get("source") || "email";

    if (articleId && !token) {
      // Store the pending article info before redirecting to login
      localStorage.setItem("pendingArticleId", articleId);
      localStorage.setItem("pendingArticleSource", source);
    }
  }, [location, token]);

  if (isLoading) {
    return <Loading />;
  }

  return element;
};

export default AuthRedirect;
