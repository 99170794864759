import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// Define types for the login function parameters and response
interface extractContentParams {
  url: string;
}

interface ContentResponse {
  msg: string;
  user: any;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object msg & user
 */
export const extractContent = async ({
  url,
}: extractContentParams): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<ContentResponse> = await axios.post(
      `${URL.CONTENT.BASE}${URL.CONTENT.V1_EXTRACT_CONTENT}`,
      {
        url,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      // getConfig()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for the login function parameters and response
interface addArticleParams {
  title: string;
  author: string;
  contentText?: string;
  isContentFromUrl: true;
  contentUrl: string;
  shareable: true;
  featured: true;
  newsLetter: true;
  scheduledDate: string;
  contentRegionIds: [string];
  contentTypeIds?: [string];
  contentTopicIds?: [string];
  mainImage?: {
    fileName?: string;
    contentType?: string;
    fileData?: string;
  };
  files: [];
}

interface ContentResponse {
  msg: string;
  user: any;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object msg & user
 */
export const addArticle = async (
  content: addArticleParams
): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<ContentResponse> = await axios.post(
      `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADD_CONTENT}`,
      {
        ...content,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      // getConfig()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for the login function parameters and response
interface editArticleParams {
  contentId: string;
  title: string;
  author: string;

  contentText?: string;
  isContentFromUrl: true;
  contentUrl: string;
  shareable: true;
  featured: true;
  newsLetter: true;
  scheduledDate: string;
  contentRegionIds: [string];
  contentTypeIds?: [string];
  contentTopicIds?: [string];
  mainImage?: {
    fileName?: string;
    contentType?: string;
    fileData?: string;
  };
  files: [];
}

interface editContentResponse {
  msg: string;
  user: any;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<editContentResponse>} returns a Promise either an error or the response object msg & user
 */
export const editArticle = async (
  content: editArticleParams
): Promise<editContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  console.log(content);
  try {
    const response: AxiosResponse<editContentResponse> = await axios.put(
      `${URL.CONTENT.BASE}${URL.CONTENT.V1_EDIT_CONTENT}/${content.contentId}`,
      {
        ...content,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      // getConfig()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for extract types regions function parameters and response

interface ContentResponse {
  regions: string;
  types: string;
  topics: string;
}

/**
 * @method GET
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const getTopicsForArticle = async (): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<ContentResponse> = await axios.get(
      `${URL.CONTENT.BASE}${URL.CONTENT.V1_GET_TOPICS_REGIONS_TYPES}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      // getConfig()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for extract types regions function parameters and response

interface ContentResponse {
  latestApproved: [];
  latestFeatured: [];
  paginatedContent: [];
  ads: [];
  continuationToken: string | null;
}
// Define types for the login function parameters and response
interface ContentParams {
  continuationToken: string;
}

/**
 * @method GET
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const getAllArticles = async ({
  continuationToken,
}: ContentParams): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_USER_GET_ALL_CONTENT}?continuationToken=${continuationToken}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Get Article by iD
interface getContenByIdtParams {
  id: string;
}
interface ContentByIdResponse {
  content: [];
}

/**
 * @method GET
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const getArticleByid = async ({
  id,
}: getContenByIdtParams): Promise<ContentByIdResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentByIdResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_GET_CONTENT_BY_ID}/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Get Article by iD
interface getContenByIdtParams {
  id: string;
}

interface ContentToEditByIdResponse {
  regions: [];
  topics: [];
  types: [];
}

/**
 * @method GET
 * @route
 * @returns {Promise<ContentToEditByIdResponse>} returns a Promise either an error or the response object
 */
export const getUpdateArticleTemplateByid = async ({
  id,
}: getContenByIdtParams): Promise<ContentToEditByIdResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentToEditByIdResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_EDIT_TEMPLATE_CONTENT}/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET articles for admin

interface ContentResponse {
  latestApproved: [];
  latestFeatured: [];
  paginatedContent: [];
}
interface getAdminContentParams {
  searchTerm: string;
  currentPage: string;
  sortBy: string;
  isDescending: boolean;
  status: number | null;
  SchedulDate: boolean;
  tag: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */

export const getAllAdminArticles = async ({
  searchTerm,
  currentPage,
  sortBy,
  isDescending,
  status,
  SchedulDate,
  tag,
}: getAdminContentParams): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentResponse> = await axios.get(
    `${URL.CONTENT.BASE}${
      URL.CONTENT.V1_ADMIN_GET_ALL_CONTENT
    }?searchTerm=${searchTerm}&pageNumber=${currentPage}&pageSize=10&sortBy=${sortBy}&isDescending=${isDescending}&SchedulDate=${SchedulDate}&tag=${tag}&${
      status !== null ? `status=${status}` : ""
    }`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for approve content

interface approveContentParams {
  contentId: string;
  message: string;
}
interface ContentResponse {
  latestApproved: [];
}
/**
 * @method GET
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const approveContent = async ({
  contentId,
  message,
}: approveContentParams): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentResponse> = await axios.put(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_APPROVE_ADMIN_CONTENT}/${contentId}`,
    { message },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for approve content

interface RejectContentParams {
  contentId: string;
  message: string;
}
interface ContentResponse {
  latestApproved: [];
}
/**
 * @method GET
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const rejectContent = async ({
  contentId,
  message,
}: RejectContentParams): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const response: AxiosResponse<ContentResponse> = await axios.put(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_REJECT_ADMIN_CONTENT}/${contentId}`,
    { message },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for approve content

interface checkContentApprovalParams {
  contentId: string;
}
interface CheckContentApprovalResponse {
  latestApproved: [];
}
/**
 * @method GET
 * @route
 * @returns {Promise<CheckContentApprovalResponse>} returns a Promise either an error or the response object
 */
export const checkContentApproval = async ({
  contentId,
}: checkContentApprovalParams): Promise<CheckContentApprovalResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<CheckContentApprovalResponse> = await axios.put(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_CEHCK_APPROVAL_CONTENT}/${contentId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for delete content

interface deleteContentParams {
  contentIds: string;
}
interface ContentResponse {
  latestApproved: [];
}
/**
 * @method PUT
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const deleteContent = async (
  contentIds: deleteContentParams
): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentResponse> = await axios.put(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_DELETE_ADMIN_CONTENT}`,
    contentIds,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET articles for admin

interface NewsletterResponse {
  latestApproved: [];
  latestFeatured: [];
  paginatedNewsletter: [];
  continuationToken: null;
}
interface getAdminNewsletterParams {
  searchTerm: string;
  currentPage: string;
  sortBy: string;
  isDescending: boolean;
}
/**
 * @method GET
 * @route
 * @returns {Promise<NewsletterResponse>} returns a Promise either an error or the response object
 */
export const getAllAdminNewsletter = async ({
  searchTerm,
  currentPage,
  sortBy,
  isDescending,
}: getAdminNewsletterParams): Promise<NewsletterResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<NewsletterResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_GET_ADMIN_NEWSELETTER}?searchTerm=${searchTerm}&pageNumber=${currentPage}&pageSize=10&sortBy=${sortBy}&isDescending=${isDescending}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET articles for admin

interface NewsletterResponse {
  latestApproved: [];
  latestFeatured: [];
  paginatedNewsletter: [];
  continuationToken: null;
}
interface getAdminNewsletterParams {
  searchTerm: string;
  currentPage: string;
  sortBy: string;
  isDescending: boolean;
}
/**
 * @method GET
 * @route
 * @returns {Promise<NewsletterResponse>} returns a Promise either an error or the response object
 */
export const getOrdredAdminNewsletter = async ({
  searchTerm,
  currentPage,
  sortBy,
  isDescending,
}: getAdminNewsletterParams): Promise<NewsletterResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<NewsletterResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_GET_ORDRED_ADMIN_NEWSELETTER}?searchTerm=${searchTerm}&pageNumber=${currentPage}&pageSize=10&sortBy=${sortBy}&isDescending=${isDescending}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for reorder newsletter content

interface reorderNewsletterParams {
  movedArticleId: string;
  belowArticleId: string;
}
interface reorderNewsletterResponse {
  paginatedContent: [];
  continuationToken: string;
  latestApproved: [];
  latestFeatured: [];
}
/**
 * @method PUT
 * @route
 * @returns {Promise<reorderNewsletterResponse>} returns a Promise either an error or the response object
 */
export const reorderNewsletter = async ({
  movedArticleId,
  belowArticleId,
}: reorderNewsletterParams): Promise<reorderNewsletterResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<reorderNewsletterResponse> = await axios.put(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_REORDER_NEWSELETTER}/${movedArticleId}/${belowArticleId}`,
    { movedArticleId, belowArticleId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for search template content
interface getUerSearchTemplateResponse {
  topics: [];
  regions: [];
  types: [];
}
/**
 * @method GET
 * @route
 * @returns {Promise<getUerSearchTemplateResponse>} returns a Promise either an error or the response object
 */
export const getSearchContentByUserTemplate =
  async ({}): Promise<getUerSearchTemplateResponse> => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

    const response: AxiosResponse<getUerSearchTemplateResponse> =
      await axios.get(
        `${URL.CONTENT.BASE}${URL.CONTENT.V1_USER_SEARCH_CONTENT_TEMPLATE}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    return response.data;
  };

// -----------------------------------------------------
// Define types for GET articles for admin

interface ContentSearchedResponse {
  latestApproved: [];
  latestFeatured: [];
  paginatedContent: [];
}
interface getUserSearchedContentParams {
  searchTerm: string;
  usersIds: string[] | null;
  topicIds: string[] | null;
  regionIds: string[] | null;
  typeIds: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
}
/**
 * @method GET
 * @route
 * @returns {Promise<ContentSearchedResponse>} returns a Promise either an error or the response object
 */
export const getUserSearchedContent = async ({
  searchTerm,
  usersIds,
  topicIds,
  regionIds,
  typeIds,
  startDate,
  endDate,
}: getUserSearchedContentParams): Promise<ContentSearchedResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentSearchedResponse> = await axios.get(
    `${URL.CONTENT.BASE}${
      URL.CONTENT.V1_USER_SEARCH_CONTENT
    }?searchTerm=${searchTerm}&${startDate && `startDate=${startDate}`}&${
      endDate && `endDate=${endDate}`
    }&
    &${usersIds && `usersIds=${usersIds}`}
    &${topicIds ? topicIds.map((el) => `&topicIds=${el}&`) : ""}&${
      typeIds ? typeIds.map((el) => `&typeIds=${el}&`) : ""
    }
    &${regionIds ? regionIds.map((el) => `&regionIds=${el}`) : ""}
    &pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for the login function parameters and response
interface addBroadcastContentParams {
  subject: "string";
  contentText: "string";
  contentHtml: "string";
}

interface addBroadcastResponse {
  msg: string;
  user: any;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<addBroadcastResponse>} returns a Promise either an error or the response object msg & user
 */
export const addBroadcastContent = async ({
  subject,
  contentText,
  contentHtml,
}: addBroadcastContentParams): Promise<addBroadcastResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, " ");

  try {
    const response: AxiosResponse<addBroadcastResponse> = await axios.post(
      `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_BROADCAST_CONTENT}`,
      {
        subject,
        contentText,
        contentHtml,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// -----------------------------------------------------
// Define types for search template content
interface getUerSearchTemplateResponse {}
interface getVendorSearchTemplateParams {
  search: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<getUerSearchTemplateResponse>} returns a Promise either an error or the response object
 */
export const getSearchVendorByUserTemplate = async ({
  search,
}: getVendorSearchTemplateParams): Promise<getUerSearchTemplateResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<getUerSearchTemplateResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_USER_SEARCH_VENDOR_CONTENT_TEMPLATE}?searchTerm=${search}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Get Article by iD
interface getImagetParams {
  url: string;
}

interface ImageExtractResponse {
  fileName: string;
  contentType: string;
  fileData: string;
}

/**
 * @method GET
 * @route
 * @returns {Promise<ImageExtractResponse>} returns a Promise either an error or the response object
 */
export const getImage = async ({
  url,
}: getImagetParams): Promise<ImageExtractResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ImageExtractResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_EXTRACT_IMAGE_CONTENT}?imageUrl=${url}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for delete content

interface harddeleteContentParams {
  contentId: string;
}
interface ContentResponse {}
/**
 * @method DELETE
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const hardDeleteContent = async (
  contentId: harddeleteContentParams
): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentResponse> = await axios.delete(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_HARD_DELETE_ADMIN_CONTENT}/${contentId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for delete content

interface duplicateContentParams {
  contentId: string;
}
interface ContentResponse {}
/**
 * @method DELETE
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const duplicateContent = async (
  contentId: duplicateContentParams
): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentResponse> = await axios.put(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_DUPLICATE_ADMIN_CONTENT}/${contentId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
//API Previews show newsletter
interface newsletterResponse {}

/**
 * @method GET
 * @route
 * @returns {Promise<newsletterResponse>}
 */
export const getPreviewNewsletter = async (): Promise<newsletterResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_GET_ADMIN_PREVIEW_NEWSELETTER}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
