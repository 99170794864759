import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import { ControlPoint, Delete, Add } from "@mui/icons-material";
import Button from "../../../common/Button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addAds,
  getAddAdsTemplate,
  getAllContentAds,
  getUpdateAdsTemplate,
  updateAds,
} from "../../../../store/api/ads";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import CreateImage from "../../AddContent/ContentForm/CreateContent";

const AdFormModal = ({
  handleClose,
  handleOpen,
  open,
  isEdit,
  setIsEdit,
  editData,
}) => {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();

  const [type, setType] = useState("");
  const [content, setContent] = useState([]);
  const [searchContent, setSearchContent] = useState("");
  const [bannerData, setBannerData] = useState({
    title: "",
    link: "",
    mainImage: null,
    imageId: null,
  });
  const [mainImage, setMainImage] = useState(null);
  const [errors, setErrors] = useState({
    type: false,
    link: false,
    image: false,
    content: false,
  });
  const [addCustomMode, setAddCustomMode] = useState(false);
  const [customArticle, setCustomArticle] = useState({
    title: "",
    link: "",
    mainImage: null,
    isCustom: true,
  });
  const [customArticleImage, setCustomArticleImage] = useState(null);
  const [customArticleErrors, setCustomArticleErrors] = useState({
    title: false,
    link: false,
    image: false,
  });

  const resetForm = () => {
    setType("");
    setContent([]);
    setBannerData({
      title: "",
      link: "",
      mainImage: null,
      imageId: null,
    });
    setMainImage(null);
    setErrors({
      type: false,
      link: false,
      image: false,
      content: false,
    });
    setAddCustomMode(false);
    resetCustomArticleForm();
    setIsEdit(false);
  };

  const resetCustomArticleForm = () => {
    setCustomArticle({
      title: "",
      link: "",
      mainImage: null,
      isCustom: true,
    });
    setCustomArticleImage(null);
    setCustomArticleErrors({
      title: false,
      link: false,
      image: false,
    });
  };

  const { data: contentData } = useQuery({
    queryKey: ["contentAds", searchContent],
    queryFn: () => getAllContentAds({ search: searchContent }),
    enabled: type !== 2,
  });

  const { data: addsTemplate } = useQuery({
    queryKey: ["adsTemplate"],
    queryFn: () => getAddAdsTemplate({}),
  });

  const { data: editadsTemplate, isLoading } = useQuery({
    queryKey: ["editAdsTemplate", editData],
    queryFn: () => getUpdateAdsTemplate({ id: editData }),
    enabled: !!isEdit,
  });

  const { mutate: addMutate } = useMutation({
    mutationFn: (ads) => addAds(ads),
    onSuccess: (data) => {
      queryClient.refetchQueries("adsList");
      handleClose();
      resetForm();
    },
    onError: (error) => {
      console.error("Error adding ad:", error);
    },
  });

  const { mutate: editMutate } = useMutation({
    mutationFn: (data) => updateAds(data),
    onSuccess: (data) => {
      queryClient.refetchQueries("adsList");
      handleClose();
      resetForm();
    },
    onError: (error) => {
      console.error("Error editing ad:", error);
    },
  });

  useEffect(() => {
    if (isEdit && !isLoading && editadsTemplate) {
      const selectedType = editadsTemplate.types.find((el) => el.isSelected);
      setType(selectedType.id);
      if (+selectedType.id === 2) {
        setBannerData({
          title: editadsTemplate.ads[0].title || "",
          link: editadsTemplate.ads[0].link || "",
          mainImage: editadsTemplate.ads[0].imageUrl || null,
          imageId: editadsTemplate.ads[0].imageId || null,
        });
        setMainImage(editadsTemplate.ads[0].imageUrl || null);
      } else {
        const processedContent = editadsTemplate?.ads?.map((item) => {
          if (item.contentId === null) {
            // Custom article
            return {
              id: item.id || Date.now(),
              originalId: item.id,
              title: item.title,
              link: item.link,
              mainImage: item.imageUrl,
              imageId: item.imageId,
              isCustom: true,
            };
          }
          // Regular content
          return {
            id: item.contentId,
            title: item.contentTitle,
            originalId: item.id,
            contentId: item.contentId,
            isCustom: false,
          };
        });
        setContent(processedContent || []);
      }
    }
  }, [isEdit, editadsTemplate, isLoading]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  const validateForm = () => {
    const newErrors = {
      type: type === "" || type === null || isNaN(+type),
      link: type == 2 && !bannerData.link.trim(),
      image: type == 2 && !mainImage && !bannerData.mainImage,
      content: (type == 1 || type === 0) && content.length === 0,
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const validateCustomArticle = () => {
    const newErrors = {
      title: !customArticle.title.trim(),
      link: !customArticle.link.trim(),
      image: !customArticleImage,
    };

    setCustomArticleErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleDelete = (id) => {
    setContent(content.filter((item) => item.id !== id));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedContent = Array.from(content);
    const [reorderedItem] = updatedContent.splice(result.source.index, 1);
    updatedContent.splice(result.destination.index, 0, reorderedItem);
    setContent(updatedContent);
  };

  const handleAddCustomArticle = () => {
    if (!validateCustomArticle()) {
      return;
    }

    // Create a unique ID for the custom article (negative to avoid conflicts with API IDs)
    const customId = -1 * (Date.now() + Math.floor(Math.random() * 1000));

    const newCustomArticle = {
      id: customId,
      title: customArticle.title,
      link: customArticle.link,
      mainImage: customArticleImage,
      isCustom: true,
    };

    setContent([...content, newCustomArticle]);
    setAddCustomMode(false);
    resetCustomArticleForm();
  };

  // Helper function to format image data correctly based on context
  const formatImageData = (imageObject, imageId) => {
    // Case 1: New file upload (object is a File)
    if (
      typeof imageObject === "object" &&
      imageObject !== null &&
      !(imageObject instanceof String)
    ) {
      // For editing
      if (isEdit) {
        return {
          id: null,
          file: imageObject,
        };
      }
      // For new items
      return imageObject;
    }
    // Case 2: Existing image with ID (for edit mode)
    else if (imageId) {
      return {
        id: imageId,
        file: null,
      };
    }
    // Case 3: Image URL without ID (should not normally happen)
    else if (typeof imageObject === "string" && imageObject) {
      return null; // In this case, no changes to the image
    }
    // Case 4: No image
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    // Get the correct order value
    const order = isEdit
      ? editadsTemplate.orders.find((el) => el.isSelected)?.id
      : addsTemplate.orders[addsTemplate.orders.length - 1].id;

    if (type == 2) {
      // Banner type
      const payload = {
        type: +type,
        order,
        ads: [
          {
            title: bannerData.title,
            link: bannerData.link,
          },
        ],
      };

      if (isEdit) {
        // Add the id to the ad item if editing
        payload.ads[0].id = editadsTemplate.ads[0].id;

        // Handle image formatting for edit mode
        payload.ads[0].mainImage = formatImageData(
          mainImage,
          bannerData.imageId
        );

        editMutate({ id: editData, ...payload });
      } else {
        // For new ads
        payload.ads[0].mainImage = mainImage;
        addMutate(payload);
      }
    } else {
      // Carousel or List type
      const ads = content.map((item) => {
        if (item.isCustom) {
          // For custom articles
          const adItem = {
            title: item.title,
            link: item.link,
            contentId: null,
          };

          // For EDIT mode: Include the original ID if this is an existing custom article
          if (isEdit && item.originalId) {
            adItem.id = item.originalId;
          }

          // Format the mainImage property correctly
          adItem.mainImage = formatImageData(
            typeof item.mainImage === "object" ? item.mainImage : null,
            item.imageId
          );

          return adItem;
        } else {
          // Regular content format
          return isEdit
            ? {
                id: item.originalId || null,
                contentId: item.id || item.contentId,
              }
            : {
                contentId: item.id,
              };
        }
      });

      const payload = {
        type: +type,
        order,
        ads,
      };

      if (isEdit) {
        editMutate({ id: editData, ...payload });
      } else {
        addMutate(payload);
      }
    }
  };

  return (
    <>
      <Grid xs={9} md={3}>
        <Button
          variant="raised"
          endIcon={<ControlPoint />}
          sx={{ backgroundColor: primaryColor, color: "#333" }}
          onClick={handleOpen}
          text="Add Ads"
        />
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalResponsiveStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            {isEdit ? "Edit Ad" : "Create New Ad"}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth error={errors.type}>
                  <InputLabel>Select Type</InputLabel>
                  <Select
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        type: false,
                        content: false,
                      }));
                    }}
                    label="Set Position"
                  >
                    {addsTemplate?.types.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.type && (
                    <FormHelperText>Type is required</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {type == 2 ? (
                // Banner type form
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      value={bannerData.title}
                      onChange={(e) =>
                        setBannerData((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Link"
                      required
                      error={errors.link}
                      helperText={errors.link ? "Link is required" : ""}
                      value={bannerData.link}
                      onChange={(e) => {
                        setBannerData((prev) => ({
                          ...prev,
                          link: e.target.value,
                        }));
                        setErrors((prev) => ({ ...prev, link: false }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {isEdit && (
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {mainImage && typeof mainImage === "string"
                          ? "Current image will be kept unless you upload a new one"
                          : mainImage
                          ? "New image will be uploaded"
                          : "No image selected"}
                      </Typography>
                    )}
                    <CreateImage
                      image={mainImage}
                      setImage={(img) => {
                        setMainImage(img);
                        setErrors((prev) => ({ ...prev, image: false }));
                      }}
                      update={isEdit}
                    />
                    {errors.image && (
                      <FormHelperText error>Image is required</FormHelperText>
                    )}
                  </Grid>
                </>
              ) : (
                // Carousel or List type
                <>
                  {addCustomMode ? (
                    // Custom Article Form
                    <>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                          Add Custom Article
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Title"
                          required
                          error={customArticleErrors.title}
                          helperText={
                            customArticleErrors.title ? "Title is required" : ""
                          }
                          value={customArticle.title}
                          onChange={(e) =>
                            setCustomArticle((prev) => ({
                              ...prev,
                              title: e.target.value,
                            }))
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Link"
                          required
                          error={customArticleErrors.link}
                          helperText={
                            customArticleErrors.link ? "Link is required" : ""
                          }
                          value={customArticle.link}
                          onChange={(e) =>
                            setCustomArticle((prev) => ({
                              ...prev,
                              link: e.target.value,
                            }))
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CreateImage
                          image={customArticleImage}
                          setImage={(img) => {
                            setCustomArticleImage(img);
                            setCustomArticleErrors((prev) => ({
                              ...prev,
                              image: false,
                            }));
                          }}
                        />
                        {customArticleErrors.image && (
                          <FormHelperText error>
                            Image is required
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Button
                          text="Cancel"
                          variant="outlined"
                          onClick={() => {
                            setAddCustomMode(false);
                            resetCustomArticleForm();
                          }}
                        />
                        <Button
                          text="Add Custom Article"
                          variant="contained"
                          onClick={handleAddCustomArticle}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </>
                  ) : (
                    // Article Selection Interface
                    <>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <InputLabel>Select List Of Content</InputLabel>
                        <Button
                          variant="outlined"
                          startIcon={<Add />}
                          text="Add Custom Article"
                          onClick={() => setAddCustomMode(true)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={contentData || []}
                          getOptionLabel={(option) => option.title || ""}
                          onChange={(event, newValue) => {
                            if (
                              newValue &&
                              !content.some((item) => item.id === newValue.id)
                            ) {
                              setContent([...content, newValue]);
                              setSearchContent("");
                              setErrors((prev) => ({
                                ...prev,
                                content: false,
                              }));
                            }
                          }}
                          inputValue={searchContent}
                          onInputChange={(e, newInputValue) =>
                            setSearchContent(newInputValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Article"
                              variant="outlined"
                              error={errors.content}
                              helperText={
                                errors.content
                                  ? "At least one article is required"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Selected Content
                    </Typography>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="articles">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {content.length === 0 && (
                              <Typography
                                sx={{ color: "text.secondary", py: 2 }}
                              >
                                No content selected. Add content from the list
                                or create custom articles.
                              </Typography>
                            )}

                            {content.map((article, index) => (
                              <Draggable
                                key={article.id.toString()}
                                draggableId={article.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: 1,
                                      margin: "8px 0",
                                      backgroundColor: snapshot.isDragging
                                        ? "#f0f0f0"
                                        : "#fafafa",
                                      border: "1px solid #ddd",
                                    }}
                                  >
                                    <Box sx={{ flexGrow: 1 }}>
                                      <Typography>{article.title}</Typography>
                                      {article.isCustom && (
                                        <Typography
                                          variant="caption"
                                          color="text.secondary"
                                        >
                                          Custom Article
                                        </Typography>
                                      )}
                                    </Box>
                                    <IconButton
                                      onClick={() => handleDelete(article.id)}
                                    >
                                      <Delete color="error" />
                                    </IconButton>
                                  </Box>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Button
                  text={isEdit ? "Update Ad" : "Create Ad"}
                  type="submit"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const modalResponsiveStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

export default AdFormModal;
