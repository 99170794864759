import React, { useState } from "react";
import { Box, Typography, Button, Tooltip, IconButton } from "@mui/material";
import { SketchPicker } from "react-color";
import CustomButton from "../../common/Button";
import { Info } from "@mui/icons-material";

const PrimaryColor = ({
  color,
  handleColorChange,
  font,
  handleBack,
  handleNext,
  update,
  handleSave,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography variant="h6">
        Choose your primary colour
        <Tooltip title="Be aware that lighter colours may not be visually accessible to all users.">
          <IconButton>
            <Info />
          </IconButton>
        </Tooltip>
      </Typography>

      {color ? (
        <Typography fontFamily={font} color={color} variant="h6">
          This is a preview of your selected colour.{" "}
        </Typography>
      ) : (
        ""
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Box
              mt={2}
              mr={4}
              sx={{
                width: "100px",
                height: "50px",
                backgroundColor: color,
                borderRadius: 1,
                margin: 0,
                marginTop: "32px",
              }}
            ></Box>
          </Button>
          {isOpen && (
            <SketchPicker color={color} onChangeComplete={handleColorChange} />
          )}
        </Box>
        <Box sx={{ marginTop: 4 }} display={"flex"} justifyContent={"end"}>
          <CustomButton
            onClick={handleBack}
            sx={{}}
            style={{}}
            backgroundColor="white"
            text="Back"
          />

          <CustomButton
            onClick={() => {
              update ? handleSave() : handleNext();
            }}
            text={update ? "Update" : "Next"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PrimaryColor;
