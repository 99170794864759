import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FixedSizeList } from "react-window";
import { fetchFonts } from "../../../store/api/organisation";
import Button from "../../common/Button";

const LISTBOX_ITEM_SIZE = 48;

const ListboxComponent = React.forwardRef(function ListboxComponent(
  { children, ...other },
  ref
) {
  const itemCount = Array.isArray(children) ? children.length : 0;

  return (
    <div ref={ref}>
      <div {...other}>
        <FixedSizeList
          height={250}
          width="100%"
          itemSize={LISTBOX_ITEM_SIZE}
          itemCount={itemCount}
          overscanCount={5}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </FixedSizeList>
      </div>
    </div>
  );
});

const GoogleFontSearchStep = ({ font, setFont, handleBack, handleNext }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const loadedFontsRef = useRef(new Set());
  const { data: fonts = [], isLoading } = useQuery({
    queryKey: ["googleFonts"],
    queryFn: fetchFonts,
    staleTime: Infinity,
  });

  const filteredFonts = fonts.filter((font) =>
    font.family.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const loadFont = (fontFamily) => {
    if (!loadedFontsRef.current.has(fontFamily)) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
        / /g,
        "+"
      )}&display=swap`;
      document.head.appendChild(link);
      loadedFontsRef.current.add(fontFamily);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Search and choose your font</Typography>
      <Autocomplete
        fullWidth
        options={filteredFonts}
        getOptionLabel={(option) => option.family}
        isOptionEqualToValue={(option, value) => option.family === value.family}
        loading={isLoading}
        onInputChange={(event, value) => setSearchQuery(value)}
        onChange={(event, value) => value && setFont(value.family)}
        ListboxComponent={ListboxComponent}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Fonts"
            variant="outlined"
            sx={{ marginTop: 2 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          loadFont(option.family);
          return (
            <MenuItem
              {...props}
              key={option.family}
              style={{
                fontFamily: option.family,
                height: LISTBOX_ITEM_SIZE,
              }}
            >
              {option.family}
            </MenuItem>
          );
        }}
        noOptionsText={isLoading ? "Loading..." : "No fonts found"}
      />

      {font && (
        <Box
          mt={4}
          style={{
            fontFamily: font,
            fontSize: "24px",
          }}
        >
          This is a preview of "{font}".
        </Box>
      )}

      <Box sx={{ marginTop: 4 }} display={"flex"} justifyContent={"end"}>
        <Button
          onClick={handleBack}
          sx={{ marginRight: 2 }}
          backgroundColor="white"
          text="Back"
        />
        <Button onClick={handleNext} text="Next" disabled={!font} />
      </Box>
    </Box>
  );
};

export default GoogleFontSearchStep;
