import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Header from "../../compnents/common/Header";
import Loading from "../../compnents/common/Loading";
import { useCheckAuth } from "../../hooks/auth";
import { getRole } from "../../helpers/auth";

const PrivateRoute = ({ element, requiredRoles }) => {
  const { refetch } = useCheckAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    refetch()
      .then(async (res) => {
        setIsAuthenticated(!!res.data);
        const userRole = await getRole();
        setHasAccess(res.data && requiredRoles.includes(userRole));
      })
      .catch((err) => {
        setIsAuthenticated(false);
        setHasAccess(false);
      });
  }, [refetch, requiredRoles]);

  if (isAuthenticated === null) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (isAuthenticated && !hasAccess) {
    return <Navigate to="/not-found" replace />;
  }

  // Render the element if authenticated and has access
  return (
    <>
      <Header />
      {element}
    </>
  );
};

export default PrivateRoute;
